//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import CommandCenterService from "../../../../../service/CommandCenterService";
import {
  Accordion,
  Button,
  Form,
  Nav,
  Tab,
} from 'react-bootstrap';
import Select from "react-select";
import { useParams } from "react-router-dom";
import MissionService from "../../../../../service/MissionService";
import { toast } from "react-toastify";

import { MissionStore } from "../../../../../store/MissionSlice";
import { useSelector } from "react-redux";
import Loader from "../../../../../Layouts/Loader/Loader";
import Dialog from "../../../../CommonComponents/Dialog";
import CustomToolTip from "../../../../CommonComponents/CustomToolTip";
import { downloadFile } from "../../../../CommonComponents/CommonFunctions";


const ConsolePanel = ({ TrueTwin }) => {
  const params = useParams();
  const {
    sat_id,
    truetwin_id,
    scenario_id
  } = params;
  const scrollRef = useRef(null)
  const [fileDownloading, setFileDownloading] = useState('');
  const [deleteFile, setDeleteFile] = useState<any>({
    isOpen: false,
    name: '',
  });

  const [datastoreFiles, setDatastoreFiles] = useState<any>([])

  const [isCustom, setIsCustom] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Output');
  const [componentType, setComponentType] = useState<string>("Payload");
  const [inputType, setInputType] = useState<string>("Telecommand Input");
  const [jsonData, setJsonData] = useState<any>('');
  const [isLoading, setIsLoading] = useState<any>(false);
  const [loading, setLoading] = useState<any>(true);
  const [responseData, setResponseData] = useState<any>({});
  const [responseList, setResponseList] = useState<any>([]);
  const [index, setIndex] = useState<any>(1);
  const [consoleType, setConsoleType] = useState("Macro");

  const [allPayloads, setAllPayload] = useState<any>([]);
  const [payloadsList, setPayloadList] = useState<any>([]);
  const [selectedPayload, setSelectedPayload] = useState<any>(null);
  const [selectedSequence, setSelectedSequence] = useState<any>(null);
  const [sequenceList, setSequenceList] = useState<any>([]);
  const [seqParm, setSeqParm] = useState<any>('');
  const [seqDuration, setSeqDuration] = useState<any>(1);


  const [allBusCategories, setAllBusCategories] = useState<{ label: string; value: string }[]>([]);
  const [selectedBusCommand, setSelectedBusCommand] = useState<{ label: string; value: string }>(null);

  const [taskCategories, setAllTaskCategories] = useState<{ label: string; value: string }[]>([{ label: "ADCS SD card log download", value: "ADCS SD card log download" }]);
  const [selectedTaskCat, setSelectedTaskCat] = useState<any>(null);
  const [taskJsonData, setTaskJsonData] = useState<any>({
        FileType: 15,
        Counter: 0,
        Offset: 0,
        Length: 20480
  });

  const [selectedBusCategorie, setSelectedBusCategorie] = useState({ label: 'All', value: 'All' });
  const [allBusCommands, setAllBusCommands] = useState<any>([]);
  const [filterBusCommands, setFilterBusCommands] = useState<any>([]);

  const userData = useSelector(
    (state: { mission: MissionStore }) => state.mission.userData
  );
  const [otherInput, setOtherInput] = useState<any>(false)

  const [accordionOpen, setAccordionOpen] = useState([])


  useEffect(() => {
    CommandCenterService.getObject()
      ?.then((res) => {
        if (res.data) {
          let busCommands: any = [];
          let allBusCategories: any = [...new Map(res.data.map((m) => [m.funcCategory, m])).values()]
          let filterdBusCategories: [] = [{ label: 'All', value: 'All' }]
          allBusCategories.filter(cat => {
              filterdBusCategories = [
                ...filterdBusCategories,
                { label: cat?.["funcCategory"], value: cat?.['funcCategory'] },
              ]
          })

          setAllBusCategories(filterdBusCategories);
          res.data.forEach((command) => {
            busCommands = [
              ...busCommands,
              { label: command?.["sequenceData"]?.['commandId'] + '-' + command?.["name"], value: command },
            ];
          });

          setFilterBusCommands(busCommands);
          setAllBusCommands(busCommands);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(selectedBusCategorie).length > 0 && selectedBusCategorie?.['value'] !== 'All') {
      let filterBusCommands: any[] = []
      allBusCommands.filter((command) => {
        if (command?.value?.funcCategory === selectedBusCategorie?.value) {
          filterBusCommands = [
            ...filterBusCommands,
            command,
          ]
        }
      })
      setFilterBusCommands(filterBusCommands);
    } else {
      setFilterBusCommands(allBusCommands);
    }
  }, [selectedBusCategorie?.['value']]);



  const get_sat_by_id = () => {
    let payloads = []
    MissionService.get_satellite_by_sat_id(sat_id)?.then((sat) => {
      if (sat?.data) {
        TrueTwin?.["remoteApp"]?.['AppData']?.forEach((payload) => {
          let data: any = {
            App_id: payload?.["appId"],
            payload_id: payload?.['id'],
            name: payload?.["name"],
            isConfigured: payload?.["isConfigured"],
            payloadType: payload?.["payloadType"],
            payloadSubType:  payload?.["payloadSubType"],
            payloadHWName: payload?.["payloadHWName"],
          };
          payloads = [
            ...payloads,
            { label: payload?.["name"], value: data },
          ];
        });
        setPayloadList(payloads);
        setAllPayload(sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"]);
      }
      setLoading(false)
    })
      .catch((err) => {
        console.error(err);
        setLoading(false)
      });
  };

  useEffect(() => {
    if (Object.keys(TrueTwin)?.length > 0) {
      get_sat_by_id();
    }
  }, [TrueTwin]);

  const get_payload_sequences = (select_payload) => {
    allPayloads.forEach((payload) => {
      if (payload?.["user_data"]?.["id"] === select_payload?.["payload_id"]) {
        let sequences = [{ label: 'Other Sequence', value: {} }];
        if (payload?.["sequences"] && payload?.["sequences"]?.length > 0) {
          payload?.["sequences"]?.forEach((sequence) => {
            sequences = [
              { label: sequence?.["seqName"], value: sequence },
              ...sequences,
            ];
            setSequenceList(sequences);
          });
        } else {
          setSequenceList(sequences);
        }
      }
    });
  };

  const validate_json_obj = () => {
    setIsLoading(true);
    if(typeof jsonData === 'string'){
      try {
        var obj = JSON.parse(jsonData);
        if (obj && typeof obj === "object" && Object.keys(obj).length > 0) {
          sendCommand(obj)
        }else{
          toast.error('Please enter valid JSON data.', {toastId: '0'})
          setIsLoading(false);
        }
      }catch (e) { 
        toast.error(e.message);
        setIsLoading(false);
      }
    }else if(typeof jsonData === 'object'){
      try {
        if (jsonData && typeof jsonData === "object" && Object.keys(jsonData).length > 0) {
          sendCommand(jsonData)
        }else{
          toast.error('Please enter valid JSON data.', {toastId: '0'})
          setIsLoading(false);
        }
      }catch (e) { 
        toast.error(e.message);
        setIsLoading(false);
      }
    }else{
      toast.error('Please enter valid JSON data.', {toastId: '0'})
      setIsLoading(false);
    }
  };

  const sendCommand = (command_Data) => {
    // let command_Data = typeof jsonData === 'string' ? validate_json_obj(jsonData) : jsonData

    let format = command_Data?.['sequenceData'].hasOwnProperty('commandId') ? "JSON" : "RAW";
    if (consoleType === "Macro") {
      let data = {
        commandData: command_Data,
        commandTime: new Date().toUTCString(),
      };
      setResponseData((prev: any) => ({ ...prev, [`Command ${index}`]: data, }));
    }
    CommandCenterService.runSequence(TrueTwin?.["groundStationId"], format, TrueTwin?.['trueTwinId'], scenario_id, command_Data)?.then((response) => {
      if (response?.['data']) {
        get_truetwin_datastore_files()
        if (consoleType === "Macro") {
          const data = {
            SubmitStatus: response?.['data']?.["status"],
            commandId: command_Data?.["commandData"]?.['commandId'],
            responseData: response?.['data']?.['responseData'],
            ResponseTime: new Date().toUTCString(),
          };
          setResponseData((prev: any) => ({
            ...prev,
            [`Response ${index}`]: data,
          }));
          setIndex((prev) => prev + 1);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }).catch((err) => {
      if (consoleType === "Macro") {
        const data = {
          SubmitStatus: "Failure",
          responseData: {},
          ResponseTime: new Date().toUTCString(),
        };
        setResponseData((prev: any) => ({
          ...prev,
          [`Response ${index}`]: data,
        }));
        setIndex((prev) => prev + 1);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (responseData && consoleType === "Macro") {
      let d: any[] = [];
      Object.keys(responseData).map((item, index) => {
        const a = JSON.stringify(responseData[item], null, 2);
        d[index] =
          `${index % 2 === 0
            ? responseData[item]["commandTime"]
            : responseData[item]["ResponseTime"]
          } ` +
          ` ${index % 2 === 0 ? "Telecommand sent" : "Response Received"}` +
          " : " +
          `${a}`;
        setResponseList(d);
      });
    }

  }, [responseData]);

  const isSequenceInArray = (key: any, data: any) => {
    let isKeyAready = sequenceList.find(obj => obj.label === key);
    if (!isKeyAready) {
      setSequenceList([...sequenceList, data]);
    }
  }

  const downloadSessionLog = () => {
    CommandCenterService.downloadSessionLogs(truetwin_id)?.then((data) => {
      if (data && data.data) {
        let link = document.createElement("a");
        const mimeType = "application/zip";
        link.href = `data:${mimeType};base64,${data.data?.["Data"]}`;
        link.download = data.data?.['FileData'];
        link.click();
        toast.success("Support logs downloaded successfully")
      }
    }).catch((err) => {
      toast.error("Something went wrong while downloading support logs")
    })
  }

  const create_console_session = () => {
    CommandCenterService.createConsoleSession(truetwin_id)?.then((res) => {
      if (res.data) {
        console.log('console session created')
      }
    }).catch((err) => {
      console.error(err);
    })
  }
  const remove_console_session = () => {
    CommandCenterService.removeConsoleSession(truetwin_id)?.then((res) => {
      if (res.data) {
        console.log('console session removed');
      }
    }).catch((err) => {
      console.error(err);
    })
  }

  useEffect(() => {
    create_console_session()
    return () => {
      remove_console_session()
    }
  }, [])

  const get_truetwin_datastore_files = () => {
    CommandCenterService.getTrueTwinDataStoreFiles(truetwin_id)?.then((res) => {
      if (res?.data && res?.data?.length > 0) {
        const sortedArray = res.data.slice().sort((a, b) => b?.time - a?.time);
        setDatastoreFiles(sortedArray)
      } else {
        setDatastoreFiles([])
      }
    }).catch((err) => {
      console.error(err);
    });
  }



  const download_console_output = () => {
    var text = responseList;
    var filename = "console_output.txt";
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8, ' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }


  const get_true_twin_file_content_from_datastore = (file: any,) => {
    setFileDownloading(file?.['name'])
    CommandCenterService.getTrueTwinDataStoreFilecontent(truetwin_id, file?.['name'])?.then((data) => {
      if (data && data.data) {
        downloadFile(data?.data, file?.['name'])
        setFileDownloading('')
      }
    }).catch((err) => {
      console.error(err);
      setFileDownloading('')
    });
  }

  const delete_file_from_datastore = (file) => {
    const fileType = 'outbound';
    CommandCenterService.deleteFileTrueTwinDataStore(truetwin_id, fileType, file)?.then((data) => {
      if (data && data.data) {
        get_truetwin_datastore_files()
        setDeleteFile({
          isOpen: false,
          name: '',
        })
        toast.success("File deleted successfully")
      }
    }).catch((err) => {
      console.error(err);
      setDeleteFile({
        isOpen: false,
        name: '',
      })
    });
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data && state.data.label === 'Other Sequence' ? '' : '',
      fontStyle: state.data && state.data.label === 'Other Sequence' ? 'italic' : 'normal',
      color: state.data && state.data.label === 'Other Sequence' ? 'grey' : '',
    }),
  };

  const handle_set_json_data = (name, value) => {
    let totalSeconds = name === 'duration' ? value : seqDuration
    const totalMinutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    const remainingMilliseconds = (totalSeconds - totalMinutes * 60 - remainingSeconds) * 1000;

    let data = {
      commandName: name === 'payload' ? value?.['name'] : jsonData?.['commandName'],
      commandType: "Payload",
      sequenceData: {
        commandId: 600,
        commandData: {
          App_id: name === 'payload' ? value?.['App_id'] : name === 'app_id' ? value : jsonData?.['sequenceData']?.['commandData']?.['App_id'],
          Sequence_id: name === 'payload' ? value?.['seq_name'] : name === 'sequence' ? value.seq_name : jsonData?.['sequenceData']?.['commandData']?.['Sequence_id'],
          Sequence_params: name === 'payload' ? value?.['seq_params'] : name === 'sequence' ? value.seq_params : jsonData?.['sequenceData']?.['commandData']?.['Sequence_params'],
          Scheduled_deadline_min: totalMinutes,
          Scheduled_deadline_sec: remainingSeconds,
          Scheduled_deadline_ms: remainingMilliseconds,
        },
        commandHeader: {
          SaId: 1,
          DaId: 134,
          Qos: 3,
        },
      }
    };
    setJsonData(data);
  }

  useEffect(()=>{
    const container = scrollRef.current;
    if (container) {
      (container as HTMLElement).scrollTop = (container as HTMLElement).scrollHeight;
      (container as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    }
  },[responseList])
  
  
  return (
    <div className="w-100 h-100 align-items-center justify-content-center">
      {loading && <Loader />}
      {!loading &&
        <div className="d-flex flex-column h-100 w-100">
          <div className="popup-body-box d-flex px-5">
            <div className="console-input-box w-50 mt-6">
              <div className="d-flex w-100 align-items-center justify-content-between ">

              { TrueTwin?.['Category'] === "FlatSat" && <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={inputType}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setInputType(e.target.value);
                      }}
                    >
                      {["Task Input", "Telecommand Input"].map((value) => (
                        <FormControlLabel
                          key={value}
                          value={value}
                          control={<Radio />}
                          label={value}
                          sx={{
                            ".MuiButtonBase-root": {
                              backgroundColor: "transparent",
                            },
                            "&.Mui-checked": {
                              backgroundColor: "gray",
                            },
                          }}
                        />
                      ))}
              </RadioGroup>}

                {TrueTwin?.['Category'] !== "FlatSat" && <span className="fs-20">Telecommand Input</span>}
                {/* <div className="d-flex gap-2 align-items-center">
                  <AntSwitch
                    disabled={userData?.['feature-allowed']?.['advancedConsole'] === 'No'}
                    checked={isCustom}
                    color="success"
                    onKeyDown={(e)=>{
                        if(e.keyCode === 13){
                            e.preventDefault();
                        }
                      }}
                    onChange={()=>{
                        setJsonData('')
                        setIsCustom(!isCustom)
                    }}
                    inputProps={{ 'aria-label': 'ant design' }}/>
                  <span style={{color: isCustom ? '#CCF54E' : 'gray'}}>Custom</span>
                </div> */}
              </div>

              {!isCustom && inputType==="Telecommand Input" && <div>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center gap-2">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={componentType}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setComponentType(e.target.value);
                        setJsonData('')
                        setSeqDuration(1)
                        setSeqParm('')
                        setIsLoading(false)
                        setSelectedBusCategorie({ label: 'All', value: 'All' })
                        setSelectedBusCommand(null)
                        setSelectedPayload(null)
                        setSelectedSequence(null)
                        setSequenceList([])
                      }}
                    >
                      {["Payload", "Bus Component"].map((value) => (
                        <FormControlLabel
                          key={value}
                          value={value}
                          control={<Radio />}
                          label={value}
                          sx={{
                            ".MuiButtonBase-root": {
                              backgroundColor: "transparent",
                            },
                            "&.Mui-checked": {
                              backgroundColor: "gray",
                            },
                          }}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center gap-3 w-100 mb-4">
                  {componentType === 'Bus Component' && <div className="d-flex flex-column gap-2 w-100 ">
                    <span className="form-content-text">{"Bus Component"}</span>
                    <Select
                      classNamePrefix="Select"
                      options={allBusCategories}
                      value={selectedBusCategorie}
                      placeholder="Bus Component"
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setSelectedBusCommand(null)
                        setJsonData('')
                        setSelectedBusCategorie(e)
                      }}
                    />
                  </div>}
                  {componentType === "Bus Component" && (
                    <div className="d-flex flex-column gap-2 w-100">
                      <span className="form-content-text">{"Telecommand ID"}</span>
                      <Select
                        classNamePrefix="Select"
                        options={filterBusCommands}
                        value={selectedBusCommand}
                        placeholder="Telecommand"
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          let data = {
                            sequenceData: e.value.sequenceData,
                            commandName: e.value.name,
                            commandType: 'BUS'
                          }
                          setSelectedBusCommand(e)
                          setJsonData(data);
                        }}
                      />

                      <div className="d-flex flex-column gap-2 w-100 mt-3">
                        <span className="form-content-text">{"Telecommand"}</span>
                        <div className="bus-comp-intput">
                          <Form.Control className="border-0" type="json" as='textarea' value={jsonData ? typeof jsonData !== 'string' ? JSON.stringify(jsonData, null, 4) : jsonData : ''} rows={17.5} onChange={(e) => {
                            setJsonData(e.target.value)
                          }} />
                        </div>
                      </div>
                    </div>
                  )}
                  {componentType === "Payload" && (
                    <div className="d-flex flex-column gap-2 w-100">
                      <span className="form-content-text">{"Payload"}</span>
                      <Select
                        classNamePrefix="Select"
                        options={payloadsList}
                        value={selectedPayload === null ? null : { label: selectedPayload?.['name'], value: selectedPayload?.['name'] }}
                        placeholder="Payload"
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setOtherInput(false);
                          setSelectedSequence(null);
                          setSeqDuration(1);
                          setSeqParm('');
                          setSelectedPayload(e.value);
                          get_payload_sequences(e.value)
                          let data = {
                            ...e?.value,
                            seq_name: '',
                            seq_params: '',
                          }
                          handle_set_json_data('payload', data)
                        }}
                      />
                    </div>
                  )}
                  {componentType === "Payload" && (
                    <div className={`d-flex flex-column gap-2 w-100 mb-5 ${sequenceList?.length === 0 ? 'opacity-50' : ''}`}>
                      <span className="form-content-text">{"Payload Sequences"}</span>
                      {!otherInput && <Select
                        isDisabled={sequenceList?.length === 0}
                        classNamePrefix="Select"
                        menuPlacement="auto"
                        value={selectedSequence}
                        options={sequenceList}
                        placeholder="Payload Sequences"
                        styles={customStyles}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (e.label === 'Other Sequence') {
                            setOtherInput(true)
                            setSeqDuration(1)
                            let data = {
                              seq_name: '',
                              seq_params: ''
                            }
                            handle_set_json_data('sequence',data)
                            setSeqParm('')
                          } else {
                            let seq_name = e.value.seqName
                            let seq_params = e.value.seqParams ? e.value.seqParams : ''
                            let data = {
                              seq_name,
                              seq_params
                            }
                            handle_set_json_data('sequence',data)
                            setSelectedSequence(e)
                            setSeqParm(seq_params)
                            isSequenceInArray(seq_name, { label: seq_name, value: { seqName: seq_name, seqParams: seq_params, seqDuration: 1, } })
                          }
                        }}
                      />}
                      {otherInput &&
                        <div className="d-flex w-100 position-relative align-items-center">
                          <Form.Control
                            className="w-100"
                            required
                            autoFocus
                            value={jsonData?.['sequenceData']?.['commandData']?.['Sequence_id']}
                            type="text"
                            placeholder="Enter payload sequence name"
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              e.preventDefault()
                              if(e.target.value.length <= 16){
                                let data = {
                                  seq_name: e.target.value,
                                  seq_params: seqParm
                                }
                                handle_set_json_data('sequence',data)
                              }else{
                                toast.error('Payload Sequence name exceed 16 characters',{toastId: '0'})
                              }
                            }}
                          />
                          <div className="input-close-button" onClick={() => {
                            setOtherInput(false)
                            let data = {
                              seq_name: '',
                              seq_params: ''
                            }
                            handle_set_json_data('sequence',data)
                            setSeqParm('')
                            setSelectedSequence(null)
                          }}>
                            <i className="fe fe-x close-icon fs-14"></i>
                          </div>

                        </div>}
                        
                      <div className={`d-flex flex-column gap-3 mt-5 ${componentType === "Payload" ? "opacity-100" : "opacity-25"}`}>
                        <h4 className="mb-0 color-atmos-secondary">
                          Parameters & Duration
                        </h4>
                        <div className="d-flex flex-column align-items-center gap-3 w-100 mb-4">
                          {TrueTwin?.['Category'] === "FlatSat" && 
                            <div className={`d-flex flex-column gap-2 w-100 ${componentType === "Payload" && !selectedPayload?.['name'] ? 'opacity-50' : ''}`}>
                              <span className="form-content-text">{"App ID"}</span>
                              <Form.Control
                                disabled={componentType === "Payload" && !selectedPayload?.['name']}
                                className="w-100"
                                required
                                type="number"
                                step={1}
                                placeholder=" Enter app id"
                                value={jsonData?.['sequenceData']?.["commandData"]?.["App_id"] ? jsonData?.['sequenceData']?.["commandData"]?.["App_id"] : ''
                                }
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  if(!isNaN(+e.target.value)){
                                    let data = e.target.value !== '' ? Math.abs(+e.target.value) : ''
                                    handle_set_json_data('app_id',data)
                                  }
                                }}
                              />
                            </div>
                          }
                          <div className={`d-flex flex-column gap-2 w-100 ${(componentType === "Bus Component" || (componentType === "Payload" && !selectedPayload?.['name'])) ? 'opacity-50' : ''}`}>
                            <span className="form-content-text">{"Parameters"}</span>
                            <Form.Control
                              disabled={
                                componentType === "Bus Component" ||
                                (componentType === "Payload" && !selectedPayload?.['name'])
                              }
                              className="w-100"
                              required
                              type="text"
                              placeholder="Parameters"
                              value={seqParm}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                if(e.target.value.length <= 16){
                                  setSeqParm(e.target.value)
                                  let seq_name = jsonData?.['sequenceData']?.['commandData']?.['Sequence_id'] ? jsonData?.['sequenceData']?.['commandData']?.['Sequence_id'] : ''
                                  let data = {
                                    seq_name: seq_name,
                                    seq_params: e.target.value
                                  }
                                  handle_set_json_data('sequence',data)
                                }else{
                                  toast.error("Sequence parameters should not exceed 16 characters. ",{toastId:'o'})
                                }
                              }}
                            />
                          </div>
                          <div className={`d-flex flex-column gap-2 w-100 ${(componentType === "Bus Component" || (componentType === "Payload" && !selectedPayload?.['name'])) ? 'opacity-50' : ''}`}>
                            <span className="form-content-text">{"Duration (seconds)"}</span>
                            <Form.Control
                              disabled={
                                componentType === "Bus Component" ||
                                (componentType === "Payload" && !selectedPayload?.['name'])
                              }
                              className="w-100"
                              required
                              type="text"
                              placeholder="Duration (seconds)"
                              value={seqDuration}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                if(!isNaN(+e.target.value) && e.target.value?.length <= 7){
                                  setSeqDuration(e.target.value !== '' ? Math.abs(+e.target.value) : '');
                                  
                                  let totalSeconds = Math.abs(+e.target.value)
                                  handle_set_json_data('duration', totalSeconds)
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>}

              {!isCustom && inputType === "Task Input" && <div style={{marginBottom:"30px"}}>
              <div className="d-flex flex-column gap-2 w-100">
                <span className="form-content-text">{"Task Type"}</span>
                <Select
                  classNamePrefix="Select"
                  options={taskCategories}
                  value={selectedTaskCat === null ? null : selectedTaskCat}
                  placeholder="Task Type"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    console.log("Console -> ", e);
                    setSelectedTaskCat(e);
                  }}
                />
              </div>
              
              {selectedTaskCat !== null && <div className="d-flex flex-column gap-2 w-100 mt-3">
                  <span className="form-content-text">{"Telecommand"}</span>
                  <div className="bus-comp-intput">
                    <Form.Control className="border-0" type="json" as='textarea' value={taskJsonData ? typeof taskJsonData !== 'string' ? JSON.stringify(taskJsonData, null, 4) : taskJsonData : ''} rows={17.5} 
                    onChange={(e) => {
                      setTaskJsonData(e.target.value)
                    }} />
                  </div>
              </div>}
                </div>}
  
              {isCustom && <div className="c-intput my-3">
                <Form.Control className="border-0" type="json" as='textarea' rows={31} onChange={(e) => {
                  if (e.target.value !== '') {
                    try {
                      var obj = JSON.parse(e.target.value);
                      if (obj && typeof obj === "object") {
                        let updated_data = {
                          commandName: 'Custom',
                          commandType: "BUS",
                          sequenceData: JSON.parse(e.target.value) ? e.target.value.includes(['sequenceData']) ? JSON.parse(e.target.value)?.['sequenceData'] :
                            JSON.parse(e.target.value) : e.target.value.includes(['sequenceData']) ? JSON.parse(e.target.value)?.['sequenceData'] : JSON.parse(e.target.value)
                        }
                        setJsonData(updated_data);
                      }
                    }
                    catch (e) {
                      toast.error(e.message);
                      setJsonData('')
                    }
                  } else {
                    setJsonData('')
                  }

                }} />
              </div>}
              <div className="w-100 d-flex justify-content-end mt-1">
               {inputType === "Telecommand Input" && <Button
                  disabled={isLoading || Object.keys(jsonData).length === 0 || jsonData?.['sequenceData']?.['commandData']?.['Sequence_id'] === '' || seqDuration === '' || seqDuration === 0}
                  type="button"
                  className="btn btn-primary pe-8 ps-8"
                  onClick={()=>{
                    if((componentType === "Payload" && ((seqDuration === '' || seqDuration === 0) || jsonData?.['sequenceData']?.['commandData']?.['Sequence_id'] === ''))){
                      toast.error(jsonData?.['sequenceData']?.['commandData']?.['Sequence_id'] === '' ? 'Sequence is required.' : seqDuration === '' ? 'Duration is required.' : "Duration can't be 0.");
                    }else{
                      validate_json_obj()
                    }
                  }}
                >
                  Submit
                </Button>}

                {inputType !=="Telecommand Input" && <Button
                 type="button"
                  className="btn btn-primary pe-8 ps-8"
                  onClick={()=>{
                    CommandCenterService.postAdcsSdCardData(taskJsonData,TrueTwin?.['trueTwinId'],TrueTwin?.["groundStationId"]).then((res) => {
                      if(res){
                        toast.success("SD Card Data Uploaded Successfully");
                      }
                    }).then((err)=>{
                      console.log("Error -> ", err)
                    })

                  }}
                >
                  Submit
                </Button>}
              </div>
            </div>
            <div className="d-flex flex-column w-50 gap-3 mt-3">
              <div className="d-flex w-100 align-items-center justify-content-between">
                <div className="s-tabs">
                  <div className="panel panel-tabs">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="Output"
                    >
                      <Nav variant="pills" className="panel-tabs nav-tabs w-auto">
                        <Nav.Item>
                          <Nav.Link eventKey="Output" onClick={() => {
                            setSelectedTab('Output')
                          }}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.11713L8.33333 11.9995L16 8.11713L12.3338 6.09182L16 4.23529L8.33333 0L0 4.23529L3.98498 6.09182L0 8.11713ZM1.14203 8.09758L8.32423 11.4436L14.9312 8.09791L11.7943 6.36502L8.33333 8.11765L4.56075 6.36007L1.14203 8.09758Z" fill="#F0F0F0"/>
                          </svg>
                            Output
                          </Nav.Link>
                          <Nav.Link eventKey="Teledata" 
                            onClick={() => {
                              get_truetwin_datastore_files()
                              setSelectedTab('Teledata')
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                              <path d="M6.54545 0C4.12872 0 0 0.39994 0 2.00004V14C0 15.5999 4.12864 16 6.54545 16C8.96227 16 13.0909 15.6001 13.0909 14V2.00004C13.0909 0.400112 8.96227 0 6.54545 0ZM6.54545 1.00011C9.86846 1.00011 11.8825 1.65016 12.0836 2.00004C11.832 2.39998 9.86837 2.99998 6.54545 2.99998C3.22254 2.99998 1.20836 2.34993 1.00726 2.00004C1.25894 1.64999 3.27294 1.00011 6.54545 1.00011ZM1.00726 3.14998C2.36667 3.80003 4.88404 3.99991 6.54545 3.99991C8.20687 3.99991 10.7243 3.79986 12.0836 3.14998V5.9499C11.8822 6.29995 9.86837 6.99989 6.54545 6.99989C3.17204 6.99989 1.1583 6.34984 1.00726 5.99996V3.14998ZM1.00726 7.15006C2.36667 7.80012 4.88404 8 6.54545 8C8.20687 8 10.7243 7.79994 12.0836 7.15006V9.94999C11.8822 10.3 9.86837 11 6.54545 11C3.17204 11 1.1583 10.3499 1.00726 10V7.15006ZM6.54545 14.9997C3.17204 14.9997 1.1583 14.3497 1.00726 13.9998V11.1498C2.36667 11.7999 4.88404 11.9997 6.54545 11.9997C8.20687 11.9997 10.7243 11.7997 12.0836 11.1498V13.9998C11.8824 14.3498 9.86837 14.9997 6.54545 14.9997Z" fill="#F0F0F0"/>
                            </svg>
                            Teledata {datastoreFiles.length > 0 && <span className="color-atmos fs-14 ms-1">({datastoreFiles.length})</span>}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab.Container>
                  </div>
                </div>

                {/* // Disable Download Support Logs for now // */}
                {/* {selectedTab === 'Output' && <div className="d-flex fs-16 cursor-pointer mt-5 me-3 align-items-center" onClick={downloadSessionLog}>
                  <i className='fe fe-download fs-18 color-atmos'></i>
                  <span class="d-flex color-atmos ms-1">Download Support Logs</span>
                </div>} */}
              </div>

              {selectedTab === 'Output' && <div>
                {isLoading && (
                  <LinearProgress />
                )}
                <div className="c-output position-relative" ref={scrollRef}>
                  <Tooltip title={'Export'} placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          color: "#d8d8d8",
                          backgroundColor: "#0B1422",
                          padding: '8px 20px',
                          fontSize: '14px',
                          borderRadius: '4px',
                          border: '1px solid #0B1422',
                          blur: '15%'
                        }
                      }
                    }}>
                    <button disabled={responseList?.length === 0} className="position-absolute btn btn-outline-primary top-0 end-0 me-3 mt-3 z-1" onClick={download_console_output}> <i className='fe fe-download fs-20'></i> </button>
                  </Tooltip>
                    {responseList?.length > 0 &&
                      Object.keys(responseData)?.map((item, index) => {
                        return (
                          <Accordion 
                            activeKey={accordionOpen.includes(item) ? item : ''} 
                            onClick={()=>{
                              if(accordionOpen.includes(item)){
                                setAccordionOpen(accordionOpen.filter(i => i !== item))
                              }else{
                                setAccordionOpen([...accordionOpen, item])
                              }
                            }}
                            className="accordion z-0">
                            <Accordion.Item eventKey={item} key={index}>
                              <Accordion.Header
                                className={
                                  index % 2 === 0
                                    ? "command"
                                    : responseData[item]?.['commandId'] === 600 ? ((responseData[item]?.["SubmitStatus"] === "Success" && responseData[item]?.['responseData']?.['Num'] === 0) ? "success" : "failed")
                                      : ((responseData[item]?.['commandId'] !== 600 && responseData[item]?.["SubmitStatus"] === "Success") ? "success" : "failed")
                                }
                              >
                                <div className="output-response">
                                  <span>{responseList[index]}</span>
                                </div>{" "}
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className='p-3 bg-light-black rounded-2 border mt-2 w-100'>
                                  <Form.Control className="border-0" disabled type="json" as='textarea' rows={10} value={JSON.stringify(responseData[item], null, 4)}/>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}
                </div>
              </div>}
              {selectedTab === 'Teledata' && <div className="">
                <div className='d-flex flex-column gap-3 w-100'>
                  <div className='d-flex w-100 align-items-center border rounded-3 py-3 px-5 justify-content-between'>
                    <strong className='fs-18 w-30 color-table-header-text align-items-center justify-content-start'>UPLOADED</strong>
                    <strong className='fs-18 d-flex w-45 align-items-center justify-content-center color-table-header-text'>FILE NAME</strong>
                    <strong className='fs-18 w-15 align-items-center justify-content-end color-table-header-text'>ACTION</strong>
                  </div>
                  <div className="w-100 pb-5">
                    {datastoreFiles.length > 0 && datastoreFiles.map(data => (
                      <div key={data?.['name']} className='d-flex w-100 align-items-center py-3 px-5 justify-content-between'>
                        <span className='fs-16 w-30 gap-2 color-text align-items-center justify-content-start'>{data?.['time'].slice(0, 19)} UTC</span>
                        <span className='fs-16 d-flex w-45 gap-2 align-items-center justify-content-center color-text'>{data?.['name']}</span>
                        <div className='fs-20 w-15 gap-3 align-items-center justify-content-end d-flex cursor-pointer'>
                          <CustomToolTip title="Download" placement="top" >
                            {fileDownloading === data?.['name'] ? <i className='fe fe-loader rotate'></i> : <i className='fe fe-download' onClick={() => { get_true_twin_file_content_from_datastore(data) }}></i>}
                          </CustomToolTip>
                          <CustomToolTip title="Delete" placement="top">
                            {deleteFile?.['name'] === data?.['name'] ? <i className='fe fe-loader rotate'></i> :
                              <i onClick={() => {
                                setDeleteFile({
                                  isOpen: true,
                                  name: data?.['name'],
                                })
                              }} className='fe fe-trash'></i>}
                          </CustomToolTip>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>}

      <Dialog
        onClick={(event) => {
          if (event) {
            delete_file_from_datastore(deleteFile?.['name'])
            setDeleteFile({
              ...deleteFile,
              isOpen: false,
            })
          } else {
            setDeleteFile({
              isOpen: false,
              name: ''
            })
          }
        }}
        openModel={deleteFile.isOpen} comment={`Are you sure you want to delete this file?`}
      />
    </div >
  );
};

export default ConsolePanel;
