//@ts-nocheck
import React, { useEffect, useState } from 'react';
import PageHeader from '../../../../../Layouts/PageHeader/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
    MissionStore,
    setEdge,
    setEdgeSelected,
    setEditEdgeData,
    setIsEdgeEdit,
} from '../../../../../store/MissionSlice';
import EdgeSlider from './EdgeSlider';
import CatalogueService from '../../../../../service/CatalogueService';
import { Steps } from '../../Stepper.constants';
import { useNavigate, useParams } from 'react-router-dom';
import { calculateEdgeUsage, calculate_Total_AOP_Usage, navigateTo } from '../../../../CommonComponents/CommonFunctions';
import CloseButton from '../../../../CommonComponents/CloseButton';
import { Button, Form } from 'react-bootstrap';
import MissionService from '../../../../../service/MissionService';
import Select from "react-select";
import Loader from '../../../../../Layouts/Loader/Loader';

const Edge = () => {
    const params = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {mission_name,sat_name,mission_id,sat_id, edge_id} = params


    const isEdgeSelected = useSelector((state: { mission: MissionStore }) => state.mission.isEdgeSelected);
    const isEditEdgeData = useSelector((state: { mission: MissionStore }) => state.mission.isEditEdgeData);
    const [EdgeList, setEdgeList] = useState<any[]>([]);
    const [filterEdgeList, setFilterEdgeList] = useState<any[]>([]);
    const [filterOptions, setFilterOptions] = useState<any>({});
    const [filterOptionsValues, setFilterOptionsValues] = useState<any>(
        {
            'GPU': null,
            'Memory': {
                min: '',
                max: ''
            },
            'Memory Type': null,
            'Storage': {
                min: '',
                max: ''
            },
            'Power': {
                min: '',
                max: ''
            },
            'CPU': {
                min: '',
                max: ''
            } 
        }
    );
    const [satEdges, setSatEdges] = useState<any[]>([]);
    const [satellieteDeta, setSatellieteData] = useState<any>({});
    const EditEdgeData = useSelector((state: { mission: MissionStore }) => state.mission.EditEdgeData);
    const [selectedEdgeId, setSelectedEdgeId] = useState<any>('');
    const [Loading, setLoading] = useState(true);
    const [hover, setHover] = useState(true)

    const filter_unique_values = (arr) => {
        const uniqueValues = {};
        const uniqueArr = arr.filter(item => {
          if (!uniqueValues[item.label]) {
            uniqueValues[item.label] = true;
            return true;
          }
          return false;
        });
      
        return uniqueArr;
      }


    useEffect(() => {
        const response = CatalogueService.getCatalogue('Edge', 'Edge Node')
        response?.then((data) => {
            if (data.data){
                let options = {
                    'GPU': [],
                    'Memory Type': [],
                }
               setEdgeList(data.data)
               setFilterEdgeList(data.data)
               data.data.forEach((edge)=>{
                    options = {
                        ...options,
                        'GPU': filter_unique_values([
                            ...options?.['GPU'],
                            {label: edge?.['product-cookie']?.['GPU'], value: edge?.['product-cookie']?.['GPU']}
                        ]),
                        'Memory Type': filter_unique_values([
                            ...options?.['Memory Type'],
                            {label: edge?.['product-cookie']?.['Memory Type'], value: edge?.['product-cookie']?.['Memory Type']}
                        ]),
                    }
                    setFilterOptions(options)
                })
            }
            setLoading(false);
        }).catch((err) => {
            console.log(err.message);
            setLoading(false);
        })
   }, []);

   useEffect(() => {
        if (sat_id) {
            MissionService.get_satellite_by_sat_id(sat_id)?.then((sat) => {
                if(sat?.data){
                    let all_edges = sat.data?.["versions"]?.[0]?.["assembly"]?.["Edge"] ? sat.data?.["versions"]?.[0]?.["assembly"]?.["Edge"] : []
                    let all_payloads = sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"] ? sat.data?.["versions"]?.[0]?.["assembly"]?.["Payloads"] : []
                    setSatellieteData(sat.data)
                    calculateEdgeUsage(all_edges,dispatch)
                    calculate_Total_AOP_Usage(all_payloads,all_edges,dispatch)
                    if(edge_id && edge_id !== undefined){
                        let edit_edge = all_edges.find(edg=>edg?.['edge-id'] == edge_id)
                        dispatch(setEditEdgeData(edit_edge));
                        dispatch(setIsEdgeEdit(true));
                    }
                }
            }).catch((err) => {
                console.log('Some thing went wrong while getting satellite');
            })
        }
    }, [sat_id]);

    const reset_filter = () => {
        setFilterOptionsValues(
            {
                'GPU': null,
                'Memory': {
                    min: '',
                    max: ''
                },
                'Memory Type': null,
                'Storage': {
                    min: '',
                    max: ''
                },
                'Power': {
                    min: '',
                    max: ''
                },
                'CPU': {
                    min: '',
                    max: ''
                } 
            }
        )
        setFilterEdgeList(EdgeList)
    }



    const onSelectEdge = (data) => {
        if(!isEdgeSelected){
            dispatch(setEdge(data));
            setSelectedEdgeId(data['uniqueId'])
            dispatch(setEdgeSelected(true));
        }
    };


    useEffect(() => {
        if (isEditEdgeData) {
            if (EditEdgeData) {
                setSelectedEdgeId(EditEdgeData?.['edge-device']?.['atmos-data']?.['uniqueId']);
                onSelectEdge(EditEdgeData?.['edge-device']?.['atmos-data']);
            }
        } else {
            setSelectedEdgeId('');
        }
    }, [isEditEdgeData]);

    const navigate_to_step = (step) => {
        if(isEditEdgeData || isEdgeSelected){
            dispatch(setEdge({}));
            dispatch(setEdgeSelected(false));
            dispatch(setIsEdgeEdit(false));
            dispatch(setEditEdgeData({}));
        }
        if(step === 'Payloads' ){
             navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`,navigate)
        }
        if(step === 'Bus Design'){
             navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_template`,navigate)
        }
    }

    
    return (
        <div className='h-100 w-100 '>
            {Loading &&  <Loader/>}
            <PageHeader sat_name={sat_name?.length > 15 ? sat_name.substring(0, 15)+"..." : sat_name} titles="Configure Edge Device" description="Edge device is a compute node used to perform specialized data processing like encryption, compression, etc." active="New Mission" items={['Apps']}
                isButton={false}
                showSwap= {true}
                showPercent= {false}
                type= ''
                value= ''
            />
            <div className='d-flex flex-wrap h-82 w-100'>
                <div className='d-flex w-100 h-100'>
                    <div className='w-20 d-flex p-5 overflow-y-scroll h-100 flex-column gap-3 position-relative'>
                        <div className='d-flex flex-column gap-2'>
                            <span className="">CPU / RT Cores</span>
                            <div className='d-flex align-items-center gap-2'>
                                <Form.Control type="number" placeholder='Minimum' value={filterOptionsValues['CPU']?.['min']} onChange={(e)=>{
                                    setFilterOptionsValues({
                                        ...filterOptionsValues,
                                        'CPU': {
                                            min: e.target.value,
                                            max: filterOptionsValues['CPU']?.['max']
                                        }
                                    })
                                    
                                    let filter_devices = EdgeList?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +e.target.value)
                                    filter_devices = (filterOptionsValues['CPU']?.['max'] !== '' && +filterOptionsValues['CPU']?.['max'] >= +e.target.value) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +filterOptionsValues['CPU']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['Power']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] >= +filterOptionsValues['Power']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Power']?.['max'] !== '' && +filterOptionsValues['Power']?.['max'] >= +filterOptionsValues['Power']?.['min']) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] <= +filterOptionsValues['Power']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['GPU'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Memory']?.['max'] !== '' && +filterOptionsValues['Memory']?.['max'] >= +filterOptionsValues['Memory']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory Type'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Storage']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +filterOptionsValues['Storage']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Storage']?.['max'] !== '' && +filterOptionsValues['Storage']?.['max'] >= +filterOptionsValues['Storage']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= +filterOptionsValues['Storage']?.['max']) : filter_devices
                                    setFilterEdgeList(filter_devices)
                                }}/>
                                <span>-</span>
                                <Form.Control type="number" placeholder='Maximum' value={filterOptionsValues['CPU']?.['max']} onChange={(e)=>{
                                    setFilterOptionsValues({
                                        ...filterOptionsValues,
                                        'CPU': {
                                            min: filterOptionsValues['CPU']?.['min'],
                                            max: e.target.value
                                        }
                                    })
                                    let filter_devices = e.target.value !== '' ? EdgeList?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +e.target.value) : EdgeList
                                    filter_devices = (filterOptionsValues['CPU']?.['min'] !== '' && +filterOptionsValues['CPU']?.['min'] <= +e.target.value) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +filterOptionsValues['CPU']?.['min']) : filter_devices
                                    filter_devices = filterOptionsValues['Power']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] >= +filterOptionsValues['Power']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Power']?.['max'] !== '' && +filterOptionsValues['Power']?.['max'] >= +filterOptionsValues['Power']?.['min']) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] <= +filterOptionsValues['Power']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['GPU'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Memory']?.['max'] !== '' && +filterOptionsValues['Memory']?.['max'] >= +filterOptionsValues['Memory']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory Type'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Storage']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +filterOptionsValues['Storage']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Storage']?.['max'] !== '' && +filterOptionsValues['Storage']?.['max'] >= +filterOptionsValues['Storage']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= +filterOptionsValues['Storage']?.['max']) : filter_devices
                                    setFilterEdgeList(filter_devices)
                                }}/>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <span className="">Memory</span>
                            <div className='d-flex align-items-center gap-2'>
                                <Form.Control type="number" placeholder='Minimum' value={filterOptionsValues['Memory']?.['min']} onChange={(e)=>{
                                    setFilterOptionsValues({
                                        ...filterOptionsValues,
                                        'Memory': {
                                            min: e.target.value,
                                            max: filterOptionsValues['Memory']?.['max']
                                        }
                                    })
                                    let filter_devices = EdgeList?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +e.target.value)
                                    filter_devices = (filterOptionsValues['Memory']?.['max'] !== '' && +filterOptionsValues['Memory']?.['max'] >= +e.target.value) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] <= +filterOptionsValues['Memory']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['Power']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] >= +filterOptionsValues['Power']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Power']?.['max'] !== '' && +filterOptionsValues['Power']?.['max'] >= +filterOptionsValues['Power']?.['min']) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] <= +filterOptionsValues['Power']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['CPU']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +filterOptionsValues['CPU']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['CPU']?.['max'] !== '' && +filterOptionsValues['CPU']?.['max'] >= +filterOptionsValues['CPU']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +filterOptionsValues['CPU']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['GPU'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory Type'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Storage']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +filterOptionsValues['Storage']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Storage']?.['max'] !== '' && +filterOptionsValues['Storage']?.['max'] >= +filterOptionsValues['Storage']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= +filterOptionsValues['Storage']?.['max']) : filter_devices
                                    setFilterEdgeList(filter_devices)
                                }}/>
                                <span>-</span>
                                <Form.Control type="number" placeholder='Maximum' value={filterOptionsValues['Memory']?.['max']} onChange={(e)=>{
                                    setFilterOptionsValues({
                                        ...filterOptionsValues,
                                        'Memory': {
                                            min: filterOptionsValues['Memory']?.['min'],
                                            max: e.target.value
                                        }
                                    })
                                    let filter_devices = e.target.value !== '' ? EdgeList?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] <= +e.target.value) : EdgeList
                                    filter_devices = (filterOptionsValues['Memory']?.['min'] !== '' && +filterOptionsValues['Memory']?.['min'] <= +e.target.value) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['min']) : filter_devices
                                    filter_devices = filterOptionsValues['Power']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] >= +filterOptionsValues['Power']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Power']?.['max'] !== '' && +filterOptionsValues['Power']?.['max'] >= +filterOptionsValues['Power']?.['min']) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] <= +filterOptionsValues['Power']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['CPU']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +filterOptionsValues['CPU']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['CPU']?.['max'] !== '' && +filterOptionsValues['CPU']?.['max'] >= +filterOptionsValues['CPU']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +filterOptionsValues['CPU']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['GPU'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory Type'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Storage']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +filterOptionsValues['Storage']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Storage']?.['max'] !== '' && +filterOptionsValues['Storage']?.['max'] >= +filterOptionsValues['Storage']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= +filterOptionsValues['Storage']?.['max']) : filter_devices
                                    setFilterEdgeList(filter_devices)
                                }}/>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <span className="">Storage</span>
                            <div className='d-flex align-items-center gap-2'>
                                <Form.Control type="number" placeholder='Minimum' value={filterOptionsValues['Storage']?.['min']} onChange={(e)=>{
                                    setFilterOptionsValues({
                                        ...filterOptionsValues,
                                        'Storage': {
                                            min: e.target.value,
                                            max: filterOptionsValues['Storage']?.['max']
                                        }
                                    })
                                    let filter_devices = EdgeList?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +e.target.value)
                                    filter_devices = (filterOptionsValues['Storage']?.['max'] !== '' && +filterOptionsValues['Storage']?.['max'] >= +e.target.value) ? filter_devices?.filter(device => device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= filterOptionsValues['Storage']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['Power']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] >= +filterOptionsValues['Power']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Power']?.['max'] !== '' && +filterOptionsValues['Power']?.['max'] >= +filterOptionsValues['Power']?.['min']) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] <= +filterOptionsValues['Power']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['CPU']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +filterOptionsValues['CPU']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['CPU']?.['max'] !== '' && +filterOptionsValues['CPU']?.['max'] >= +filterOptionsValues['CPU']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +filterOptionsValues['CPU']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['GPU'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory Type'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Memory']?.['max'] !== '' && +filterOptionsValues['Memory']?.['max'] >= +filterOptionsValues['Memory']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] <= +filterOptionsValues['Memory']?.['max']) : filter_devices
                                    setFilterEdgeList(filter_devices)
                                }}/>
                                <span>-</span>
                                <Form.Control type="number" placeholder='Maximum' value={filterOptionsValues['Storage']?.['max']} onChange={(e)=>{
                                    setFilterOptionsValues({
                                        ...filterOptionsValues,
                                        'Storage': {
                                            min: filterOptionsValues['Storage']?.['min'],
                                            max: e.target.value
                                        }
                                    })
                                    let filter_devices = e.target.value !== '' ? EdgeList?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= +e.target.value) : EdgeList
                                    filter_devices = (filterOptionsValues['Storage']?.['min'] !== '' && +filterOptionsValues['Storage']?.['min'] <= +e.target.value) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +filterOptionsValues['Storage']?.['min']) : filter_devices
                                    filter_devices = filterOptionsValues['Power']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] >= +filterOptionsValues['Power']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Power']?.['max'] !== '' && +filterOptionsValues['Power']?.['max'] >= +filterOptionsValues['Power']?.['min']) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] <= +filterOptionsValues['Power']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['CPU']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +filterOptionsValues['CPU']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['CPU']?.['max'] !== '' && +filterOptionsValues['CPU']?.['max'] >= +filterOptionsValues['CPU']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +filterOptionsValues['CPU']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['GPU'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory Type'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Memory']?.['max'] !== '' && +filterOptionsValues['Memory']?.['max'] >= +filterOptionsValues['Memory']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] <= +filterOptionsValues['Memory']?.['max']) : filter_devices
                                    setFilterEdgeList(filter_devices)
                                }}/>
                            </div>
                        </div>
                        {Object.keys(filterOptions).length > 0 && Object.keys(filterOptions).map((key, index) => {
                            return (
                                <div className='d-flex flex-column gap-2' key={index}>
                                    <span className="">{key}</span>
                                    <Select
                                        classNamePrefix="Select"
                                        options={filterOptions?.[key]}
                                        value={filterOptionsValues[key]}
                                        placeholder={"Select " + key}
                                        onKeyDown={(e)=>{
                                            if(e.keyCode === 13){
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e)=>{
                                            setFilterOptionsValues({
                                                ...filterOptionsValues,
                                                [key]: e
                                            })
                                            let filter_devices = EdgeList?.filter(device => device?.["product-cookie"][key] === e.value)
                                            filter_devices = filterOptionsValues['CPU']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +filterOptionsValues['CPU']?.['min']) : filter_devices
                                            filter_devices = (filterOptionsValues['CPU']?.['max'] !== '' && +filterOptionsValues['CPU']?.['max'] >= +filterOptionsValues['CPU']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +filterOptionsValues['CPU']?.['max']) : filter_devices
                                            filter_devices = filterOptionsValues['Power']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] >= +filterOptionsValues['Power']?.['min']) : filter_devices
                                            filter_devices = (filterOptionsValues['Power']?.['max'] !== '' && +filterOptionsValues['Power']?.['max'] >= +filterOptionsValues['Power']?.['min']) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] <= +filterOptionsValues['Power']?.['max']) : filter_devices
                                            filter_devices = (key !== 'GPU' && filterOptionsValues['GPU'] !== null) ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                            filter_devices = filterOptionsValues['Memory']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['min']) : filter_devices
                                            filter_devices = (filterOptionsValues['Memory']?.['max'] !== '' && +filterOptionsValues['Memory']?.['max'] >= +filterOptionsValues['Memory']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['max']) : filter_devices
                                            filter_devices = (key !== 'Memory Type' && filterOptionsValues['Memory Type'] !== null) ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                            filter_devices = filterOptionsValues['Storage']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +filterOptionsValues['Storage']?.['min']) : filter_devices
                                            filter_devices = (filterOptionsValues['Storage']?.['max'] !== '' && +filterOptionsValues['Storage']?.['max'] >= +filterOptionsValues['Storage']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= +filterOptionsValues['Storage']?.['max']) : filter_devices
                                            setFilterEdgeList(filter_devices)
                                        }}
                                    />
                                </div>
                            )
                        })}
                        <div className='d-flex flex-column gap-2'>
                            <span className="">Peak Power</span>
                            <div className='d-flex align-items-center gap-2'>
                                <Form.Control type="number" placeholder='Minimum' value={filterOptionsValues['Power']?.['min']} onChange={(e)=>{
                                    setFilterOptionsValues({
                                        ...filterOptionsValues,
                                        'Power': {
                                            min: e.target.value,
                                            max: filterOptionsValues['Power']?.['max']
                                        }
                                    })
                                    let filter_devices = EdgeList?.filter(device => +device?.["max_power_consumption_in_watts"] >= +e.target.value)
                                    filter_devices = (filterOptionsValues['Power']?.['max'] !== '' && +filterOptionsValues['Power']?.['max'] >= +e.target.value) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] <= +filterOptionsValues['Power']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['CPU']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +filterOptionsValues['CPU']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['CPU']?.['max'] !== '' && +filterOptionsValues['CPU']?.['max'] >= +filterOptionsValues['CPU']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +filterOptionsValues['CPU']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['GPU'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Memory']?.['max'] !== '' && +filterOptionsValues['Memory']?.['max'] >= +filterOptionsValues['Memory']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory Type'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Storage']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +filterOptionsValues['Storage']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Storage']?.['max'] !== '' && +filterOptionsValues['Storage']?.['max'] >= +filterOptionsValues['Storage']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= +filterOptionsValues['Storage']?.['max']) : filter_devices
                                    setFilterEdgeList(filter_devices)
                                }}/>
                                <span>-</span>
                                <Form.Control type="number" placeholder='Maximum' value={filterOptionsValues['Power']?.['max']} onChange={(e)=>{
                                    setFilterOptionsValues({
                                        ...filterOptionsValues,
                                        'Power': {
                                            min: filterOptionsValues['Power']?.['min'],
                                            max: e.target.value
                                        }
                                    })
                                    let filter_devices = e.target.value !== '' ? EdgeList?.filter(device => +device?.["max_power_consumption_in_watts"] <= +e.target.value) : EdgeList
                                    filter_devices = (filterOptionsValues['Power']?.['min'] !== '' && +filterOptionsValues['Power']?.['min'] <= +e.target.value) ? filter_devices?.filter(device => +device?.["max_power_consumption_in_watts"] >= +filterOptionsValues['Power']?.['min']) : filter_devices
                                    filter_devices = filterOptionsValues['CPU']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] >= +filterOptionsValues['CPU']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['CPU']?.['max'] !== '' && +filterOptionsValues['CPU']?.['max'] >= +filterOptionsValues['CPU']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['CPU Cores'] <= +filterOptionsValues['CPU']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['GPU'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['GPU'] === filterOptionsValues['GPU']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Memory']?.['max'] !== '' && +filterOptionsValues['Memory']?.['max'] >= +filterOptionsValues['Memory']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Memory']?.split(' ')[0] >= +filterOptionsValues['Memory']?.['max']) : filter_devices
                                    filter_devices = filterOptionsValues['Memory Type'] !== null ? filter_devices?.filter(device => device?.["product-cookie"]?.['Memory Type'] === filterOptionsValues['Memory Type']?.['value']) : filter_devices
                                    filter_devices = filterOptionsValues['Storage']?.['min'] !== '' ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] >= +filterOptionsValues['Storage']?.['min']) : filter_devices
                                    filter_devices = (filterOptionsValues['Storage']?.['max'] !== '' && +filterOptionsValues['Storage']?.['max'] >= +filterOptionsValues['Storage']?.['min']) ? filter_devices?.filter(device => +device?.["product-cookie"]?.['Storage']?.split(' ')[0] <= +filterOptionsValues['Storage']?.['max']) : filter_devices
                                    setFilterEdgeList(filter_devices)
                                }}/>
                            </div>
                        </div>
                        <div className='w-90 d-flex justify-content-center position-absolute bottom-0 mb-2'>
                            <button className='btn btn-outline-primary px-8 fs-18' onClick={reset_filter}>Reset</button>
                        </div>
                    </div>
                    <span className='border-left-3 h-100'></span>
                    <div className="flex-1 d-flex flex-wrap edge-cards overflow-y-scroll py-5 ms-5">
                        {filterEdgeList?.length > 0 && filterEdgeList?.map((data,i) => {
                            return <div className={data['uniqueId'] === selectedEdgeId ? 'common-card-box h-365 active' : 'common-card-box h-365'}
                                        key={i} onClick={() => {
                                onSelectEdge(data);
                            }}>
                                <div className="common-card-header">
                                    {data['name']}
                                </div>
                                <div className="common-card-content">
                                    <div className="d-flex flex-wrap">
                                        <div className="stats-alpha">
                                            <strong>{data['product-cookie']['CPU Cores']}</strong>
                                            <span>CPU Cores</span>
                                        </div>
                                        <div className="stats-alpha lg">
                                            <strong>{data['product-cookie']['GPU']}</strong>
                                            <span>GPU</span>
                                        </div>
                                        <div className="stats-alpha">
                                            <strong>{data['product-cookie']['Memory']}</strong>
                                            <span>Memory</span>
                                        </div>
                                        <div className="stats-alpha">
                                            <strong>{data['product-cookie']['Memory Type']}</strong>
                                            <span>Memory Type</span>
                                        </div>
                                        <div className="stats-alpha lg">
                                            <strong>{data['product-cookie']['Storage']}</strong>
                                            <span>Storage</span>
                                        </div>
                                        <div className="stats-alpha">
                                            <strong>{data['max_power_consumption_in_watts']+' W'}</strong>
                                            <span>Peak Power</span>
                                        </div>
                                        <div className="stats-alpha">
                                            <strong>{data['product-cookie']['Performance']}</strong>
                                            <span>Performance</span>
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
            </div>

            <div className="navigator-btn gap-3">
                {!isEditEdgeData && <CloseButton />}
                {isEditEdgeData && <Button
                variant="primary-outline"
                type="button"
                className="btn btn-outline-danger pt-1 pb-1 pe-6 ps-6 me-2 fs-14"
                onClick={() => {
                    dispatch(setIsEdgeEdit(false));
                    dispatch(setEditEdgeData({}));
                    navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`,navigate)
                }}
                >
                    Cancel
                </Button>}
                <div className="btn-group btn-grp-nav">
                    <Button
                        variant={hover ? "outline-primary" : "primary"}
                        type="button"
                        className={`d-flex align-items-center btn ${
                            hover ? "btn-outline-primary" : "btn-primary"
                        } pt-1 pb-1 pe-4 ps-3 fs-14`}
                        onClick={()=>{
                            if(satEdges?.length > 0){
                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`,navigate)
                            }else{
                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`,navigate)
                            }
                        }}
                        onMouseEnter={() => {
                            setHover(false);
                        }}
                        onMouseLeave={() => {
                            setHover(true);
                        }}
                    >
                    <i className="fe fe-chevron-left me-1 fs-16"></i> Back
                    </Button>
                    <Button
                    variant={hover ? "primary" : "outline-primary"}
                    type="submit"
                    className={`d-flex align-items-center btn ${
                        hover ? "btn-primary" : "btn-outline-primary"
                    } pt-1 pb-1 pe-3 ps-4 fs-14`}
                    onClick={()=>{
                        navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_template`,navigate)
                    }}
                    >
                {satEdges?.length > 0 ? 'Next' : 'Skip'}
                    <i className="fe fe-chevron-right ms-1 fs-16"></i>
                    </Button>
                </div>
            </div>

            {isEdgeSelected && <EdgeSlider satEdges={satEdges} setSatEdges={setSatEdges} satellieteDeta={satellieteDeta} setSelectedEdgeId={setSelectedEdgeId} />}
            <div className="stepper-container">
                <div className="breadcrumb">
                    {Steps.map((data) => {
                        return <a
                            onClick={()=>{navigate_to_step(data.title)}}
                            className={data.title === 'Edge' ? 'active' : ''}
                            key={data.title}>
                            <span className={data.title !== 'Review' ? "breadcrumb__inner" : 'breadcrumb__inner_deactive'}>
                                <span className="breadcrumb__title">
                                    {data.svg}
                                </span>
                                <span className="breadcrumb__desc">{data.title}</span>
                            </span>
                        </a>
                    })}
                </div>
            </div>
        </div>
    )
};
export default Edge;

