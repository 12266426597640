import { Fragment, useEffect, useState, useRef } from "react";
import ModalHeader from "./scenario_modal_stepper/ModalHeader";
import "./NewScenarioFlow.css";
import ScenarioDetails from "./scenario_modal_stepper/ScenarioDetails";
import OrbitalDetails from "./scenario_modal_stepper/OrbitalDetails";
import { useNavigate, useParams } from "react-router-dom";
import PhysicalCharacteristics from "./scenario_modal_stepper/PhysicalCharacteristics";
import AdditionaOrbitDetails from "./scenario_modal_stepper/externalOrbits/AdditionaOrbitDetails";
import ScenarioSchedule from "./scenario_modal_stepper/ScenarioSchedule";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ScenarioGroundStation from "./scenario_modal_stepper/ScenarioGroundStation";
import MissionService from "../../../../../service/MissionService";
import CommandCenterService from "../../../../../service/CommandCenterService";
import CatalogueService from "../../../../../service/CatalogueService";
import { capitalizeFirstLetter, navigateTo, truncateNumber } from "../../../../CommonComponents/CommonFunctions";
import VisualizationWrapper from "./scenario_visualization/VisualizationWrapper";
import ScenarioScheduleModal from "./modal/ScenarioScheduleModal";
import { toast } from "react-toastify";
import LoaderAnimation from "../../Loader/LoaderAnimation";
import Loader from "../../../../../Layouts/Loader/Loader";
import { MissionStore } from "../../../../../store/MissionSlice";
import { useSelector } from "react-redux";
import GroundStationService from "../../../../../service/GroundStationService";

dayjs.extend(utc)

type ImportedScheduletype = {
  file_data: any,
  name: '',
  schedule_upload: boolean,
  schedule_imported: boolean,
  show_upload_btn: boolean,
  show_deploy_btn: boolean,
}

const NewScenarioFlow = () => {
  const params = useParams();
  const {
    mission_name,
    mission_id,
    sat_name,
    sat_id,
    truetwin_name,
    truetwin_id,
    scenario_name,
    scenario_id,
    scenario_step
  } = params;

  const navigate = useNavigate()

  const scrollRef = useRef(null)
  const formSectionRef = useRef(null)
  const [openTaskPlanMenu, setOpenTaskPlanMenu] = useState(false)

  const userData = useSelector((state: { mission: MissionStore }) => state.mission.userData);
  const [isModalVisible, setisModalVisible] = useState(true);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  
  const [deployLoader, setDeployLoader] = useState<any>(false);

  const [showManualGroundStations, setShowManualGroundStations] = useState(false);
  const [newSimulationTime, setNewSimulationTime] = useState<any>('');

  const [addAdditionaOrbitDetails, setAddAdditionaOrbitDetails] = useState<any>(false);
  const [dataSubmited, setDataSubmited] = useState<any>([]);
  const [scenarioScheduleData, setScenarioScheduleData] = useState<any>([]);
  const [scenarioScheduled, setScenarioScheduled] = useState<any>(false);
  const [Satellite, setSatellite] = useState<any>({});
  const [Template, setTemplate] = useState<any>({});
  const [Mission, setMission] = useState<any>({});
  const [MissionGSList, setMissionGSList] = useState<any>([]);
  const [TrueTwin, setTrueTwin] = useState<any>({});
  const [loading, setLoading] = useState(true)
  const [scenarioResponseData, setScenarioResponseData] = useState<any>({})
  const [uploadFileStatus,setUploaddFileStatus] = useState<any>()
  const [importedSchedule, setImportedSchedule] = useState<ImportedScheduletype>({
    file_data: null,
    name: '',
    schedule_upload: false,
    schedule_imported: false,
    show_upload_btn: false,
    show_deploy_btn: false
  })
  const [scenarioData, setScenarioData] = useState<any>({
    scenario_details: {
      scenarioName: "",
      sampleRate: 1,
      draftMode: 'Yes',
    },
    orbit_details: {
      epoch: '',
      altitude: "",
      eccentricity: "",
      inclination: "",
      RAAN: "",
      AP: "",
      TA: ""
    },
    physical_characterisitics: {
      mass: "",
      momentOfInertia: {
        Ixx: "",
        Ixy: "",
        Ixz: "",
        Iyy: "",
        Iyz: "",
        Izz: "",
      },
      dimensions: {
        length: "",
        width: "",
        height: "",
      },
      orientation:{
        yaw: '',
        pitch: '',
        roll: '',
      }
    },
    additional_orbits: [],
    additional_orbit_details: {
      name: "",
      altitude: "",
      eccentricity: "",
      inclination: "",
      RAAN: "",
      AP: "",
      TA: ""
    },
    ground_stations: [],
    task_plan: '',
    start_time: '',
    duration: 120,
  })
  const [isViewButtonEnabled, setIsViewButtonEnabled] = useState(false);

  const navigate_to_truetwin_details = () => {
    navigateTo(
        `${mission_name}/${mission_id}/${sat_name}/${sat_id}/truetwin_details/${truetwin_name}/${truetwin_id}/Scenarios`,
        navigate
    );
  }

  const navigate_to_tt_dashboard = () => {
    navigateTo(
      `${mission_name}/${mission_id}/${sat_name}/${sat_id}/${truetwin_name}/${truetwin_id}/dashboard/${scenario_name}/${scenario_id}`,
      navigate
    );
  };

  const navigate_scenario = (step: string,scenarioName?: string,scenarioId?: string) => {
    navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/${sat_name}/${sat_id}/${truetwin_name}/${truetwin_id}/update_scenario/${scenarioName}/${scenarioId}/${step}`);
  }


  const check_current_step = () => {
    if(scenario_id && scenario_id !== undefined){
      MissionService.getScenarioById(scenario_id).then((res) => {
        if(res?.data){
          let response = res?.data
          let scenario_gs:string[] = [] 
          if(response?.groundStations){
            response.groundStations.map(st =>{
              if(scenarioData.ground_stations.includes(st)){
                scenario_gs.push(st)
              }
            })
          }
          let scenario_data = {
            scenario_details: {
              scenarioName: response?.scenarioName ? response?.scenarioName : scenarioData.scenario_details.scenarioName,
              sampleRate: response?.sampleRate ? response?.sampleRate : scenarioData.scenario_details.sampleRate,
              draftMode: response?.draftMode ? response?.draftMode : scenarioData.scenario_details.draftMode,
            },
            orbit_details: {
              altitude: (response?.orbit && 'altitude' in response?.orbit) ? response?.orbit?.altitude : scenarioData.orbit_details.altitude,
              eccentricity: (response?.orbit && 'eccentricity' in response?.orbit) ? response?.orbit?.eccentricity : scenarioData.orbit_details.eccentricity,
              inclination: (response?.orbit && 'inclination' in response?.orbit) ? response?.orbit?.inclination : scenarioData.orbit_details.inclination,
              RAAN: (response?.orbit && 'RAAN' in response?.orbit) ? response?.orbit?.RAAN : scenarioData.orbit_details.RAAN,
              AP: (response?.orbit && 'AP' in response?.orbit) ? response?.orbit?.AP : scenarioData.orbit_details.AP,
              TA: (response?.orbit && 'TA' in response?.orbit) ? response?.orbit?.TA : scenarioData.orbit_details.TA,
              epoch: (response?.orbit && 'epoch' in response?.orbit) ? dayjs.utc(new Date(response?.orbit?.epoch * 1000)) : scenarioData.orbit_details.epoch,
            },
            physical_characterisitics: {
              mass: (response?.physicalCharacteristics && 'mass' in response?.physicalCharacteristics) ? response?.physicalCharacteristics?.mass : scenarioData.physical_characterisitics.mass,
              momentOfInertia: {
                Ixx: (response?.physicalCharacteristics?.momentOfInertia && 'Ixx' in response?.physicalCharacteristics?.momentOfInertia) ? response?.physicalCharacteristics?.momentOfInertia?.Ixx : scenarioData?.physical_characterisitics?.momentOfInertia?.Ixx,
                Ixy: (response?.physicalCharacteristics?.momentOfInertia && 'Ixy' in response?.physicalCharacteristics?.momentOfInertia) ? response?.physicalCharacteristics?.momentOfInertia?.Ixy : scenarioData?.physical_characterisitics?.momentOfInertia?.Ixy,
                Ixz: (response?.physicalCharacteristics?.momentOfInertia && 'Ixz' in response?.physicalCharacteristics?.momentOfInertia) ? response?.physicalCharacteristics?.momentOfInertia?.Ixz : scenarioData?.physical_characterisitics?.momentOfInertia?.Ixz,
                Iyy: (response?.physicalCharacteristics?.momentOfInertia && 'Iyy' in response?.physicalCharacteristics?.momentOfInertia) ? response?.physicalCharacteristics?.momentOfInertia?.Iyy : scenarioData?.physical_characterisitics?.momentOfInertia?.Iyy,
                Iyz: (response?.physicalCharacteristics?.momentOfInertia && 'Iyz' in response?.physicalCharacteristics?.momentOfInertia) ? response?.physicalCharacteristics?.momentOfInertia?.Iyz : scenarioData?.physical_characterisitics?.momentOfInertia?.Iyz,
                Izz: (response?.physicalCharacteristics?.momentOfInertia && 'Izz' in response?.physicalCharacteristics?.momentOfInertia) ? response?.physicalCharacteristics?.momentOfInertia?.Izz : scenarioData?.physical_characterisitics?.momentOfInertia?.Izz
              },
              dimensions:{
                length: (response?.physicalCharacteristics?.dimensions && 'length' in response?.physicalCharacteristics?.dimensions) ? response?.physicalCharacteristics?.dimensions?.length : scenarioData?.physical_characterisitics?.dimensions?.length,
                width: (response?.physicalCharacteristics?.dimensions && 'width' in response?.physicalCharacteristics?.dimensions) ? response?.physicalCharacteristics?.dimensions?.width : scenarioData?.physical_characterisitics?.dimensions?.width,
                height: (response?.physicalCharacteristics?.dimensions && 'height' in response?.physicalCharacteristics?.dimensions) ? response?.physicalCharacteristics?.dimensions?.height : scenarioData?.physical_characterisitics?.dimensions?.height,
              },
              orientation:{
                roll: (response?.physicalCharacteristics?.orientation && 'roll' in response?.physicalCharacteristics?.orientation) ? response?.physicalCharacteristics?.orientation?.roll : scenarioData?.physical_characterisitics?.orientation?.roll,
                pitch: (response?.physicalCharacteristics?.orientation && 'pitch' in response?.physicalCharacteristics?.orientation) ? response?.physicalCharacteristics?.orientation?.pitch : scenarioData?.physical_characterisitics?.orientation?.pitch,
                yaw: (response?.physicalCharacteristics?.orientation && 'yaw' in response?.physicalCharacteristics?.orientation) ? response?.physicalCharacteristics?.orientation?.yaw : scenarioData?.physical_characterisitics?.orientation?.yaw
              }
            },
            additional_orbits: (response?.externalAttachedOrbits && response?.['externalAttachedOrbits']?.length > 0) ? response?.externalAttachedOrbits : scenarioData.additional_orbits,
            additional_orbit_details: scenarioData?.additional_orbit_details,
            ground_stations: response?.groundStations ? scenario_gs : scenarioData.ground_stations,
            task_plan: response?.taskPlanId ? response?.taskPlanId : scenarioData.task_plan,
            start_time: response?.simulationStartTime ? dayjs.utc(new Date(response?.simulationStartTime * 1000)) : scenarioData.start_time,
            duration: response?.simulationDuration ? response?.simulationDuration : scenarioData.duration,
          }
          setScenarioResponseData(response)
          
          if (scenario_step === "Scenario_Details" || (scenario_step === "Orbital_Details" && response?.orbit) || 
            (scenario_step === "Physical_Characteristics" && response?.physicalCharacteristics?.mass) ||
            (scenario_step === 'Additional_Orbits' && response?.externalAttachedOrbits) || scenario_step === "Ground_Station" || scenario_step === "Schedule") {
              setScenarioData(scenario_data)
            }
          if(response?.['scenarioName']){
            if(response?.orbit && response?.draftMode === 'No'){
              if(response?.physicalCharacteristics?.mass !== ''){
                if(response?.externalAttachedOrbits && response?.externalAttachedOrbits?.length > 0){
                  if(response?.groundStations && response?.groundStations?.length > 0){
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
                  }else{
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits'])
                  }
                }else{
                  calculate_orbital_details(scenario_data)
                  if(scenario_gs?.length > 0){
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
                  }else{
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits'])
                  }
                }
              }else{
                setDataSubmited(['Scenario_Details', 'Orbital_Details'])
                calculate_phyiscal_characteristics(scenario_data)
              }
              setScenarioScheduled(true)
            }else{
              setShowManualGroundStations(true)
              setDataSubmited(['Scenario_Details'])
              calculate_orbital_details(scenario_data)
            }
          }
        }
      })?.catch((err) => {
        console.log(err);
        toast.error('Error in getting scenario details.', {toastId: '0'});
      })
    }
  }


  const get_scenario_data = (scenarioId) => {
    MissionService.getScenarioById(scenarioId).then((res) => {
      if(res?.data){
        setScenarioResponseData(res?.data)

        if(res?.data?.groundStations && res?.data?.groundStations?.length > 0){
          setScenarioScheduled(true)
        }
        if(scenario_step === "Schedule"){
          setScenarioScheduleData((res?.data?.taskSchedule && res?.data?.taskSchedule?.length > 0) ? res?.data?.taskSchedule : [])
          setScenarioData({
            ...scenarioData,
            start_time: res?.data?.simulationStartTime ? dayjs.utc(new Date(res?.data?.simulationStartTime * 1000)) : scenarioData.start_time,
            duration: res?.data?.['simulationDuration']
          })
          setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station', 'Schedule'])
        }
      }
    })?.catch((err) => {
      console.log(err);
      toast.error('Error in getting scenario details.', {toastId: '0'});
    })
  }


  const get_global_groundstations = (mission_gs_list) => {
    GroundStationService.getGroundStationsByMissionId()?.then((res) => {
      if (res.data) {
        let gs_list = res?.['data'].filter(gs => mission_gs_list.includes(gs?.['groundStationId']))
        setMissionGSList(gs_list)
        if(gs_list?.length > 0 && window.location.pathname?.includes('create_scenario')){
          setShowManualGroundStations(true)
        }
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  const get_mission_details = () => {
    if(mission_id && mission_id !== undefined ){
      MissionService.get_mission_by_id(mission_id)?.then((res) => {
        if(res.data){
          setMission(res?.data);
          get_satellite();
          if(res?.['data']?.['gsList'] && res?.['data']?.['gsList']?.length > 0){
            let gs_list = res?.['data']?.['gsList']
            get_global_groundstations(gs_list)
            setScenarioData({
              ...scenarioData,
              ground_stations: gs_list
            })
          }else{
            setLoading(false);
          }
        }
      }).catch((err) => {
        console.log(err)
        setLoading(false);
      })
    }
  }

  const get_satellite = () => {
    if(sat_id && sat_id !== undefined ){
      MissionService.get_satellite_by_sat_id(sat_id)
      ?.then((sat) => {
        if (sat.data) {
          get_bus_template(sat.data?.["versions"]?.[0]?.["assembly"]?.["Template"])
          get_truetwin_details();
          setSatellite(sat?.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }
  };

  const get_bus_template = (tempId) => {
    CatalogueService.getTemplate(tempId)?.then((temp) => {
      if(temp?.data){
        setTemplate(temp?.data);
      }
    })?.catch((err) => {
      console.error(err);
    })
  }
  const get_truetwin_details = () => {
    CommandCenterService.getTrueTwinStatus(truetwin_id)
    ?.then((res) => {
      if(res.data){
        setTrueTwin(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
    });
  }

  useEffect(() => {
    get_mission_details();
  }, [mission_id]);


  useEffect(() => {
    if (Object.keys(Template)?.length > 0 && scenario_id) {
      check_current_step()
    }
  },[Template, scenario_id])


  const calculate_phyiscal_characteristics = (scenario_data) => {
    let temp_size = Template?.['template-attribute']?.['bus-type']
    switch(temp_size){
      case '3U':
        setScenarioData({
          ...scenario_data,
          physical_characterisitics: {
            ...scenario_data?.physical_characterisitics,
            mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
            momentOfInertia: {
              Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 0.015,
              Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0.0,
              Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0.0,
              Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 0.05,
              Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0.0,
              Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 0.06,
            },
            dimensions: {
              length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 10,
              width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 10,
              height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
            },
            orientation: {
              roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
              pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
              yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
            }
          }
        })
        break;
      case '6U':
        setScenarioData({
          ...scenario_data,
          physical_characterisitics: {
            ...scenario_data?.physical_characterisitics,
            mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
            momentOfInertia: {
              Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 0.15,
              Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0.0,
              Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0.0,
              Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 0.4,
              Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0.0,
              Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 0.45,
            },
            dimensions: {
              length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 10,
              width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 20,
              height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
            },
            orientation: {
              roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
              pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
              yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
            }
          }
        })
        break;
      case '8U':
        setScenarioData({
          ...scenario_data,
          physical_characterisitics: {
            ...scenario_data?.physical_characterisitics,
            mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
            momentOfInertia: {
              Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 0.15,
              Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0.0,
              Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0.0,
              Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 0.4,
              Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0.0,
              Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 0.45,
            },
            dimensions: {
              length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 20,
              width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 20,
              height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
            },
            orientation: {
              roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
              pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
              yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
            }
          }
        })
        break;
      case '12U':
        setScenarioData({
          ...scenario_data,
          physical_characterisitics: {
            ...scenario_data?.physical_characterisitics,
            mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
            momentOfInertia: {
              Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 0.5,
              Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0.0,
              Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0.0,
              Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 1.0,
              Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0.0,
              Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 1.2,
            },
            dimensions: {
              length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 20,
              width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 20,
              height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
            },
            orientation: {
              roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
              pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
              yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
            }
          }
        })
        break;
      case '16U':
        setScenarioData({
          ...scenario_data,
          physical_characterisitics: {
            ...scenario_data?.physical_characterisitics,
            mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
            momentOfInertia: {
              Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 1.4,
              Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0.0,
              Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0.0,
              Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 1.7,
              Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0.0,
              Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 2.0,
            },
            dimensions: {
              length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 20,
              width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 20,
              height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 40
            },
            orientation: {
              roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
              pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
              yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
            }
          }
        })
        break;
      case '27U':
         setScenarioData({
          ...scenario_data,
          physical_characterisitics: {
            ...scenario_data?.physical_characterisitics,
            mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
            momentOfInertia: {
              Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 1.4,
              Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0.0,
              Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0.0,
              Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 1.95,
              Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0.0,
              Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 2.0,
            },
            dimensions: {
              length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 30,
              width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 30,
              height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
            },
            orientation: {
              roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
              pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
              yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
            }
          }
        })         
        break;
      case 'ESPA100kg':
          setScenarioData({
            ...scenario_data,
            physical_characterisitics: {
              ...scenario_data?.physical_characterisitics,
              mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
              momentOfInertia: {
                Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 8,
                Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0,
                Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0,
                Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 35,
                Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0,
                Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 40,
              },

              dimensions: {
                length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 30,
                width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 30,
                height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
              },
              orientation: {
                roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
                pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
                yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
              }
            }
          })
          break;

      case 'ESPA200kg':
          setScenarioData({
            ...scenario_data,
            physical_characterisitics: {
              ...scenario_data?.physical_characterisitics,
              mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
              momentOfInertia: {
                Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 30,
                Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0,
                Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0,
                Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 100,
                Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0,
                Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 125,
              },

              dimensions: {
                length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 30,
                width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 30,
                height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
              },
              orientation: {
                roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
                pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
                yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
              }
            }
          })
          break;

      case 'ESPA500kg':
          setScenarioData({
            ...scenario_data,
            physical_characterisitics: {
              ...scenario_data?.physical_characterisitics,
              mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
              momentOfInertia: {
                Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 150,
                Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0,
                Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0,
                Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 150,
                Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0,
                Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 150,
              },

              dimensions: {
                length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 30,
                width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 30,
                height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
              },
              orientation: {
                roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
                pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
                yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
              }
            }
          })
          break;

      case 'm600':
            setScenarioData({
              ...scenario_data,
              physical_characterisitics: {
                ...scenario_data?.physical_characterisitics,
                mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
                momentOfInertia: {
                  Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : 10.9488,
                  Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : -0.0162,
                  Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : -0.043,
                  Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : 42.1077,
                  Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : -0.09198,
                  Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : 38.1495,
                },

                dimensions: {
                  length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 30,
                  width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 30,
                  height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 30
                },
                orientation: {
                  roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
                  pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
                  yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
                }
              }
            })
            break;
  
          
      default:
        setScenarioData({
          ...scenario_data,
          physical_characterisitics: {
            ...scenario_data?.physical_characterisitics,
            mass: scenario_data?.physical_characterisitics?.mass !== '' ? scenario_data?.physical_characterisitics?.mass : Number(Satellite?.['swap-info']?.['totalUsedMass']),
            momentOfInertia: {
              Ixx: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixx : truncateNumber(Number((((1/12) * Satellite?.['swap-info']?.['totalUsedMass'] * ((70 * 70) + (50 * 50)))/10000)), 2),
              Ixy: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixy : 0,
              Ixz: scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Ixz : 0,
              Iyy: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyy : truncateNumber(Number((((1/12) * Satellite?.['swap-info']?.['totalUsedMass'] * ((100 * 100) + (50 * 50)))/10000)), 2),
              Iyz: scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Iyz : 0,
              Izz: scenario_data?.physical_characterisitics?.momentOfInertia?.Izz !== '' ? scenario_data?.physical_characterisitics?.momentOfInertia?.Izz : truncateNumber(Number((((1/12) * Satellite?.['swap-info']?.['totalUsedMass'] * ((100 * 100) + (70 * 70)))/10000)), 2),
            },
            dimensions: {
              length: scenario_data?.physical_characterisitics?.dimensions?.length !== '' ? scenario_data?.physical_characterisitics?.dimensions?.length : 100,
              width: scenario_data?.physical_characterisitics?.dimensions?.width !== '' ? scenario_data?.physical_characterisitics?.dimensions?.width : 70,
              height: scenario_data?.physical_characterisitics?.dimensions?.height !== '' ? scenario_data?.physical_characterisitics?.dimensions?.height : 50
            },
            orientation: {
              roll: scenario_data?.physical_characterisitics?.orientation?.roll ? scenario_data?.physical_characterisitics?.orientation?.roll : 0,
              pitch: scenario_data?.physical_characterisitics?.orientation?.pitch ? scenario_data?.physical_characterisitics?.orientation?.pitch : 0,
              yaw: scenario_data?.physical_characterisitics?.orientation?.yaw ? scenario_data?.physical_characterisitics?.orientation?.yaw : 0
            }
          }
        })
    }
  }

  const calculate_orbital_details = (scenario_data) => {
    if(scenario_step === "Scenario_Details" || scenario_step === "Orbital_Details"){
      setScenarioData({
        ...scenario_data,
        start_time: scenario_data?.start_time !== '' ? scenario_data?.start_time : dayjs.utc(new Date()),
        orbit_details:{
          epoch: scenario_data?.orbit_details?.epoch !== '' ? scenario_data?.orbit_details?.epoch : dayjs.utc(new Date()),
          altitude : scenario_data?.orbit_details?.altitude !== '' ? scenario_data?.orbit_details?.altitude : Mission?.['mission-attributes']?.['Altitude_range']?.['max'],
          inclination: scenario_data?.orbit_details?.inclination !== '' ? scenario_data?.orbit_details?.inclination : Mission?.['mission-attributes']?.['inclination_angle'],
          eccentricity: scenario_data?.orbit_details?.eccentricity !== '' ? scenario_data?.orbit_details?.eccentricity : 0,
          RAAN: scenario_data?.orbit_details?.RAAN !== '' ? scenario_data?.orbit_details?.RAAN : 0,
          AP: scenario_data?.orbit_details?.AP !== '' ? scenario_data?.orbit_details?.AP : 0,
          TA: scenario_data?.orbit_details?.TA !== '' ? scenario_data?.orbit_details?.TA : 0
        }
      })
    }else{
      setScenarioData({
        ...scenario_data,
        additional_orbit_details:{
          name: '',
          altitude : Mission?.['mission-attributes']?.['Altitude_range']?.['max'],
          inclination: Mission?.['mission-attributes']?.['inclination_angle'],
          eccentricity: 0,
          RAAN: 0,
          AP: 0,
          TA: 0
        }
      })
    }
  }

  const check_validation = () => {
    if(scenario_step === "Scenario_Details" ){
      if(scenarioData?.scenario_details?.scenarioName !== '' ){
        return true
      }else{
        toast.error('Scenario title is required',{toastId:'s'})
        return false
      }
    }else if(scenario_step === "Orbital_Details"){
      const orbitParams = scenarioData?.orbit_details
      const not_validated = Object.keys(scenarioData?.orbit_details).some(key => {
        if(key === 'altitude'){
            if((orbitParams?.[key] === '') || +orbitParams?.[key] < 300 || +orbitParams?.[key] > 750){
                toast.error(orbitParams?.[key] === '' ? capitalizeFirstLetter(key) + " is required." :  capitalizeFirstLetter(key) + " should be between 300 and 750",{toastId: "o"})
                return true;
            }
        }else{
            if(orbitParams?.[key] === ''){
                toast.error(capitalizeFirstLetter(key) + " is required.",{toastId: "o"})
                return true;
            }
        }
      });
      
      if (not_validated) {
        return false
      } else {
        return true
      }
    }else if(scenario_step === 'Physical_Characteristics'){
      const momentOfInertia = scenarioData?.physical_characterisitics?.momentOfInertia
      const dimensions = scenarioData?.physical_characterisitics?.dimensions
      const orientation = scenarioData?.physical_characterisitics?.orientation
      const not_validated_momentOfInertia = Object.keys(momentOfInertia).some(key => {
        if(momentOfInertia[key] === ''){
          toast.error(capitalizeFirstLetter(key) + " is required",{toastId: "o"})
          return true
        }
      })
      const not_validated_dimensions = Object.keys(dimensions).some(key => {
        if(dimensions[key] === ''){
          toast.error(capitalizeFirstLetter(key) + " is required",{toastId: "o"})
          return true
        }
      })
      const not_validated_orientation = Object.keys(orientation).some(key => {
        if(orientation[key] === ''){
          toast.error(capitalizeFirstLetter(key) + " is required",{toastId: "o"})
          return true
        }
      })
      if(not_validated_momentOfInertia || not_validated_dimensions || not_validated_orientation){
        return false
      }else{
        return true
      }
    }else if(scenario_step === 'Ground_Station'){
      if(scenarioData?.ground_stations?.length > 0 && MissionGSList.length > 0){
        return true
      }else{
        toast.error('Atleast one ground station is required.',{toastId:'s'})
        return false
      }
    }else if(scenario_step === 'Schedule'){
      let scenario_start_time = new Date(scenarioResponseData?.orbit?.epoch * 1000).getTime()
      let scenario_end_time = scenario_start_time + (scenarioResponseData?.['scenarioDuration'] * 60000)
      let simulation_time = new Date(newSimulationTime !== '' ? newSimulationTime : scenarioData?.start_time).getTime()
      let duration = scenarioData?.duration
      if(duration !== '' && duration >= 10 &&  duration <= (scenarioResponseData?.['scenarioDuration'] / 2)){
        if(!simulation_time || (simulation_time < scenario_start_time) || ((simulation_time  + (duration * 60000)) > scenario_end_time)){          
          setNewSimulationTime('')
          setScenarioData({
            ...scenarioData, 
            start_time: ''
          })
          setTimeout(() => {
            setScenarioData({
              ...scenarioData, 
              start_time: scenarioResponseData?.['simulationStartTime'] * 1000
            })
          },10)
          toast.error(`Scenario start time & duration should not exceed scenario end time(${new Date(scenario_end_time).toISOString().split('.')[0]})`, { toastId: "error" })
          return false
        } else {
          return true
        }
      } else{
        toast.error(duration === '' ? 'Duration is required.' : duration < 10 ? 'Duration should be greater than or equal to 10 minute.' : `Duration should be less then 120 minute.`,{toastId:'s'})
        return false
      }
    }
  }
  

  const handleModalSectionNext = () => {
    if (scenario_step === "Scenario_Details" && check_validation()) {
      if(window.location.pathname?.includes('update_scenario')){
        update_scenario()
      }else{
        create_scenario()
      }
    } else if (scenario_step === "Orbital_Details" && check_validation()) {
      post_orbit_details()
    } else if (scenario_step === "Physical_Characteristics" && check_validation()) {
      post_phyiscal_characteristics()
    } else if (scenario_step === "Additional_Orbits") {
      if(addAdditionaOrbitDetails){
        setAddAdditionaOrbitDetails(false)
      }
      post_external_orbits()
    } else if (scenario_step === "Ground_Station" && check_validation()) {
      post_scenario_ground_stations()
    } else if (scenario_step === "Schedule" && check_validation()) {
      post_scenario_taskplan()
    }
  };

  const handleModalSectionPrevious = () => {
    if(importedSchedule?.['schedule_imported']){
      setImportedSchedule({
        ...importedSchedule,
        schedule_upload: false,
        show_upload_btn: true,
        show_deploy_btn: false,
      })
    }
    if (scenario_step === "Orbital_Details") {
      navigate_scenario('Scenario_Details', scenario_name, scenario_id)
    } else if (scenario_step === "Physical_Characteristics") {
      calculate_orbital_details(scenarioData)
      navigate_scenario('Orbital_Details', scenario_name, scenario_id)
    } else if (scenario_step === "Additional_Orbits") {
      calculate_phyiscal_characteristics(scenarioData)
      navigate_scenario('Physical_Characteristics', scenario_name, scenario_id)
    } else if (scenario_step === "Ground_Station") {
      if(userData?.['feature-allowed']?.['advancedSimulation'] === 'No'){
        calculate_phyiscal_characteristics(scenarioData)
      }
      navigate_scenario(userData?.['feature-allowed']?.['advancedSimulation'] === 'Yes' ? 'Additional_Orbits' : 'Physical_Characteristics', scenario_name, scenario_id)
    } else if (scenario_step === "Schedule") {
      setImportedSchedule({
        ...importedSchedule,
        schedule_upload: false,
        show_upload_btn: importedSchedule?.['schedule_imported'],
        show_deploy_btn: false,
      })
      navigate_scenario('Ground_Station', scenario_name, scenario_id)
    }
  };

  const create_scenario = () => {
    setLoading(true)
    let body = {
      "scenarioName": scenarioData.scenario_details?.scenarioName,
      "missionId": mission_id,
      "satId": sat_id,
      "trueTwinId": truetwin_id,
      "sampleRate": scenarioData.scenario_details?.sampleRate,
      "isQuickDeployFlag": 'false',
      "draftMode": scenarioData.scenario_details?.draftMode,
      "status": 'Configure',
      'isScenarioAutomated': 'true'
    }
    MissionService.postScenario(body).then((response) => {
      if(response?.data){
        let scenarioName = response?.data?.scenarioName
        let scenarioId = response?.data?.scenarioId
        get_scenario_data(response?.data?.scenarioId)
        calculate_orbital_details(scenarioData)
        setDataSubmited([...dataSubmited, 'Scenario_Details'])
        navigate_scenario("Orbital_Details", scenarioName, scenarioId)
        setScenarioScheduleData([])
        setLoading(false)
      }
    }).catch((err) => {
      setLoading(false)
      if(err.response?.data === "Scenario with same name already exists"){
        toast.error('The name you entered is already in use for another scenario.', { toastId: 'o' });
      }else{
        toast.error("Error in creating scenario.",{toastId: '0'});
      }
    })
  }

  const update_scenario = () => {
    setLoading(true)
    let body = {
      ...scenarioResponseData,
      "draftMode": 'Yes',
      "status": 'Configure',
      scenarioName: scenarioData.scenario_details?.scenarioName,
    }
    let scenarioId = scenarioResponseData?.scenarioId
    let scenarioName = scenarioResponseData?.scenarioName
    MissionService.putScenario(scenarioId, body)?.then((response) => {
      if(response?.data){
        get_scenario_data(scenarioId)
        calculate_orbital_details(scenarioData)
        setDataSubmited([...dataSubmited, 'Scenario_Details'])
        setScenarioScheduleData([])
        navigate_scenario('Orbital_Details', scenarioName, scenarioId)
        setLoading(false)
      }
    })?.catch((err) => {
      setLoading(false)
      if(err.response?.data === "Scenario with same name already exists"){
        toast.error('The name you entered is already in use for another scenario.', { toastId: 'o' });
      }else{
        toast.error("Getting error while updating scenario.",{toastId: '0'});
      }
    })
  }

  const post_orbit_details = () => {
    setLoading(true)
    let body = {
      epoch: truncateNumber(Number((new Date(scenarioData?.orbit_details?.epoch).getTime() / 1000)), 0),
      altitude: scenarioData?.orbit_details?.altitude,
      eccentricity: scenarioData?.orbit_details?.eccentricity,
      inclination: scenarioData?.orbit_details?.inclination,
      RAAN: scenarioData?.orbit_details?.RAAN,
      AP: scenarioData?.orbit_details?.AP,
      TA: scenarioData?.orbit_details?.TA
    }
    let scenarioId = scenarioResponseData?.scenarioId
    let scenarioName = scenarioResponseData?.scenarioName
    MissionService.patchScenario(scenarioId, body).then((response) => {
      if(response?.data){
        get_scenario_data(scenarioId)
        setScenarioScheduled(true)
        calculate_phyiscal_characteristics(scenarioData)
        setDataSubmited([...dataSubmited, 'Orbital_Details'])
        setScenarioScheduleData([])
        navigate_scenario('Physical_Characteristics', scenarioName, scenarioId)
      }
    }).catch((err) => {
      setLoading(false)
      toast.error("Getting error while posting scenario orbit details",{toastId: '0'});
    })
  }

  const post_phyiscal_characteristics = () => {
    setLoading(true)
    let body = {
      ...scenarioResponseData,
      draftMode: 'No',
      status: 'Run Simulation',
      physicalCharacteristics: {
        mass: scenarioData?.physical_characterisitics?.mass,
        momentOfInertia:{
          Ixx: parseFloat(scenarioData?.physical_characterisitics?.momentOfInertia?.Ixx),
          Ixy: parseFloat(scenarioData?.physical_characterisitics?.momentOfInertia?.Ixy),
          Ixz: parseFloat(scenarioData?.physical_characterisitics?.momentOfInertia?.Ixz),
          Iyy: parseFloat(scenarioData?.physical_characterisitics?.momentOfInertia?.Iyy),
          Iyz: parseFloat(scenarioData?.physical_characterisitics?.momentOfInertia?.Iyz),
          Izz: parseFloat(scenarioData?.physical_characterisitics?.momentOfInertia?.Izz)
        },
        dimensions:{
          length: scenarioData?.physical_characterisitics?.dimensions?.length,
          width: scenarioData?.physical_characterisitics?.dimensions?.width,
          height: scenarioData?.physical_characterisitics?.dimensions?.height,
        },
        orientation:{
          roll: scenarioData?.physical_characterisitics?.orientation?.roll,
          pitch: scenarioData?.physical_characterisitics?.orientation?.pitch,
          yaw: scenarioData?.physical_characterisitics?.orientation?.yaw
        }
      }
    }
    let scenarioId = scenarioResponseData?.scenarioId
    let scenarioName = scenarioResponseData?.scenarioName
    MissionService.putScenario(scenarioId, body)?.then((response) => {
      if(response?.data){
        get_scenario_data(scenarioId)
        calculate_orbital_details(scenarioData)
        setDataSubmited([...dataSubmited, 'Physical_Characteristics'])
        setScenarioScheduleData([])
        navigate_scenario(userData?.['feature-allowed']?.['advancedSimulation'] === 'Yes' ? 'Additional_Orbits' : 'Ground_Station', scenarioName, scenarioId)
        setLoading(false)
      }
    })?.catch((err) => {
      setLoading(false)
      toast.error("Getting error while posting scenario physical characteristics",{toastId: '0'});
    })
  }

  const generate_scenario_schedule = () => {
    let scenarioId = scenarioResponseData?.scenarioId ? scenarioResponseData?.scenarioId : scenario_id
    MissionService.generateSchedule(scenarioId)?.then((response) => {
      if(response?.data){
        get_scenario_data(scenarioId)
        setScenarioScheduleData(response?.data)
      }else{
        toast.error("No tasks scheduled for this scenario.",{toastId: '0'});
      }
      setScenarioScheduled(true)
      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station', 'Schedule'])
    })?.catch((err) => {
      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
      toast.error("Getting error while generating scenario schedule.",{toastId: '0'});
      setLoading(false)
    })
  }

  const post_external_orbits = () => {
    setLoading(true)
    let scenarioId = scenarioResponseData?.scenarioId
    let scenarioName = scenarioResponseData?.scenarioName
    MissionService.addExperimentalObjects(scenarioId, scenarioData?.['additional_orbits'])?.then((res) => {
      if(res?.data){
        get_scenario_data(scenarioId)
        setScenarioScheduled(true)
        setDataSubmited([...dataSubmited, 'Additional_Orbits'])
        setScenarioScheduleData([])
        navigate_scenario('Ground_Station', scenarioName, scenarioId)
      }
    })?.catch((err) => {
      setLoading(false)
      toast.error("Getting error while posting additional orbits.",{toastId: '0'});
    })
  }

  
  const post_scenario_ground_stations = () => {
    setLoading(true)
    let scenarioId = scenarioResponseData?.scenarioId
    let scenarioName = scenarioResponseData?.scenarioName
    let body = {
      ...scenarioResponseData,
      'groundStations': scenarioData?.ground_stations
    }
    MissionService?.putScenario(scenarioId, body)?.then((response) => {
      if(response?.data){
        get_scenario_data(scenarioId)
        setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
        setScenarioScheduleData([])
        navigate_scenario('Schedule', scenarioName, scenarioId)
      }
    })?.catch((err) => {
      setLoading(false)
      toast.error("Getting error while posting ground stations.",{toastId: '0'});
    })
  }


  const post_scenario_taskplan = () => {
    setLoading(true)
    let scenarioId = scenarioResponseData?.scenarioId
    let body = {
      ...scenarioResponseData,
      "isScenarioAutomated" : scenarioData?.task_plan === '' ? 'true' : 'false',
      "taskPlanId": scenarioData?.task_plan,
      'simulationDuration': scenarioData?.duration,
      'simulationStartTime': truncateNumber(Number((new Date(scenarioData?.start_time).getTime() / 1000)), 0)
    }
    MissionService?.putScenario(scenarioId, body)?.then((response) => {
      if(response?.data && !importedSchedule?.['schedule_imported']){
        generate_scenario_schedule()
      }else{
        setLoading(false)
      }
    })?.catch((err) => {
      setLoading(false)
      toast.error("Getting error while posting taskplan details.",{toastId: '0'});
    })
  }


  const undeploy_truetwin = () => {
    CommandCenterService.unDeployTrueTwin(truetwin_id)
      ?.then((res) => {
        if (res) {
            setDeployLoader({"error": `An error occurred while deploying ${TrueTwin?.['Category']} ${truetwin_name}.`});
        }
      })
      .catch((err) => {
        setDeployLoader({"error": `An error occurred while deploying ${TrueTwin?.['Category']} ${truetwin_name}.`});
      });
  };
  const dis_connect_ground_station = () => {
    CommandCenterService.disconnectGroundStation(
        TrueTwin?.["groundStationId"],
        TrueTwin?.["trueTwinId"]
    )
      ?.then((res) => {
        if(res.data){
          undeploy_truetwin();
        }
      })
      .catch((err) => {
          undeploy_truetwin();
      });
  };
  const run_simulation = (body) => {
      MissionService.runSimulation(body)?.then(res => {
        if(res.data){
          setDeployLoader({'deployed': `${TrueTwin?.['Category']} ${truetwin_name} deployed successfully.`})
        }
      }).catch(err => {
        console.log('something wen wrong');
        dis_connect_ground_station()
      })
  }


  const deploy_truetwin_by_launch_type = () => {
    setDeployLoader({'deploying': `Deploying ${TrueTwin?.['Category']} ${truetwin_name}...`});
    let data = {
      ...TrueTwin,
      deployInfo: {
        'type': scenario_name,
        'scenarioId': scenario_id,
        'scenarioName': scenario_name,
        'isManual': (scenarioResponseData?.['taskPlanId'] && scenarioResponseData?.['taskPlanId'] !== '') ? false : true
      }
    }
    CommandCenterService.deployTrueTwin(truetwin_id, data)?.then((res) => {
       if (res.data) {
          if(TrueTwin?.['Category'] === "TrueTwin"){
            CommandCenterService.connectGroundStation(res.data?.["groundStationId"],truetwin_id,scenario_id)?.then((res) => {
              if(res?.data){
                if(TrueTwin?.['isRemoteAppConfigured'] === 'No'){
                  let body = {
                    "simulationName": "Simultion" + scenario_name,
                    "trueTwinId": truetwin_id,
                    "scenarioId": scenario_id,
                    "missionId": mission_id,
                    "satId": sat_id,
                  }
                  run_simulation(body)
                }else{
                  setDeployLoader({
                      'deployed': `TrueTwin ${truetwin_name} deployed successfully.`,
                      'connecting': `Connecting to remote application...`
                  })
                }
              }
            })
            .catch((err) => {
              dis_connect_ground_station()
            });
          }else{
            setDeployLoader({
              'deployed': `FlatSat ${truetwin_name} deployed successfully.`,
              'connect': `Run FlatSat proxy on your setup, port: ${TrueTwin?.['satellitePort']}`
            })
          }
       }
      })?.catch((err) => {
        setDeployLoader({"error": `An error occurred while deploying TrueTwin ${truetwin_name}.`});
      });
  };



  const upload_generated_schedule = () => {
    setLoading(true)
    CommandCenterService.uploadScenarioSchedule(scenario_id, truetwin_id)?.then(res => {
      if (res.data) {
        toast.success('Schedule uploaded successfully.');
        navigate_to_tt_dashboard()
      }
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      toast.error('Something went wrong while uploading schedule data.');
    })
  }
  
  useEffect(()=>{
    if(dataSubmited?.includes('Schedule')){
      setIsViewButtonEnabled(true)
    }else{
      setIsViewButtonEnabled(false)
    }
  },[scenario_step , dataSubmited, TrueTwin])

  useEffect(()=>{
    let scroll_container:any = scrollRef?.current
    let form_section:any = formSectionRef?.current
    if(scroll_container && form_section){
      scroll_container.scrollTop = form_section.offsetTop -50
    }
  },[scenario_step, openTaskPlanMenu])

  const upload_imported_schedule = () =>{
    MissionService.postScenarioSchedule(scenario_id,importedSchedule?.['file_data']).then((res)=>{
      if(res){
        get_scenario_data(scenario_id)
        setScenarioScheduled(true)
        setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station', 'Schedule'])
        setImportedSchedule({
          ...importedSchedule,
          schedule_upload: true,
          show_deploy_btn: true,
          show_upload_btn: false,
        })
      }
    }).catch((err)=>{
      setUploaddFileStatus("Error in Upload File")
    })
  }
  function formDataToJson(formData) {
    const json = {};
    formData.forEach((value, key) => {
      if (!json[key]) {
        json[key] = value;
      } else {
        if (!Array.isArray(json[key])) {
          json[key] = [json[key]];
        }
        json[key].push(value);
      }
    });
    return JSON.stringify(json);
  }
  

  return (
    <Fragment>
      {loading && <Loader />}
      <div className="scenario_mainModal" id="planeModal">
        <ModalHeader setisModalVisible={()=>{setisModalVisible(!isModalVisible)}} close={()=>{navigate_to_truetwin_details()}} />
        {isModalVisible && (
          <Fragment>
            <div className="modal_body_section" ref={scrollRef} >

              <div ref={scenario_step === "Scenario_Details" ? formSectionRef : null} className={scenario_step === "Scenario_Details" ? "highLightedBackground" : ""}>
                <ScenarioDetails 
                  scenario_details={scenarioData.scenario_details}
                  set_scenario_details={(data: object) => setScenarioData({
                    ...scenarioData, 
                    scenario_details: {
                      ...scenarioData.scenario_details, ...data
                    }
                  })}
                  navigate_scenario={(data)=>{navigate_scenario(data, scenario_name, scenario_id)}}
                  data_submited={dataSubmited}
                  true_twin={{}}
                  scenario_step={scenario_step}
                />
              </div>

              <div ref={scenario_step === "Orbital_Details" ? formSectionRef : null} className={scenario_step === "Orbital_Details" ? "highLightedBackground" : ""}>
                <OrbitalDetails
                  orbit_details={scenarioData.orbit_details}
                  set_scenario_orbit_details={(data: object) => {
                    setScenarioData({
                      ...scenarioData, 
                      start_time: data?.['epoch'] ? data?.['epoch'] : scenarioData?.start_time,
                      orbit_details: {
                        ...scenarioData?.orbit_details,
                        ...data
                      }
                    })
                  }}

                  navigate_scenario={(data)=>{
                    navigate_scenario(data, scenario_name, scenario_id)
                    calculate_orbital_details(scenarioData)
                  }}
                  data_submited={dataSubmited}
                  true_twin={{}}
                  scenario_step={scenario_step}
                />
              </div>

              <div ref={scenario_step === "Physical_Characteristics" ? formSectionRef : null} className={scenario_step === "Physical_Characteristics" ? "highLightedBackground" : ""}>
                <PhysicalCharacteristics
                  physical_characterisitics={scenarioData.physical_characterisitics}
                  set_scenario_physical_characterisitics={(data: object) => setScenarioData({
                    ...scenarioData, 
                    physical_characterisitics: {
                      ...scenarioData.physical_characterisitics, ...data
                    }
                  })}
                  navigate_scenario={(data)=>{
                    navigate_scenario(data, scenario_name, scenario_id)
                    calculate_phyiscal_characteristics(scenarioData)
                  }}
                  data_submited={dataSubmited}
                  true_twin={{}}
                  scenario_step={scenario_step}
                />
              </div>
              
              {userData?.['feature-allowed']?.['advancedSimulation'] === 'Yes' &&
                <div ref={scenario_step === "Additional_Orbits" ? formSectionRef : null} className={scenario_step === "Additional_Orbits" ? "highLightedBackground" : ""}>
                <AdditionaOrbitDetails
                  additional_orbit_details={scenarioData.additional_orbit_details}
                  additional_orbits={scenarioData.additional_orbits}
                  set_scenario_additional_orbits={(data: any) => setScenarioData({
                    ...scenarioData, 
                    additional_orbits: data
                  })}
                  set_scenario_additional_orbits_details={(data: any) => setScenarioData({
                    ...scenarioData, 
                    additional_orbit_details: {
                      ...scenarioData.additional_orbit_details, ...data
                    }
                  })}
                  navigate_scenario={(data)=>{
                    navigate_scenario(data, scenario_name, scenario_id)
                    calculate_orbital_details(scenarioData)
                  }}
                  data_submited={dataSubmited}
                  true_twin={{}}
                  scenario_step={scenario_step}
                  isOpen={addAdditionaOrbitDetails}
                  open_modal={(data)=>{setAddAdditionaOrbitDetails(data)}}
                  close_modal={()=>{setAddAdditionaOrbitDetails(false)}}
                  reset_additional_orbits={()=>{calculate_orbital_details(scenarioData)}}
                />
                </div>
              }

              <div ref={scenario_step === "Ground_Station" ? formSectionRef : null} className={scenario_step === "Ground_Station" ? "highLightedBackground" : ""}>
                <ScenarioGroundStation
                  all_ground_stations={MissionGSList}
                  ground_stations={scenarioData.ground_stations}
                  set_scenario_ground_stations={(data: any) => setScenarioData({
                    ...scenarioData,
                    ground_stations: data
                  })}
                  navigate_scenario={(data)=>{navigate_scenario(data, scenario_name, scenario_id)}}
                  data_submited={dataSubmited}
                  true_twin={{}}
                  scenario_step={scenario_step}
                />
              </div>

              <div ref={scenario_step === "Schedule" ? formSectionRef : null} className={scenario_step === "Schedule" ? "highLightedBackground" : ""}>
                <ScenarioSchedule
                  setOpenTaskPlanMenu={(data)=>{setOpenTaskPlanMenu(data)}}
                  set_new_simulation_time={(date)=>{
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
                    setNewSimulationTime(date)
                  }}
                  task_plan={scenarioData.task_plan}
                  set_scenario_task_plan={(data: string) => {
                    setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
                    setScenarioData({
                    ...scenarioData, 
                    task_plan: data
                    })
                  }}
                  duration={scenarioData.duration}
                  set_simulation_duration={(data: any) => {
                    if(!importedSchedule.show_deploy_btn){
                      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
                    }
                    setScenarioData({
                    ...scenarioData, 
                    duration: data
                    })
                  }}
                  start_time={scenarioData.start_time}
                  set_simulation_start_time={(data: any) => {
                    if(!importedSchedule.show_deploy_btn){
                      setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
                    }
                    setScenarioData({
                      ...scenarioData, 
                      start_time: data
                    })
                  }}
                  epoch={scenarioResponseData?.orbit?.epoch * 1000}
                  scenario_duration={scenarioResponseData?.['scenarioDuration']}
                  navigate_scenario={(data)=>{navigate_scenario(data, scenario_name, scenario_id)}}
                  data_submited={dataSubmited}
                  scenario_step={scenario_step}
                  set_imported_schedule={(schedule) => {
                    setImportedSchedule(schedule)
                  }}
                  imported_schedule_data={importedSchedule}
                  uploadFileStatus = {uploadFileStatus}
                  setUploaddFileStatus = {setUploaddFileStatus}
                  set_scenario_schedule_data={(data => {
                    setScenarioScheduleData(data)
                  })}
                />
              </div>

            </div>

            <div className="modalFooter">
              <button
                disabled={loading}
                className="back_close_btn"
                onClick={() => {
                  if(scenario_step === "Scenario_Details") {
                    navigate_to_truetwin_details()
                  }else{
                    handleModalSectionPrevious()
                  }
                }}
              >
                {scenario_step === "Scenario_Details" ? 'Close' : 'Back'}
              </button>
              <div className="d-flex gap-2">
                {((!importedSchedule?.['show_upload_btn'] && !importedSchedule?.['show_deploy_btn']) || scenario_step !== "Schedule") &&
                  <Fragment>
                    {(scenario_step === 'Schedule' && dataSubmited?.includes('Schedule') && TrueTwin?.['isDeployed'] === 'No') && 
                      <button disabled={loading} className="save_close_btn " onClick={navigate_to_truetwin_details}>
                        Save & Close
                      </button>
                    }
                    <button disabled={loading} className="save_btn" onClick={() => {
                    if(scenario_step === 'Schedule' && dataSubmited?.includes('Schedule') && scenarioScheduleData?.length > 0) {
                      if(TrueTwin?.['isDeployed'] === 'No'){
                        deploy_truetwin_by_launch_type()
                      }else if(TrueTwin?.['isDeployed'] === 'Yes' && TrueTwin?.['Category'] === 'FlatSat'){
                        upload_generated_schedule()
                      }else{
                        navigate_to_truetwin_details()
                      }
                    }else{
                      handleModalSectionNext()
                    }
                  }}>
                    {(scenario_step === 'Schedule' && dataSubmited?.includes('Schedule') && scenarioScheduleData?.length > 0) ? TrueTwin?.['isDeployed'] === 'No' ? 'Deploy' : TrueTwin?.['Category'] === 'FlatSat' ? 'Upload Schedule' : 'Close' : 'Next'}
                    </button>
                  </Fragment> 
                }
                {(importedSchedule?.['show_upload_btn'] || importedSchedule?.['show_deploy_btn']) && scenario_step === 'Schedule' &&
                  <Fragment> 
                    <button disabled={loading} className="save_close_btn " onClick={navigate_to_truetwin_details}>
                      Save & Close
                    </button>     
                    {TrueTwin?.['isDeployed'] === 'No' && 
                        <button className="save_btn"
                          onClick={()=>{
                            if(importedSchedule?.['show_deploy_btn']){
                              deploy_truetwin_by_launch_type()
                            }else{
                              upload_imported_schedule()
                            }
                          }}
                        >
                          {importedSchedule?.['show_deploy_btn'] ? 'Deploy' : 'Upload'}
                        </button>
                    }
                  </Fragment>
                }
              </div>
          </div>
          </Fragment>

        )}
      </div>
      <VisualizationWrapper
        scenario_duration={scenarioResponseData?.['scenarioDuration']}
        scenario_start_time={scenarioData?.orbit_details?.epoch}
        simulation_start_time={scenarioData?.start_time}
        set_simulation_time={(data: any) => {
          setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
          setScenarioData({
            ...scenarioData, 
            start_time: data?.['start_time'],
            duration: data?.['duration']
          })
        }}
        set_simulation_duration={(data: any) => {
          setDataSubmited(['Scenario_Details', 'Orbital_Details', 'Physical_Characteristics', 'Additional_Orbits', 'Ground_Station'])
          setScenarioData({
            ...scenarioData, 
            duration: data
          })
        }}
        scenario_step={scenario_step} 
        open_schedule_modal={()=>{setOpenScheduleModal(true)}}
        scenario_scheduled={scenarioScheduled}
        schedule_data={scenarioScheduleData}
        set_scenario_scheduled={()=>{setScenarioScheduled(false)}}
        duration={scenarioData.duration}
        ground_stations={MissionGSList}
        selected_ground_station={scenarioData?.ground_stations}
        scenario_response_data={scenarioResponseData}
        set_loading={(data)=>{setLoading(data)}}
        show_manual_gs= {showManualGroundStations}
        set_show_manual_gs= {(data)=>{setShowManualGroundStations(data)}}
        isViewButtonEnabled = {isViewButtonEnabled}
      />
      <ScenarioScheduleModal 
        simulation_start_time={scenarioData?.start_time}
        simulation_duration={scenarioData.duration}
        schedule_data={scenarioScheduleData} 
        openModal={openScheduleModal} 
        closeModal={()=>{setOpenScheduleModal(false)}}
      />
      {deployLoader && (
        <LoaderAnimation
          objectName={(truetwin_name && truetwin_name?.length > 10) ? truetwin_name?.substring(0, 10) + '...' : truetwin_name}
          close={() => {
            get_truetwin_details()
            setDeployLoader(false);
          }}
          state= {deployLoader}
          truetwin_id={TrueTwin?.['trueTwinId']}
          category={TrueTwin?.['Category']}
          mission_name={mission_name}
          mission_id={mission_id}
          sat_name={sat_name}
          sat_id={sat_id}
          dis_connect_ground_station={dis_connect_ground_station}
        />
      )}
    </Fragment>
  );
};

export default NewScenarioFlow;
