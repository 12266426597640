// @ts-nocheck
import Panel from "./ResizableSidePanel"
import Orbit from '../../../../OrbitVisualization/Orbit';
function CesiumView({ simulationStatus,selectedTabBtn,isQuickDeploy,isScenarioAutomated,taskListQueue, isShowPanel, setLoading}) {

    
    return (
        <div className='w-100 h-100 d-flex' >
            {isShowPanel && <div style={{width:"15%"}}>
                <Panel taskListQueue={taskListQueue} />
            </div>}
             <div className='w-100 flex-1'>
                <Orbit simulationStatus={simulationStatus} 
                selectedTabBtn={selectedTabBtn} 
                isQuickDeploy = {isQuickDeploy} 
                isScenarioAutomated={isScenarioAutomated} 
                setLoading={setLoading}
            />
            </div>
        </div>
    );
}

export default CesiumView;
