import React, { Fragment } from 'react'

interface PhysicalCharacteristicsProps {
    physical_characterisitics: any;
    set_scenario_physical_characterisitics: (details: Object) => void;
    navigate_scenario: (details: string) => void;
    data_submited: string[];
    true_twin: Object;
    scenario_step?: string;
}

const PhysicalCharacteristics: React.FC<PhysicalCharacteristicsProps> = ({ physical_characterisitics, set_scenario_physical_characterisitics, navigate_scenario, true_twin, scenario_step, data_submited }) => {
  return (
    <Fragment>
      <div className="modalBoxShadowDiv">
            <button 
                onClick={() =>{
                    if(data_submited?.includes('Physical_Characteristics')){
                        navigate_scenario('Physical_Characteristics')
                    }
                    
                }}
                className={`modalCheckboxDiv bg-transparent ${!data_submited?.includes('Physical_Characteristics') ? "disabledBtn" : ''}`}
            >
            <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
            <div className={"modalDropDownIconBtn"}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d={scenario_step === 'Physical_Characteristics' ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                    stroke="#F0F0F0"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                </svg>
            </div>
            <div className={!data_submited?.includes('Physical_Characteristics') ? "modalCheckboxText modalWhiteSubHeader disabledBtn" : "modalCheckboxText modalWhiteSubHeader"}>
                Physical Characteristics
            </div>
            {data_submited?.includes('Physical_Characteristics') && <img className="greenTickIcon" src={require("../ImageSvgs/GreenTickIcon.svg").default} />}
            </button>
        </div>
        {scenario_step === 'Physical_Characteristics' && (
            <Fragment>
            <div className="modalAnalysisDiv modalAnalysisDivLong">
                <div className="modalAnalysisText">Mass</div>
                <input
                value={physical_characterisitics.mass}
                onChange={(e) => {
                    if(!isNaN(+e.target.value) && e.target.value?.length <= 8){
                        set_scenario_physical_characterisitics({
                            mass: e.target.value !== '' ? Math.abs(+e.target.value) : ''
                        })
                    }
                }}
                className="modalAnalysisInput"
                type="number"
                placeholder="kg"
                step='any'
                required
                />
            </div>
            <div className="modalAnalysisDiv">
                <div className="modalAnalysisText">Moment of Inertia</div>
            </div>
            <div className="modalAnalysisDiv modalFlexDiv">
                <div className="modalThirdsDiv">
                Ixx
                <input
                    className="tableInputElement"
                    
                    value={physical_characterisitics?.momentOfInertia?.Ixx}
                    onChange={(e:any) => {
                        if(!isNaN(e.target.value) && e.target.value?.length <= 18){
                            set_scenario_physical_characterisitics({
                                momentOfInertia: {
                                    ...physical_characterisitics?.momentOfInertia,
                                    Ixx: e.target.value !== '' ? e.target.value : ''
                                }
                            })
                        }
                    }}
                    placeholder="kg.m&sup2;"
                    min={0}
                    type='number'
                    step='any'
                    required
                />
                </div>
                <div className="modalThirdsDiv">
                Ixy
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.momentOfInertia?.Ixy}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 18){
                            set_scenario_physical_characterisitics({
                                momentOfInertia: {
                                    ...physical_characterisitics?.momentOfInertia,
                                    Ixy: e.target.value !== '' ? e.target.value : ''
                                }
                            })
                        }
                    }}
                    placeholder="kg.m&sup2;"
                    min={0}
                    type='number'
                    step='any'
                    required
                />
                </div>
                <div className="modalThirdsDiv">
                Ixz
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.momentOfInertia?.Ixz}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 18){
                            set_scenario_physical_characterisitics({
                                momentOfInertia: {
                                    ...physical_characterisitics?.momentOfInertia,
                                    Ixz: e.target.value !== '' ? e.target.value : ''
                                }
                            })
                        }
                    }}
                    placeholder="kg.m&sup2;"
                    min={0}
                    type='number'
                    step='any'
                    required
                />
                </div>
            </div>
            <div className="modalAnalysisDiv modalFlexDiv">
                <div className="modalThirdsDiv">
                Iyy
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.momentOfInertia?.Iyy}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 18){
                            set_scenario_physical_characterisitics({
                                momentOfInertia: {
                                    ...physical_characterisitics?.momentOfInertia,
                                    Iyy: e.target.value !== '' ? e.target.value : ''
                                }
                            })
                        }
                    }}
                    placeholder="kg.m&sup2;"
                    min={0}
                    type='number'
                    step='any'
                    required
                />
                </div>
                <div className="modalThirdsDiv">
                Iyz
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.momentOfInertia?.Iyz}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 18){
                            set_scenario_physical_characterisitics({
                                momentOfInertia: {
                                    ...physical_characterisitics?.momentOfInertia,
                                    Iyz: e.target.value !== '' ? e.target.value : ''
                                }
                            })
                        }
                    }}
                    placeholder="kg.m&sup2;"
                    min={0}
                    type='number'
                    step='any'
                    required
                />
                </div>
                <div className="modalThirdsDiv">
                Izz
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.momentOfInertia?.Izz}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 18){
                            set_scenario_physical_characterisitics({
                                momentOfInertia: {
                                    ...physical_characterisitics?.momentOfInertia,
                                    Izz: e.target.value !== '' ? e.target.value : ''
                                }
                            })
                        }
                    }}
                    placeholder="kg.m&sup2;"
                    min={0}
                    type='number'
                    step='any'
                    required
                />
                </div>
            </div>
            <br />
            <div className="modalAnalysisDiv">
                <div className="modalAnalysisText">Dimensions</div>
            </div>
            <div className="modalAnalysisDiv modalFlexDiv">
                <div className="modalThirdsDiv">
                L
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.dimensions?.length}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 8){
                            set_scenario_physical_characterisitics({
                                dimensions: {
                                    ...physical_characterisitics?.dimensions,
                                    length: e.target.value !== '' ? Math.abs(+e.target.value) : ''
                                }
                            })
                        }
                    }}
                    placeholder="cm"
                    type='number'
                    step='any'
                    required
                />
                </div>
                <div className="modalThirdsDiv">
                W
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.dimensions?.width}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 8){
                            set_scenario_physical_characterisitics({
                                dimensions: {
                                    ...physical_characterisitics?.dimensions,
                                    width: e.target.value !== '' ? Math.abs(+e.target.value) : ''
                                }
                            })
                        }
                    }}
                    placeholder="cm"
                    type='number'
                    step='any'
                    required
                />
                </div>
                <div className="modalThirdsDiv">
                H
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.dimensions?.height}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 8){
                            set_scenario_physical_characterisitics({
                                dimensions: {
                                    ...physical_characterisitics?.dimensions,
                                    height: e.target.value !== '' ? Math.abs(+e.target.value) : ''
                                }
                            })
                        }
                    }}
                    placeholder="cm"
                    type='number'
                    step='any'
                    required
                />
                </div>
            </div>
            <br />
            <div className="modalAnalysisDiv">
                <div className="modalAnalysisText">Current Orientation</div>
            </div>
            <div className="modalAnalysisDiv modalFlexDiv">
                <div className="modalThirdsDiv">
                Yaw
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.orientation?.yaw}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 8){
                            set_scenario_physical_characterisitics({
                                orientation: {
                                    ...physical_characterisitics?.orientation,
                                    yaw: e.target.value !== '' ? Math.abs(+e.target.value) : ''
                                }
                            })
                        }
                    }}
                    placeholder={"0\u2070"}
                    type='number'
                    step='any'
                    required
                />
                </div>
                <div className="modalThirdsDiv">
                Pitch
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.orientation?.pitch}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 8){
                            set_scenario_physical_characterisitics({
                                orientation: {
                                    ...physical_characterisitics?.orientation,
                                    pitch: e.target.value !== '' ? Math.abs(+e.target.value) : ''
                                }
                            })
                        }
                    }}
                    placeholder={"0\u2070"}
                    type='number'
                    step='any'
                    required
                />
                </div>
                <div className="modalThirdsDiv">
                Roll
                <input
                    className="tableInputElement"
                    value={physical_characterisitics?.orientation?.roll}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value) && e.target.value?.length <= 8){
                            set_scenario_physical_characterisitics({
                                orientation: {
                                    ...physical_characterisitics?.orientation,
                                    roll: e.target.value !== '' ? Math.abs(+e.target.value) : ''
                                }
                            })
                        }
                    }}
                    placeholder={"0\u2070"}
                    type='number'
                    step='any'
                    required
                />
                </div>
            </div>
            </Fragment>
        )}
    </Fragment>
  )
}

export default PhysicalCharacteristics
