import React, { useEffect, useState } from 'react'
import Loader from '../../../../Layouts/Loader/Loader';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ButtonGroup,
    Dropdown,
    Nav,
    Tab,
} from "react-bootstrap";
import { navigateTo } from '../../../CommonComponents/CommonFunctions';
import GroundStationForm from '../GroundStations/GroundStationForm';
import GroundStationService from '../../../../service/GroundStationService';
import CustomToolTip from '../../../CommonComponents/CustomToolTip';

const GroundStationsDetails = () => {
    const params = useParams();
    const { mission_name, mission_id, station_name, station_id } = params
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(true);
    const [openGSConfigModal, setOpenGSConfigModal] = React.useState<any>(false);
    const [station, setStation] = React.useState({});

    const get_station_details = () => {
        GroundStationService.getGroundStationById(station_id)?.then((res) => {
            if (res.data) {
                let station_details = res?.data
                setStation(station_details)
                setLoading(false)
            }
        }).catch((err) => {
            console.error(err);
            setLoading(false)
        })
    }

    const navigate_to_mission_details = () => {
        navigateTo(`${mission_name}/_details/${mission_id}/Ground_Stations`, navigate)
    }

    useEffect(() => {
        if (station_id && station_id !== undefined) {
            get_station_details()
        }
    }, [station_id])

    return (
        <div className='h-100 w-100'>
            {loading && <Loader />}
            <div className='p-7'>
                <div className="d-flex gap-3">
                    <div onClick={navigate_to_mission_details}
                        className="back-icon-button"
                    >
                        <img src={require('../../../../assets/images/svgs/gs/ground_station.svg').default} width={25} height={25} alt='station' />
                    </div>
                    <div className="sat-info-box-compact w-500px">
                        <div className='border-curve-gradient py-3 ps-5 pe-4'>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className='d-flex flex-column w-90 gap-1'>
                                    <span className="fs-25 color-atmos">
                                        {station_name && station_name?.length > 30 ? `${station_name?.slice(0, 30)}...` : station_name}
                                    </span>
                                    <div className='w-90 d-flex align-items-center justify-content-between'>
                                        <div className="d-flex flex-column">
                                            <span className='fs-14'>{station?.['groundStationTime'] && station?.['groundStationTime']?.slice(0, 10)}</span>
                                            <span className='fs-12 opacity-50'>Date Created</span>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span className='fs-14'>{station?.['status'] === 'Configured' ? 'Configuration Completed' : 'Configuration Pending'}</span>
                                            <span className='fs-12 opacity-50'>Status</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sat-progress d-flex flex-column align-items-center">
                                </div>
                                <ButtonGroup size="sm" className="flex-nowrap">
                                    <div className="btn-list more-button">
                                        <Dropdown drop="end">
                                            <Dropdown.Toggle size="sm" variant="transparent">
                                                <i className="fe fe-more-vertical fs-20"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    eventKey="0"
                                                    onClick={(e) => {
                                                        setOpenGSConfigModal(true)
                                                    }}
                                                >
                                                    Edit
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-tabs pt-5">
                    <div className='d-flex flex-column justify-content-start gap-3 w-100'>
                        <div className='d-flex flex-column justify-content-start gap-2'>
                            <span className="fs-20">Basic Details </span>
                            <div className='d-flex flex-column gap-3 w-100'>
                                <div className='d-flex w-100 gap-3'>
                                    <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-15">
                                        <CustomToolTip title={`${station?.['properties']?.['location']}, ${station?.['properties']?.['country']}`}>
                                            <strong>
                                                {station?.['properties']?.['location']?.length > 9 ? `${station?.['properties']?.['location'].slice(0, 9)}...` : station?.['properties']?.['location']}
                                                {', '}
                                                {station?.['properties']?.['country']?.length > 9 ? `${station?.['properties']?.['country'].slice(0, 9)}...` : station?.['properties']?.['country']}
                                            </strong>
                                        </CustomToolTip>
                                        <span className='color_history fs-13'>Location</span>
                                    </div>
                                    <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-15">
                                        <strong>{station?.['properties']?.['antenaSize'] ? station?.['properties']?.['antenaSize'] : '--'}</strong>
                                        <span className='color_history fs-13'>Antenna Size</span>
                                    </div>
                                    <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-15">
                                        <CustomToolTip title={station?.['properties']?.['polarization']} placement='top'>
                                            <strong>{station?.['properties']?.['polarization'] ? station?.['properties']?.['polarization']?.length > 13 ? `${station?.['properties']?.['polarization'].slice(0, 13)}...` : station?.['properties']?.['polarization'] : '--'}</strong>
                                        </CustomToolTip>
                                        <span className='color_history fs-13'>Polarization</span>
                                    </div>
                                </div>
                                <div className='d-flex w-100 gap-3'>
                                    <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-15">
                                        <strong>
                                            {station?.['properties']?.['latitude']}
                                            <sup>o</sup>
                                        </strong>
                                        <span className='color_history fs-13'>Latitude</span>
                                    </div>
                                    <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-15">
                                        <strong>
                                            {station?.['properties']?.['longitude']}
                                            <sup>o</sup>
                                        </strong>
                                        <span className='color_history fs-13'>Longitude</span>
                                    </div>
                                    <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-15">
                                        <strong>{station?.['properties']?.['altitude']}</strong>
                                        <span className='color_history fs-13'>Altitude</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='d-flex w-100'>
                            {station?.['properties']?.['freqbands']?.includes('S-band') &&
                                <div className='d-flex flex-column justify-content-start gap-2 w-35'>
                                    <span className="fs-20">S-Band </span>
                                    <div className='d-flex flex-column gap-3 w-100'>
                                        <div className='d-flex w-100 gap-3'>
                                            <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-45">
                                                <strong>{station?.['properties']?.['S_GT'] ? station?.['properties']?.['S_GT'] : '--'}</strong>
                                                <span className='color_history fs-13'>S_GT</span>
                                            </div>
                                            <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-45">
                                                <strong>{station?.['properties']?.['S_EIRP'] ? station?.['properties']?.['S_EIRP'] : '--'}</strong>
                                                <span className='color_history fs-13'>S_EIRP</span>
                                            </div>
                                        </div>
                                        <div className='d-flex w-100 gap-3'>
                                            <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-45">
                                                <strong>
                                                    {station?.['properties']?.['rxFreq']?.['Sfreq']?.['min'] ? `${station?.['properties']?.['rxFreq']?.['Sfreq']?.['min']} - ${station?.['properties']?.['rxFreq']?.['Sfreq']?.['max']}` : '--'}
                                                </strong>
                                                <span className='color_history fs-13'>Rx Frequency Range</span>
                                            </div>
                                            <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-45">
                                                <strong>
                                                    {station?.['properties']?.['txFreq']?.['Sfreq']?.['min'] ? `${station?.['properties']?.['txFreq']?.['Sfreq']?.['min']} - ${station?.['properties']?.['txFreq']?.['Sfreq']?.['max']}` : '--'}
                                                </strong>
                                                <span className='color_history fs-13'>Tx Frequency Range</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(station?.['properties']?.['freqbands']?.includes('S-band') && station?.['properties']?.['freqbands']?.includes('X-band')) &&
                                <div
                                    style={{
                                        width: '30px',
                                        borderLeft: '1px solid rgba(255, 255, 255, 0.2)',
                                    }}
                                />
                            }
                            {station?.['properties']?.['freqbands']?.includes('X-band') &&
                                <div className='d-flex flex-column justify-content-start gap-2 w-20'>
                                    <span className="fs-20">X-Band </span>
                                    <div className='d-flex flex-column w-100 gap-3'>
                                        <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-90">
                                            <strong>{station?.['properties']?.['X_GT']}</strong>
                                            <span className='color_history fs-13'>X_GT</span>
                                        </div>
                                        <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-90">
                                            <strong>
                                                {station?.['properties']?.['rxFreq']?.['Xfreq']?.['min']} - {station?.['properties']?.['rxFreq']?.['Xfreq']?.['max']}
                                            </strong>
                                            <span className='color_history fs-13'>Rx Frequency Range</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(station?.['properties']?.['freqbands']?.includes('X-band') && station?.['properties']?.['freqbands']?.includes('UHF')) &&
                                <div
                                    style={{
                                        width: '30px',
                                        borderLeft: '1px solid rgba(255, 255, 255, 0.2)',
                                    }}
                                />
                            }
                            {station?.['properties']?.['freqbands']?.includes('UHF') &&
                                <div className='d-flex flex-column justify-content-start gap-2 w-35'>
                                    <span className="fs-20">UHF </span>
                                    <div className='d-flex flex-column gap-3 w-100'>
                                        <div className='d-flex w-100 gap-3'>
                                            <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-45">
                                                <strong>{station?.['properties']?.['U_GT']}</strong>
                                                <span className='color_history fs-13'>U_GT</span>
                                            </div>
                                            <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-45">
                                                <strong>{station?.['properties']?.['U_EIRP']}</strong>
                                                <span className='color_history fs-13'>U_EIRP</span>
                                            </div>
                                        </div>
                                        <div className='d-flex w-100 gap-3'>
                                            <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-45">
                                                <strong>
                                                    {station?.['properties']?.['rxFreq']?.['UHFfreq']?.['min']} - {station?.['properties']?.['rxFreq']?.['UHFfreq']?.['max']}
                                                </strong>
                                                <span className='color_history fs-13'>Rx Frequency Range</span>
                                            </div>
                                            <div className="h-90px px-4 d-flex flex-column justify-content-center border border-radius-8 w-45">
                                                <strong>
                                                    {station?.['properties']?.['txFreq']?.['UHFfreq']?.['min']} - {station?.['properties']?.['txFreq']?.['UHFfreq']?.['max']}
                                                </strong>
                                                <span className='color_history fs-13'>Tx Frequency Range</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>

            {openGSConfigModal &&
                <GroundStationForm
                    close={() => {
                        setOpenGSConfigModal(false)
                        get_station_details()
                    }}
                    data={station}
                    mode={'Update'}
                />
            }
        </div>
    )
}

export default GroundStationsDetails
