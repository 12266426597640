//@ts-nocheck
import { iteratee } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react'

import './ADCSGrafana.css'

const AdcsPowerGrafanaPanel = ({ adcs_pannels, selectedADCSButton, handleGrafanaIframePanel, validation }) => {
    const power_urls = adcs_pannels?.['Power']?.sort((a, b) => a.id - b.id)

    const replaceValidationPanelURL = (url) => {
        if (validation && Object.keys(validation).length > 0) {
            let startTime = new Date(validation?.['startTime']).getTime()
            let endTime = (validation?.['endTime'] && (new Date(validation?.['startTime']).getTime() !== new Date(validation?.['endTime']).getTime())) ? new Date(validation?.['endTime']).getTime() : 'now' 
            
            return url.split('from=')[0] + 'from=' + startTime + '&to=' + endTime + '&var-re' + url.split('&var-re')[1]
        }else{
            return url
        }
      }
      
    return (
      <div className='col-12 row no-gutters'>
          {power_urls?.map((item)=>(
            <div className={item?.panel_name === 'Sensor Current' ? 'col-12' : 'col-6'}>
              <label className='ms-6 labelColor labelColor'>{item?.['panel_name']}</label>
              <iframe className='col-12 h-300 no-gutters' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(item?.['url'])}`} loading="lazy" />
            </div>
          ))}
          
         
      </div>
    )
}

export default AdcsPowerGrafanaPanel