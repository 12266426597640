// @ts-nocheck
import React, { Fragment, useEffect, useState } from 'react';
import TimelineBar from '../../../../../draggable_and_resizable/TimelineBar';
import dayjs from 'dayjs';


interface DraggableBoxProps {
  container_ref: any;
  element_ref: any;
  total_width: number;
  scenario_duration: any;
  scenario_start_time: any;
  simulation_start_time: any;
  timeline_start_time: any;
  duration: any;
  set_simulation_time:(data: any) => void;
  set_simulation_duration:(data: any) => void;
}

const DraggableBox: React.FC<DraggableBoxProps> = ({container_ref, element_ref, total_width, scenario_start_time, scenario_duration, timeline_start_time,
  simulation_start_time, duration, set_simulation_time, set_simulation_duration }) => {
  
  const [timeLineData, setTimeLineData] = useState<any>({
    width: 100,
    height: 30,
    top: 0,
    left: 0,
  })

  useEffect(()=>{
    if(timeline_start_time){
      setTimeLineData({
        ...timeLineData,
        left: (Math.round(new Date(simulation_start_time).getTime() / 1000) - Math.round(new Date(timeline_start_time).getTime() / 1000)) * [10/60],
        width: duration * 60 * [10/60]
      })
    }
  }, [simulation_start_time, timeline_start_time, duration])

  const handleDrag = (event, deltaX) => {
    const element = element_ref.current;
    element.style.scrollBehavior = 'smooth';
    handle_scroll(event)
    let left_position = timeLineData.left + deltaX
    if(left_position >= 0 && (left_position + (duration * 10)) <= total_width){
      let previous_simulation_start_time = new Date(timeline_start_time).getTime() / 60000
      let latest_simulation_start_time = (previous_simulation_start_time + (left_position / 10)) * 60000
      set_simulation_time({
        start_time: dayjs.utc(latest_simulation_start_time),
        duration: Math.round(timeLineData.width / 10)
      })
      setTimeLineData({
        ...timeLineData,
        left: left_position,
      })
    }
  }

  const handleResize = (style, isShiftKey, type) => {
    // type is a string and it shows which resize-handler you clicked
    // e.g. if you clicked top-right handler, then type is 'tr'
    let { top, left, width, height } = style
    top = 0
    left = Math.round(left / 10) * 10
    width = Math.round(width / 10) * 10
    
    height = 30
    if(left >= 0 && width >=100 && width <= ((scenario_duration / 12) * 10) && (left + width) <= total_width){
      if(type === 'r'){
        setTimeLineData({
          top,
          left,
          width,
          height
        })
        set_simulation_duration(Math.round(width / 10))
      }else{
        let previous_simulation_start_time = new Date(timeline_start_time).getTime() / 60000
        let latest_simulation_start_time = (previous_simulation_start_time + (left / 10)) * 60000 
        
        set_simulation_time({
          start_time: dayjs.utc(latest_simulation_start_time),
          duration: Math.round(width / 10)
        })
        setTimeLineData({
          top,
          left,
          width,
          height
        })
      }
    }
  }

  const handleDragStart = () => {
    document.body.style.userSelect = 'none';
  };

  const handleDragEnd = () => {
    document.body.style.userSelect = 'auto';
  };

  const handle_scroll = (event) => {
    const container = container_ref.current;
    container.style.scrollBehavior = 'auto';
    const containerRect = container.getBoundingClientRect();
  
    const containerLeft = containerRect.left;
    const containerRight = containerRect.right;
  
    const mousePosX = event.clientX;
    const scrollThreshold = 50; // Adjust this value as needed
  
    // If mouse is near the left edge of the container
    if (mousePosX < containerLeft + scrollThreshold) {
      const scrollAmount = containerLeft - mousePosX + scrollThreshold;
      container.scrollLeft -= scrollAmount;
    } 
    // If mouse is near the right edge of the container
    else if (mousePosX > containerRight - scrollThreshold) {
      const scrollAmount = mousePosX - containerRight + scrollThreshold;
      container.scrollLeft += scrollAmount;
    }
  }
  



  return (
      <TimelineBar
        element_ref={element_ref}
        left={timeLineData.left}
        top={timeLineData.top}
        width={timeLineData.width}
        height={timeLineData.height}
        zoomable='n, w, s, e, nw, ne, se, sw'
        rotatable={false}
        onResize={handleResize}
        onDrag={handleDrag}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
    />
  );
};

export default DraggableBox;
