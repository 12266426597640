//@ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import './ADCSGrafana.css'

const AttitudeDetermination = ({adcs_pannels,validation}) => {
    
    const attitudeUrl = adcs_pannels?.['Measurements']?.['Attitude_Determination']?.sort((a, b) => a.id - b.id);

    const replaceValidationPanelURL = (url) => {
        if (validation && Object.keys(validation).length > 0) {
            let startTime = new Date(validation?.['startTime']).getTime()
            let endTime = (validation?.['endTime'] && (new Date(validation?.['startTime']).getTime() !== new Date(validation?.['endTime']).getTime())) ? new Date(validation?.['endTime']).getTime() : 'now' 
            
            return url.split('from=')[0] + 'from=' + startTime + '&to=' + endTime + '&var-re' + url.split('&var-re')[1]
        }else{
            return url
        }
      }


    return(
        <div className='col-12 row no-gutters'>
             {attitudeUrl?.map((item) => (
                <div className='col-12 '>
                    <label className='ms-6 labelColor labelColor'>{item?.['panel_name']}</label>
                    <iframe className='col-12 h-300 no-gutters' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(item?.['url'])}`} loading="lazy" />
                </div>
             ))}
        </div>
    )
}

const MagneticFieldAndControl = ({adcs_pannels,validation}) => {
    const magneticFieldUrls = adcs_pannels?.['Measurements']?.['Mag_Field_Controls'].sort((a, b) => a.id - b.id);

    const replaceValidationPanelURL = (url) => {
        if (validation && Object.keys(validation).length > 0) {
            let startTime = new Date(validation?.['startTime']).getTime()
            let endTime = (validation?.['endTime'] && (new Date(validation?.['startTime']).getTime() !== new Date(validation?.['endTime']).getTime())) ? new Date(validation?.['endTime']).getTime() : 'now' 
            
            return url.split('from=')[0] + 'from=' + startTime + '&to=' + endTime + '&var-re' + url.split('&var-re')[1]
        }else{
            return url
        }
      }


    return(
        <div className='col-12 row no-gutters'>
             {magneticFieldUrls?.map((item) => (
                <div className='col-12 '>
                    <label className='ms-6 labelColor labelColor'>{item?.['panel_name']}</label>
                    <iframe className='col-12 h-300 no-gutters' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(item?.['url'])}`} loading="lazy" />
                </div>
             ))}
             
        </div>
    )
}

const MotionAndDynamics = ({adcs_pannels,validation}) => {
    const motionDynamicsUrl = adcs_pannels?.['Measurements']?.['Motion_Dynamics'].sort((a, b) => a.id - b.id);

    const replaceValidationPanelURL = (url) => {
        if (validation && Object.keys(validation).length > 0) {
            let startTime = new Date(validation?.['startTime']).getTime()
            let endTime = (validation?.['endTime'] && (new Date(validation?.['startTime']).getTime() !== new Date(validation?.['endTime']).getTime())) ? new Date(validation?.['endTime']).getTime() : 'now' 
            
            return url.split('from=')[0] + 'from=' + startTime + '&to=' + endTime + '&var-re' + url.split('&var-re')[1]
        }else{
            return url
        }
      }


    return(
        <div className='col-12 row no-gutters'>
             {motionDynamicsUrl?.map((item) => (
                <div className='col-12 '>
                    <label className='ms-6 labelColor labelColor'>{item?.['panel_name']}</label>
                    <iframe className='col-12 h-300 no-gutters' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(item?.['url'])}`} loading="lazy" />
                </div> 
             ))}
             
        </div>
    )
}

const NavigationAndPositioning = ({adcs_pannels,validation}) => {
    const navigationUrls = adcs_pannels?.['Measurements']?.['Navigation_Positioning'].sort((a, b) => a.id - b.id);

    const replaceValidationPanelURL = (url) => {
        if (validation && Object.keys(validation).length > 0) {
            let startTime = new Date(validation?.['startTime']).getTime()
            let endTime = (validation?.['endTime'] && (new Date(validation?.['startTime']).getTime() !== new Date(validation?.['endTime']).getTime())) ? new Date(validation?.['endTime']).getTime() : 'now' 
            
            return url.split('from=')[0] + 'from=' + startTime + '&to=' + endTime + '&var-re' + url.split('&var-re')[1]
        }else{
            return url
        }
      }


    return(
        <div className='col-12 row no-gutters'>
            {navigationUrls?.map((item)=>(
                <div className='col-12 '>
                <label className='ms-6 labelColor labelColor'>{item?.['panel_name']}</label>
                <iframe className='col-12 h-300 no-gutters' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(item?.['url'])}`} loading="lazy" />
            </div> 
            ))}
        </div>
    )
}

const AdcsMeasurenmentGrafanaPanel = ({ adcs_pannels, selectedADCSButton, handleGrafanaIframePanel, validation, setCurrentMeasurenmentSubButton, currentComponent, setCurrentComponent }) => {
   
    return (
        <>
            {adcs_pannels?.['Measurements']?.['Attitude_Determination'] && <AttitudeDetermination adcs_pannels={adcs_pannels} validation={validation}/>}
            {adcs_pannels?.['Measurements']?.['Mag_Field_Controls'] &&<MagneticFieldAndControl adcs_pannels={adcs_pannels} validation={validation}/>}
            {adcs_pannels?.['Measurements']?.['Motion_Dynamics'] &&<MotionAndDynamics adcs_pannels={adcs_pannels} validation={validation}/>}
            {adcs_pannels?.['Measurements']?.['Navigation_Positioning'] &&<NavigationAndPositioning adcs_pannels={adcs_pannels} validation={validation} />}
        </>
    );
};

export default AdcsMeasurenmentGrafanaPanel;

