import { Fragment, useState } from 'react'
import ModalDropdown from './ModalDropdown';
import { containsSpecialChars } from '../../../../../CommonComponents/CommonFunctions';
import { toast } from 'react-toastify';

interface ScenarioDetailsProps {
    scenario_details: any;
    set_scenario_details: (details: Object) => void;
    navigate_scenario: (details: string) => void;
    data_submited: string[];
    true_twin: Object;
    scenario_step?: string;
}

const ScenarioDetails: React.FC<ScenarioDetailsProps> = ({scenario_details, set_scenario_details, navigate_scenario, true_twin, scenario_step, data_submited}) => {

    return (
        <Fragment>
            <div className="modalBoxShadowDiv">
                <button 
                    onClick={() =>{
                        if(data_submited?.includes('Scenario_Details')){
                            navigate_scenario('Scenario_Details');
                        }
                    }} 
                    className={`modalCheckboxDiv bg-transparent ${!data_submited?.includes('Scenario_Details') ? "disabledBtn" : ''}`}
                >
                    <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
                    <div className="modalDropDownIconBtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d={scenario_step === 'Scenario_Details' ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                                stroke="#F0F0F0"
                                strokeWidth="1.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className="modalCheckboxText modalWhiteSubHeader">Scenario Details</div>
                    {data_submited?.includes('Scenario_Details') && <img className="greenTickIcon" src={require("../ImageSvgs/GreenTickIcon.svg").default} />}
                </button>
            </div>

            {scenario_step === 'Scenario_Details' && (
                <form id="scenarioDetails">
                    <div className="modalSubHeader modalAnalysisText">Scenario Title</div>
                    <input
                        value={scenario_details.scenarioName}
                        onChange={(e) => {
                            if(containsSpecialChars(e.target.value)){
                                toast.error("Special characters not allowed", { toastId: "error" })
                            }else if(e.target.value?.length <= 100){
                                set_scenario_details({
                                    scenarioName: e.target.value.trimStart()
                                })
                            }else{
                                toast.error("Scenario name can't be greater than 100 characters",{ toastId: "err" })
                            }
                        }}
                        className="modalInputFullLen"
                        type="text"
                        placeholder="Scenario title"
                        required
                    ></input>
                    <div className="modalSubHeader modalAnalysisText opacity-75">Time Scaling factor</div>
                    <input
                        disabled
                        // value={scenario_details?.sampleRate}
                        value={'Real Time'}
                        onChange={(e) => {
                            set_scenario_details({
                                sampleRate: e.target.value
                          })
                        }}
                        className="modalInputFullLen opacity-75"
                        type="text"
                        placeholder="Time scaling factor"
                        required
                    ></input>
                    {/* <div className="modalSubHeader modalAnalysisText opacity-50">Goal</div>
                    <div style={{ margin: "12px" }}>
                        <ModalDropdown options={[]} disabled={true} handleSelectedOption={()=>{}} selectedOption={null} placeholder='Goal'/>
                    </div>
                    <div className="modalSubHeader modalAnalysisText opacity-50">Objective</div>
                    <div style={{ margin: "12px" }}>
                        <ModalDropdown options={[]} disabled={true} handleSelectedOption={()=>{}} selectedOption={null} placeholder='Obective'/>
                    </div> */}
                </form>
            )}
        </Fragment>
    )
}

export default ScenarioDetails
