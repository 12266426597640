import { Fragment, useEffect, useState } from 'react'
import OperateModal from './modal/OperateModal'
import Sidebar from './sidebar/Sidebar'
import Visualization from './visualization/Visualization'
import { useParams } from 'react-router'
import MissionService from '../../../service/MissionService'
import OperateService from '../../../service/OperateService'
import Loader from '../../../Layouts/Loader/Loader'
import { navigateTo } from '../../CommonComponents/CommonFunctions'
import { useNavigate } from "react-router-dom";

const Operate = () => {

  const navigate = useNavigate();
  const params = useParams();
  const { mission_name, mission_id } = params
  const [sidebarLoader, setSidebarLoader ] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [fetchOrbitDetails, setFetchOrbitDetails] = useState<any>(false)
  const [loadingScreen, setLoadingScreen] = useState(true);

  const [promotedSatellites, setPromotedSatellites] = useState([])


  const get_all_promoted_satellites = () => {
    OperateService.getAllPromotedSatellites(mission_id)?.then((res) => {
      if (res.data) {
        setPromotedSatellites(res.data)
        setSidebarLoader(false)
      }
    }).catch((err) => {
      setSidebarLoader(false)
      console.error(err);
    })
  }


  const navigate_to_dashboard = (sat_name, sat_id, truetwin_name, truetwin_id, scenario_name, scenario_id) => {
    navigateTo(
      `${mission_name}/${mission_id}/${sat_name}/${sat_id}/${truetwin_name}/${truetwin_id}/operate/${scenario_name}/${scenario_id}/dashboard`,
      navigate
    );
  };

 const calculate_orbit_details = (data) => {
  setLoadingScreen(true)
    let scenario_id = data?.['scenarioDetails']?.['scenarioId']
    let scenario_name = data?.['scenarioDetails']?.['scenarioName']
    let sat_name = data?.['name']
    let sat_id = data?.['satId']
    let truetwin_id = data?.['trueTwinDetails']?.['trueTwinId']
    let truetwin_name = data?.['trueTwinDetails']?.['Name']
    let orbit_data = {
      ...data?.['scenarioDetails'],
      "orbit": data?.['scenarioDetails']?.['orbit'],
      "missionId": mission_id,
      'satellite_name': data?.['name'],
      "epoch": new Date(data?.['scenarioDetails']?.['time']).valueOf() / 1000,
      "date": data?.['scenarioDetails']?.['time']?.split('Z')[0],
      "refresh_rate": 5,
      "accel_factor": 1,
      "events": {},
    }
    MissionService.patchScenario(scenario_id, orbit_data).then((res) => {
      if(res?.data){
        // setFetchOrbitDetails({
        //   'scenario_id': data?.['scenarioId'],
        //   'truetwin_id': data?.['trueTwinId']
        // })
        navigate_to_dashboard(sat_name, sat_id, truetwin_name, truetwin_id, scenario_name, scenario_id)
      }
    }).catch((err) => {
        console.log(err)
        setLoadingScreen(false)
    })
 }

  useEffect(() => {
    get_all_promoted_satellites()
  },[]) 


  return (
    <Fragment>
      <div className='w-100 d-flex align-items-center justify-content-center h-100 position-relative'>
        <Sidebar
          sidebarLoader={sidebarLoader}
          setSidebarLoader={(data) => setSidebarLoader(data)}
          openModal={(data) => {setOpenModal(data) }}
          promotedSatellites={promotedSatellites}
          fetchPromotedSatellites={() => get_all_promoted_satellites()}
          calculate_orbit_details={(data) => calculate_orbit_details(data)}
        />
        <Visualization
          fetch_orbit_details={fetchOrbitDetails}
          setLoadingScreen={(data) => setLoadingScreen(data)}
        />
      </div>
      {openModal && <OperateModal close={() => { 
        setOpenModal(false)
        get_all_promoted_satellites()
       }} openModal={openModal} />}
       {loadingScreen && <Loader/>}
    </Fragment>
  )
}

export default Operate