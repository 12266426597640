// @ts-nocheck

import { useEffect, useState } from 'react';
import CommandCenterService from '../../../../service/CommandCenterService';
import { useNavigate, useParams } from 'react-router-dom';
import MissionService from '../../../../service/MissionService';
import { toast } from 'react-toastify';
import Footer from "./Footer";
import QuickDeployDashboard from "./QuickDeployDashboard/QuickDeployDashboard";
import SimulationDashboard from "./SimulationDashBoard/SimulationDashboard";
import Loader from "../../../../Layouts/Loader/Loader";
import LoaderAnimation from "../Loader/LoaderAnimation";
import Dialog from "../../../CommonComponents/Dialog";
import { navigateTo } from '../../../CommonComponents/CommonFunctions';
import { setRemoteAppStatus } from '../../../../store/MissionSlice';
import { useDispatch } from 'react-redux';
import './Dashboard.css'

const Dashboard = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, scenario_id } = params
    const [TrueTwin, setTrueTwin] = useState<any>({});
    const [loading, setLoading] = useState<any>(true);
    const [selectedTabBtn, setSelectedTabBtn] = useState('')
    const [simulationStatus, setSimulationStatus] = useState({});

    const [deployLoader, setDeployLoader] = useState<any>(false);
    const [openRetireModel, setOpenRetireModel] = useState(false);
    const [isGroundStationAvailable,setGroundStationAvailable] = useState(true)
    const [isScenarioAutomated, setIsScenarioAutomated] = useState('')
    const [taskListQueue, setTaskListQueue]=useState([])
    const [isShowPanel, SetIsShowPanel] = useState(true)
    const [scenarioData, setscenarioData] = useState({})



    const navigate_to_truetwin_details = () => {
        navigateTo(
            `${mission_name}/${mission_id}/${sat_name}/${sat_id}/truetwin_details/${truetwin_name}/${truetwin_id}/${TrueTwin?.['deployInfo']?.['type'] === 'Quick Deploy' ? 'Configuration' : 'Scenarios'}`,
            navigate
        );
    }
    const get_truetwin_by_id = () => {
        CommandCenterService.getTrueTwinStatus(truetwin_id)?.then((res) => {
            if (res.data) {
                setTrueTwin(res.data)
                setSelectedTabBtn(res?.['data']?.['deployInfo']?.['type'] === 'Quick Deploy' ? 'Console' : 'Overview')
                if(res.data?.['Category'] === "TrueTwin"){
                    get_ground_station_data();
                }
            }
            if(res?.['data']?.['deployInfo']?.['type'] === 'Quick Deploy'){
                setLoading(false)
            }
        }).catch((err) => {
            console.error(err);
            setLoading(false)
        })
    }

    const get_scenario_info = () => {
        if(scenario_id && scenario_id !== undefined){
          MissionService.getScenarioById(scenario_id)?.then((res) => {
            if(res.data){
                setscenarioData(res.data)
                setIsScenarioAutomated(res.data?.['isScenarioAutomated']) 
            }
          }).catch((err) => {
            console.error(err)
          })
        }
    }

    const get_ground_station_data = () => {
        MissionService.getGroundStations(scenario_id)?.then((res) => {
            if(res.data) {
                res?.data?.['groundStations']?.length > 0 ? setGroundStationAvailable(true) : setGroundStationAvailable(false)
            }
        }).then((err)=> {
            console.log(err)
        })
    }

    const get_truetwin_status = () => {

        const check_status = setInterval(() => {
            CommandCenterService.getTrueTwinStatus(truetwin_id)?.then((res) => {
                if (res.data && res.data?.['isDeployed'] === 'No') {
                    setTrueTwin(res.data)
                    clearInterval(check_status)
                    if(TrueTwin?.['isRemoteAppConfigured'] === 'Yes'){
                        dispatch(
                            setRemoteAppStatus({
                            status: 0,
                            truetwin_id: '',
                            })
                        );
                    }
                }
            }).catch((err) => {
                console.error(err);
            })
        },[10000])
    }

    const undeploy_truetwin = () => {
        CommandCenterService.unDeployTrueTwin(TrueTwin?.["trueTwinId"])
            ?.then((res) => {
                if (res) {
                    get_truetwin_by_id()
                    if(TrueTwin?.['isRemoteAppConfigured'] === 'Yes'){
                        dispatch(
                            setRemoteAppStatus({
                            status: 0,
                            truetwin_id: '',
                            })
                        );
                    }
                    setDeployLoader(false)
                    navigate_to_truetwin_details()
                }
            })
            .catch((err) => {
                setDeployLoader({'error': `An error occurred while retiring ${TrueTwin?.['Category']} ${truetwin_name?.length > 15 ? truetwin_name?.substring(0,15)+"..." : truetwin_name}`});
                toast.error('Something went wrong while undeploying truetwin');
            });
    };
    const dis_connect_ground_station = () => {
        setDeployLoader({'retiring': `Retiring ${TrueTwin?.['Category']} ${truetwin_name?.length > 15 ? truetwin_name?.substring(0,15)+"..." : truetwin_name}`});
        CommandCenterService.disconnectGroundStation(TrueTwin?.["groundStationId"], TrueTwin?.["trueTwinId"])?.then((res) => {
            if(res.data){
                undeploy_truetwin();
            }
        })
        .catch((err) => {
            undeploy_truetwin();
            console.error(err);
        });
    };


    useEffect(() => {
        get_truetwin_by_id();
        get_scenario_info()
    }, [])
    
    const getSimulationStatus = (sim_status) => {
        MissionService.getSimulationStatus(scenarioData?.['simulationId'])?.then((res) => {
            if (res.data) {
                setTaskListQueue(res.data)
                if(res.data?.['simulation_status'] === 'complete'){
                    sim_status = {
                        'simulation_status': res.data?.['simulation_status'],
                        'simulation_time_rel': sim_status?.['simulation_duration'],
                        'simulation_duration': sim_status?.['simulation_duration'],
                    }
                    setSimulationStatus(sim_status)
                    if(TrueTwin?.["isDeployed"] === "Yes" ){
                        get_truetwin_status()
                    }
                    clearInterval(simulationInterval);
                }else if(res.data?.['simulation_status'] === 'active'){
                    sim_status = res.data
                    setSimulationStatus(res.data)
                }
            }
        }).catch((err) => {
        console.error(err);
        })
    }
    useEffect(() => {
        if(TrueTwin?.["isDeployed"] === "Yes"  && TrueTwin?.['Category'] === "TrueTwin" && scenarioData?.['simulationId']){
            let sim_status:any = {}
            
            const simulationInterval = setInterval(()=>{
                getSimulationStatus(sim_status)
            
          }, 1000)
          return () => clearInterval(simulationInterval);
        }
    },[TrueTwin?.["isDeployed"],scenarioData?.['simulationId']])


    return (
        <div className='w-100 h-100'>
            <div className='dashboard'>
                {loading && <Loader/>}
                {TrueTwin && Object.keys(TrueTwin)?.length > 0 && 
                    <div className='dashboard__body'>
                        {TrueTwin?.['deployInfo']?.['type'] === 'Quick Deploy' ?
                            <QuickDeployDashboard
                                selectedTabBtn={selectedTabBtn}
                                TrueTwin={TrueTwin}
                                simulationStatus={simulationStatus}
                                isQuickDeploy = {true}
                                isScenarioAutomated={isScenarioAutomated} 
                                taskListQueue={taskListQueue}     
                                setLoading={setLoading}                            
                            /> :
                            <SimulationDashboard
                                selectedTabBtn={selectedTabBtn}
                                TrueTwin={TrueTwin}
                                simulationStatus={simulationStatus}
                                isQuickDeploy = {false}
                                isScenarioAutomated={isScenarioAutomated}  
                                taskListQueue={taskListQueue}   
                                isShowPanel = {isShowPanel}  
                                setLoading={setLoading}  
                                scenarioData={scenarioData}                                            
                            />
                        }
                    </div>
                }
                {Object.keys(TrueTwin)?.length > 0 && <div className={`app__footer`}>
                    <Footer
                        setSelectedTabBtn={setSelectedTabBtn}
                        selectedTabBtn={selectedTabBtn}
                        TrueTwin={TrueTwin}
                        simulationStatus={simulationStatus}
                        setOpenRetireModel={setOpenRetireModel}
                        isFlatSat = {TrueTwin?.['Category'] !== 'TrueTwin'}
                        isGroundStationAvailable = {isGroundStationAvailable}
                        isScenarioAutomated={isScenarioAutomated}   
                        SetIsShowPanel = {SetIsShowPanel}          
                    />
                </div>}
            </div>
            {deployLoader && <LoaderAnimation
                objectName={truetwin_name}
                state={deployLoader}
                close={() => setDeployLoader(false)}
                show_worning={TrueTwin?.['isRemoteAppConfigured'] === 'Yes' ? true : false}
            />}

            <Dialog
                onClick={(event) => {
                    if (event) {
                        if (openRetireModel) {
                            dis_connect_ground_station();
                        }
                    }
                    setOpenRetireModel(false)
                }}
                openModel={openRetireModel} comment={`Are you sure you wish to ${TrueTwin?.['Category'] === 'TrueTwin' ? 'retire' : 'end the simulation'} ${truetwin_name?.length> 15 ? truetwin_name?.substring(0,15)+"..." : truetwin_name} ?`}
            />
        </div>
    )
}

export default Dashboard