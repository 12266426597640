//@ts-nocheck
import { iteratee } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react'
import './ADCSGrafana.css'
import { Form } from 'react-bootstrap';


const AdcsStateGrafanaPanels = ({ adcs_pannels, selectedADCSButton, handleGrafanaIframePanel, validation }) => {

  const stateUrl = adcs_pannels?.['State']?.sort((a, b) => a.id - b.id)

  const [positionPanel, setPositionPanel] = useState([])
  const [positionRadioButtons, setPositionRadioButtons] = useState(['WGS 84','ECEF', 'ECI'])
  const [displayPosition, setDispplayPosition] = useState({})
  const [selectedPositionRadioButton, setSelectedPositionRadioButton] = useState('WGS 84')

  const [velocityPanel, setVelocityPanel] = useState([])
  const [velocityRadioButtons, setVelocityRadioButtons] = useState(['ECEF', 'ECI'])
  const [displayVelocity, setDisplayVelocity] = useState({})
  const [selectedVelocityRadioButton, setSelectedVelocityRadioButton] = useState('ECEF')


  const [angularRatePanel, setAngularRatePanel] = useState([])
  const [angularRateRadioButtons, setangularRateRadioButtons] = useState(['Estimated', 'Fine', 'Rate Sensor'])
  const [displayangularRate,  setDisplayangularRate] = useState({})
  const [selectedangularRateRadioButton, setSelectedangularRateRadioButton] = useState('Rate Sensor')

  useEffect(()=>{
    let positionList = []
    let velocityList = []
    let angularRatePanelList = []

    stateUrl?.map((item) => {
      if (item.panel_name === "WGS 84" || item.panel_name === "ECEF" || item.panel_name === "ECI") {
        if (item.panel_name === "WGS 84") {
            setDispplayPosition(item)
       }
        positionList.push(item)
      } else if (item.panel_name === "ECEF Velocity" || item.panel_name === "ECI Velocity") {
                  if (item.panel_name === "ECEF Velocity") {
                      setDisplayVelocity(item)
                  }
                  velocityList.push(item)
      } else if (item.panel_name === "Angular Rate" || item.panel_name === "Fine Estimated Angular Rates" || item.panel_name === "Rate Sensor Rate"){
                if(item.panel_name === "Angular Rate"){
                    setDisplayangularRate(item)
                }
                angularRatePanelList.push(item)
      }
    })

    setPositionPanel(positionList);
    setVelocityPanel(velocityList);
    setAngularRatePanel(angularRatePanelList)
  },[])

  const replaceValidationPanelURL = (url) => {
    if (validation && Object.keys(validation)?.length > 0) {
        let startTime = new Date(validation?.['startTime']).getTime()
        let endTime = (validation?.['endTime'] && (new Date(validation?.['startTime']).getTime() !== new Date(validation?.['endTime']).getTime())) ? new Date(validation?.['endTime']).getTime() : 'now' 
        
        return url?.split('from=')?.[0] + 'from=' + startTime + '&to=' + endTime + '&var-re' + url?.split('&var-re')?.[1]
    }else{
        return url
    }
  }

    return (
      <div className='col-12 row no-gutters'>
            <div className='col-12 mt-3 row'>
                
                <div className='col-6 '>
                                <div className='d-flex justify-content-between'>
                                    <label className='ms-6 labelColor labelColor'>Position</label>
                                    <Form.Group className="horizontal-check">
                                        <div className='d-flex gap-2 align-items-center me-3'>
                                            {positionRadioButtons.map((tab) => {
                                                return (
                                                    <Form.Check
                                                        key={tab}
                                                        label={tab}
                                                        type="radio"
                                                        className="custom-radio gap-2 d-flex align-items-center"
                                                        checked={selectedPositionRadioButton === tab}
                                                        onChange={() => {
                                                            setSelectedPositionRadioButton(tab)
                                                         
                                                            positionPanel?.map((item) => {
                                                                if (item.panel_name === tab) {
                                                                    setDispplayPosition(item)
                                                                }
                                                            })
                                                        }}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </Form.Group>
                                </div>
                                <iframe className='col-12 h-300' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(displayPosition?.['url'])}`} loading="lazy" onLoad={handleGrafanaIframePanel} />
                </div>

                <div className='col-6 '>
                                <div className='d-flex justify-content-between'>
                                    <label className='ms-6 labelColor labelColor'>Velocity</label>
                                    <Form.Group className="horizontal-check">
                                        <div className='d-flex gap-2 align-items-center me-3'>
                                            {velocityRadioButtons.map((tab) => {
                                                return (
                                                    <Form.Check
                                                        key={tab}
                                                        label={tab}
                                                        type="radio"
                                                        className="custom-radio gap-2 d-flex align-items-center"
                                                        checked={selectedVelocityRadioButton === tab}
                                                        onChange={() => {
                                                            setSelectedVelocityRadioButton(tab)
                                                            let pan;
                                                            if (tab === "ECEF") {
                                                                pan = "ECEF Velocity"
                                                            }
                                                            else {
                                                                pan = "ECI Velocity"
                                                            }
                                                            velocityPanel?.map((item) => {
                                                                if (item.panel_name === pan) {
                                                                    setDisplayVelocity(item)
                                                                }
                                                            })
                                                        }}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </Form.Group>
                                </div>
                                <iframe className='col-12 h-300' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(displayVelocity?.['url'])}`} loading="lazy" onLoad={handleGrafanaIframePanel} />
                </div>

                {stateUrl?.[5]?.['panel_name'] && <div className='col-6 '>
                      <label className='ms-6 labelColor labelColor'>Altitude</label>                   
                      <iframe className='col-12 h-300' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(stateUrl?.[5]?.['url'])}`} loading="lazy" onLoad={handleGrafanaIframePanel} />
                </div>}                            

                {stateUrl?.[6]?.['panel_name'] && <div className='col-6 '>
                      <label className='ms-6 labelColor labelColor'>Attitude</label>                   
                      <iframe className='col-12 h-300' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(stateUrl?.[6]?.['url'])}`} loading="lazy" onLoad={handleGrafanaIframePanel} />
                </div>}

                {angularRatePanel.length > 0 && <div className='col-12 '>
                                <div className='d-flex justify-content-between'>
                                    <label className='ms-6 labelColor labelColor'>Angular Rate</label>
                                    {/* <Form.Group className="horizontal-check d-none">
                                        <div className='d-flex gap-2 align-items-center me-3'>
                                            {angularRateRadioButtons.map((tab) => {
                                                return (
                                                    <Form.Check
                                                        key={tab}
                                                        label={tab}
                                                        type="radio"
                                                        className="custom-radio gap-2 d-flex align-items-center"
                                                        checked={selectedangularRateRadioButton === tab}
                                                        onChange={() => {
                                                            setSelectedangularRateRadioButton(tab)
                                                            let pan;
                                                            if (tab === "Estimated") {
                                                                pan = "Estimated Angular Rate"
                                                            }
                                                            else if (tab === "Fine") {
                                                                pan = "Fine Estimated Angular Rates"
                                                            }
                                                            else if (tab === "Rate Sensor") {
                                                                pan = "Rate Sensor Rate"
                                                            }

                                                            angularRatePanel?.map((item) => {
                                                                if (item.panel_name === pan) {
                                                                    setDisplayangularRate(item)
                                                                }
                                                            })
                                                        }}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </Form.Group> */}
                                </div>
                                <iframe className='col-12 h-300' src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(displayangularRate?.['url'])}`} loading="lazy" onLoad={handleGrafanaIframePanel} />
                </div>}
            </div>
      </div>
    )
}

export default AdcsStateGrafanaPanels