// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  ToggleCustomCheckButtonsGroup,
  ToogleRadioButtonsGroup,
} from "../../../../CommonComponents/ToggleButtonsGroup";
import CatalogueService from "../../../../../service/CatalogueService";
import { toast } from "react-toastify";
import { setIsNewPayloadCatelog } from "../../../../../store/MissionSlice";
import { AntSwitch } from "../../../../CommonComponents/AntSwitch";
import { containsSpecialChars, containsWhitespace, truncateNumber } from "../../../../CommonComponents/CommonFunctions";
import Dialog from "../../../../CommonComponents/Dialog";
import Loader from "../../../../../Layouts/Loader/Loader";


const NewPayloadCatelog = ({
  payloadsList,
  onSelectPayload,
  configImg,
  payloadType,
  setImgPayloadDetails,
  payloadSubtype,
  setPayloadSubType,
  imgPayloadDetails,
  setConfigImg,
  radarPayloadDetails,
  setRadarPayloadDetails,
  handleFreqBands,
  configComm,
  setConfigComm,
  selectedBands,
  getAllCatalogs,
}) => {
  const dispatch = useDispatch();

  const [sarSpecificParams, setSarSpecificParams] = useState<any>({
    frequency: "",
    antenna_size: "",
    pulse_width: "",
    sampling_rate: "",
  });

  const [payloadSizeX, setPayloadSizeX] = useState<any>("");
  const [payloadSizeY, setPayloadSizeY] = useState<any>("");
  const [payloadSizeZ, setPayloadSizeZ] = useState<any>("");

  const [massType, setMassType] = useState<any>("kg");

  const [payloadName, setPayloadName] = useState<string>("");
  const [frontAperture, setFrontAperture] = useState<any>(50);
  const [spectralBands, setSpectralBands] = useState<any>(4);
  const [payloadMass, setPayloadMass] = useState<any>("");
  const [payloadPower, setPayloadPower] = useState<any>("");
  const [isNewInterface, setIsNewInterface] = useState(false);
  const [newInterface, setNewInterface] = useState("");
  const [allNewlyAddPayloadInterFaces, setAllNewlyAddPayloadInterFaces] =
    useState<any>([]);
  const [allPayloadInterFaces, setAllPayloadInterFaces] = useState<any>([
    "UART",
    "GPIO",
    "RS485",
    "PCIex1",
    "CAN",
    "I2C",
    "Ethernet",
  ]);
  const [payloadInterFaces, setPayloadInterFaces] = useState<any>([]);
  const [additionalIndex, setAdditionalIndex] = useState<any>([]);
  const [additionalConfigurations, setAdditionalConfigurations] = useState<any>(
    []
  );
  const [additionalConfigurationName, setAdditionalConfigurationName] =
    useState<any>("");
  const [additionalConfigurationValue, setAdditionalConfigurationValue] =
    useState<any>("");
  const [isValidate, setIsValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const getSelectedPayload = (value) => {
    const payloadClass = payloadsList.filter((data) => data.type === value);
    onSelectPayload(payloadClass[0]);
  };

  const [openModal, setOpenModal] = useState(false);


  const checkValidation = () => {
    if (payloadType === "Earth Observation" && payloadSubtype === '') {
      setIsValidate(true);
    }
    else if (payloadType === "Communication" && payloadSubtype === '') {
      setIsValidate(true);
    }
    else if (payloadSubtype === 'Imaging' && imgPayloadDetails === '') {
      setIsValidate(true);
    }
    else if (payloadSubtype === 'Radar' && radarPayloadDetails === '') {
      setIsValidate(true);
    }
    else if (imgPayloadDetails === "MX / Multispectral" && configImg?.swath === '') {
      setIsValidate(true);
    }
    else if (imgPayloadDetails === "MX / Multispectral" && configImg?.gsd === '') {
      setIsValidate(true);
    }
    else if (payloadType === "Communication" && payloadSubtype === "RF" && configComm?.['Data Rate'] === '') {
      setIsValidate(true);
    }
    else if (payloadType === "Communication" && payloadSubtype === "RF" && configComm?.['Number of Beams'] === '') {
      setIsValidate(true);
    }
    else if (payloadType === "Communication" && payloadSubtype === "RF" && selectedBands?.length === 0) {
      setIsValidate(true);
      toast.error('Please select atleast one frequency band.', { toastId: 'o' });
    }
    else if (containsWhitespace(payloadName) || payloadName === "") {
      setIsValidate(true);
    }
    else if (payloadSizeX === "") {
      setIsValidate(true);
    }
    else if (payloadSizeY === "") {
      setIsValidate(true);
    }
    else if (payloadSizeZ === "") {
      setIsValidate(true);
    }
    else if (payloadPower === '' || payloadPower <= 0) {
      toast.error('Peak power consumption must be greater than 0 (W).')
      setIsValidate(true);
    }
    else if (payloadMass === '' || payloadMass <= 0) {
      setIsValidate(true);
    }
    else if (payloadInterFaces.length === 0) {
      setIsValidate(true);
    }
    else {
      setIsValidate(false);
      createNewPayload()
    }
  };

  const createNewPayload = () => {
    setLoading(true);
    let typeSpecificParams: any = {};
    let payloadDetails = "";
    if (
      payloadType === "Earth Observation" &&
      payloadSubtype === "Imaging" &&
      imgPayloadDetails === "MX / Multispectral"
    ) {
      typeSpecificParams = {
        GSD: +configImg?.gsd,
        Swath: +configImg?.swath,
        "Front Aperture": "~" + frontAperture + " cm",
        "Spectral Bands": spectralBands + " Bands MS",
      };
    }
    if (
      payloadType === "Earth Observation" &&
      payloadSubtype === "Radar" &&
      radarPayloadDetails === "SAR"
    ) {
      if (sarSpecificParams?.frequency !== '') {
        typeSpecificParams = {
          ...typeSpecificParams,
          "Frequency": sarSpecificParams?.frequency + " GHz"
        }
      } if (sarSpecificParams?.antenna_size !== '') {
        typeSpecificParams = {
          ...typeSpecificParams,
          "Antenna Size": sarSpecificParams?.antenna_size + " m",
        }
      } if (sarSpecificParams?.pulse_width !== '') {
        typeSpecificParams = {
          ...typeSpecificParams,
          "Pulse Width": sarSpecificParams?.pulse_width + " μs",
        }
      } if (sarSpecificParams?.sampling_rate !== '') {
        typeSpecificParams = {
          ...typeSpecificParams,
          "Sampling Rate": sarSpecificParams?.sampling_rate + " MHz"
        }
      } else {
        typeSpecificParams = { ...typeSpecificParams };
      }
    }
    if (payloadType === "Communication" && payloadSubtype === "RF") {
      typeSpecificParams = {
        "Frequency Bands": selectedBands,
        "Data Rate": +configComm?.['Data Rate'],
        "Number of Beams": +configComm?.['Number of Beams'],
      };
    }
    if (payloadSubtype === "Imaging") {
      payloadDetails = imgPayloadDetails;
    }
    if (payloadSubtype === "Radar") {
      payloadDetails = radarPayloadDetails;
    }
    let additionalConfig = {}
    additionalConfigurations.map(item => {
      additionalConfig = {
        ...additionalConfig,
        [Object.keys(item)[0]]: Object.values(item)[0]
      }
    })

    let payload_size = truncateNumber(((payloadSizeX * payloadSizeY * payloadSizeZ) / 1000), 1).toLocaleString()

    const atmos_data = {
      "catalogue-type": "Payload",
      component_specific_attributes: {
        interfaces: payloadInterFaces,
        payloadDetails: payloadDetails,
        payloadSubType: payloadSubtype,
        payloadType: payloadType,
        typeSpecificParams: typeSpecificParams,
        additionalParams: additionalConfig,
      },
      description: payloadName,
      max_power_consumption_in_watts: payloadPower,
      name: payloadName,
      "product-cookie": {
        Size: payload_size > 0.1 ? payload_size.split('.')[1] == 0 ? payload_size.split('.')[0] : payload_size : 0.1,
        "Supported Interfaces": "UART",
        Weight: massType === "kg" ? payloadMass + ' kg' : (payloadMass / 1000) + ' kg',
        "friendly-name": payloadName,
      },
      "product-flavor": payloadName,
      "product-line": "",
      "subsystem-type": "Payload Server",
      uniqueId: "",
      visibility: "public",
      weight_in_gms: massType === "kg" ? `${(payloadMass * 1000)}` : payloadMass,
    };

    CatalogueService.addCatalogue(atmos_data)
      ?.then((response) => {
        getAllCatalogs();
        toast.success("Payload Device added successfully", { toastId: 'o' });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong while creating new catalogue", { toastId: 'o' });
        setLoading(false);
      });
  };


  return (
    <div className="d-flex w-100 h-100">
      {loading && <Loader />}
      <div className="h-100 w-100 py-5 d-flex justify-content-center">
        <div className="d-flex w-70 h-100 align-self-center justify-content-between gap-5">
          <div className="w-50 d-flex flex-column h-90 gap-3 overflow-y-auto pb-5">
            <div className="w-100 d-flex flex-column ps-3">
              <label className="color-text">Payload Class</label>
              <div className="d-flex w-100 gap-3 flex-wrap align-items-start">
                {payloadsList.map((data, i) => (
                  <Button
                    key={data.type + i}
                    variant={
                      payloadType === data.type ? "primary" : "outline-danger"
                    }
                    type="button"
                    className={`d-flex align-items-center btn-payload btn ${payloadType === data.type
                      ? "btn-primary"
                      : "btn-outline-primary"
                      } pt-3 pb-3 pe-4 ps-4 fs-14`}
                    onClick={(e) => {
                      getSelectedPayload(data.type);
                      setPayloadSubType('')
                      setImgPayloadDetails('')
                      setIsValidate(false);
                    }}
                  >
                    {data.type}
                  </Button>
                ))}
              </div>
            </div>
            {payloadType === "Earth Observation" && (
              <Form noValidate validated={isValidate} className="w-100 d-flex flex-column ms-0 ps-0">
                <div className="w-100 justify-content-between group-radio">
                  <Col className="mb-3">
                    <label className="color-text">Payload Type</label>
                    <ToogleRadioButtonsGroup
                      name={"ImgPaylodSubType"}
                      value={payloadSubtype}
                      defaultValue={"Imaging"}
                      toggleButtonsList={[
                        "Imaging",
                        "Radar",
                        "AIS",
                      ]}
                      selectedRadios={(value) => setPayloadSubType(value)}
                    />
                    {isValidate && payloadSubtype === "" && <span className="fs-14 text-danger">
                      Select a payload type.
                    </span>}
                  </Col>
                </div>
                {payloadSubtype === "Imaging" && (
                  <div className="w-100 justify-content-between group-radio">
                    <Col className="mb-5 d-flex w-100 flex-wrap">
                      <label className="color-text">Payload SubType</label>
                      <ToogleRadioButtonsGroup
                        name={"ImgPaylodDetails"}
                        value={imgPayloadDetails === 'MX / Multispectral' ? 'Multispectral' : imgPayloadDetails}
                        defaultValue={"Multispectral"}
                        toggleButtonsList={[
                          "Multispectral",
                          "Hyperspectral",
                          "Thermal IR",
                          "SWIR",
                        ]}
                        selectedRadios={(value) => {
                          setImgPayloadDetails(value === 'Multispectral' ? 'MX / Multispectral' : value)
                        }}
                      />
                      {isValidate && imgPayloadDetails === "" && <span className="fs-14 text-danger">
                        Select a payload subtype.
                      </span>}
                    </Col>
                  </div>
                )}


                {payloadSubtype === "Imaging" &&
                  imgPayloadDetails === "MX / Multispectral" && (
                    <div className="w-90">
                      <Col className="mb-5">
                        <Form.Label className="color-text">
                          Minimum Ground Sampling Distance (m)
                        </Form.Label>
                        <Form.Control
                          required={
                            payloadSubtype === "Imaging" &&
                            imgPayloadDetails === "MX / Multispectral"
                          }
                          type="text"
                          placeholder="Enter ground sampling distance (m)"
                          value={configImg?.gsd}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setConfigImg((prevState) => ({
                                ...prevState, // <-- copy other nested state
                                gsd: event.target.value,
                              }));
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter ground sampling distance (m).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
                {payloadSubtype === "Imaging" &&
                  imgPayloadDetails === "MX / Multispectral" && (
                    <div className="w-90">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Minimum Swath (km)</Form.Label>
                        <Form.Control
                          required={
                            payloadSubtype === "Imaging" &&
                            imgPayloadDetails === "MX / Multispectral"
                          }
                          type="text"
                          placeholder="Enter minimum swath (km)"
                          value={configImg?.swath}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setConfigImg((prevState) => ({
                                ...prevState, // <-- copy other nested state
                                swath: event.target.value,
                              }));
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter minimum swath (km).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
                {payloadSubtype === "Imaging" &&
                  imgPayloadDetails === "MX / Multispectral" && (
                    <div className="w-90">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Front Aperture (cm)</Form.Label>
                        <Form.Control
                          required={
                            payloadSubtype === "Imaging" &&
                            imgPayloadDetails === "MX / Multispectral"
                          }
                          type="text"
                          placeholder="Enter front aperture (cm)"
                          value={frontAperture}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setFrontAperture(event.target.value);
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter front aperture (cm).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
                {payloadSubtype === "Imaging" &&
                  imgPayloadDetails === "MX / Multispectral" && (
                    <div className="w-90">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Spectral Bands (MS)</Form.Label>
                        <Form.Control
                          required={
                            payloadSubtype === "Imaging" &&
                            imgPayloadDetails === "MX / Multispectral"
                          }
                          type="text"
                          placeholder="Enter spectral bands (MS)"
                          value={spectralBands}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setSpectralBands(event.target.value);
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter spectral bands (MS).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}

                {payloadSubtype === "Radar" && (
                  <div className="w-90 justify-content-between group-radio">
                    <Col className="mb-5">
                      <label className="color-text">Payload SubType</label>
                      <ToogleRadioButtonsGroup
                        name={"radarPayloadDetails"}
                        value={radarPayloadDetails}
                        defaultValue={"SAR"}
                        toggleButtonsList={[
                          "SAR",
                          "LIDAR",
                          "Microwave",
                        ]}
                        selectedRadios={(value) =>
                          setRadarPayloadDetails(value)
                        }
                      />
                      {isValidate && radarPayloadDetails === "" && <span className="fs-14 text-danger">
                        Select a payload subtype.
                      </span>}
                    </Col>
                  </div>
                )}
                {payloadSubtype === "Radar" &&
                  radarPayloadDetails === "SAR" && (
                    <div className="w-90">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Frequency (GHz)</Form.Label>
                        <Form.Control
                          required={
                            (payloadSubtype === "Radar" &&
                              radarPayloadDetails === "SAR")
                          }
                          type="text"
                          placeholder="Enter frequency (GHz)"
                          value={sarSpecificParams?.frequency}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setSarSpecificParams((prev) => ({
                                ...prev,
                                frequency: event.target.value,
                              }));
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter frequency (GHz).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
                {payloadSubtype === "Radar" &&
                  radarPayloadDetails === "SAR" && (
                    <div className="w-90">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Antenna Size (m)</Form.Label>
                        <Form.Control
                          required={
                            payloadSubtype === "Radar" &&
                            radarPayloadDetails === "SAR"
                          }
                          type="text"
                          placeholder="Enter antenna size (m)"
                          value={sarSpecificParams?.antenna_size}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setSarSpecificParams((prev) => ({
                                ...prev,
                                antenna_size: event.target.value,
                              }));
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter antenna size(m).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
                {payloadSubtype === "Radar" &&
                  radarPayloadDetails === "SAR" && (
                    <div className="w-90">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Pulse Width (μs)</Form.Label>
                        <Form.Control
                          required={
                            payloadSubtype === "Radar" &&
                            radarPayloadDetails === "SAR"
                          }
                          type="text"
                          placeholder="Enter pulse width (μs)"
                          value={sarSpecificParams?.pulse_width}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setSarSpecificParams((prev) => ({
                                ...prev,
                                pulse_width: event.target.value,
                              }));
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter pulse width (μs).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
                {payloadSubtype === "Radar" &&
                  radarPayloadDetails === "SAR" && (
                    <div className="w-90">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Sampling Rate (MHz)</Form.Label>
                        <Form.Control
                          required={
                            payloadSubtype === "Radar" &&
                            radarPayloadDetails === "SAR"
                          }
                          type="text"
                          placeholder="Enter sampling rate (MHz)"
                          value={sarSpecificParams?.sampling_rate}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setSarSpecificParams((prev) => ({
                                ...prev,
                                sampling_rate: event.target.value,
                              }));
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter sampling rate (MHz).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
              </Form>
            )}

            {payloadType === "Communication" && (
              <Form noValidate validated={isValidate} className="w-100 d-flex flex-column">
                <div className="w-100 justify-content-between group-radio">
                  <Col className="mb-3">
                    <label className="color-text">Payload Type</label>
                    <ToogleRadioButtonsGroup
                      name={"commPaylodSubType"}
                      value={payloadSubtype}
                      defaultValue={"Optical"}
                      toggleButtonsList={["RF", "Optical"]}
                      selectedRadios={(value) => setPayloadSubType(value)}
                    />
                    {isValidate && payloadSubtype === "" && <span className="fs-14 text-danger">
                      Select a payload type.
                    </span>}
                  </Col>
                </div>
                {payloadSubtype === "RF" && (
                  <div className="w-100">
                    <Col className="mb-5">
                      <Form.Label className="color-text">Frequency Bands</Form.Label>
                      <ToggleCustomCheckButtonsGroup
                        toggleButtonsList={[
                          "S Band",
                          "Ka Band",
                          "Ku Band",
                          "V Band",
                          "E Band",
                          "Custom",
                        ]}
                        selectedCheck={handleFreqBands}
                        selectedUnCheck={handleFreqBands}
                        selectedBands={selectedBands}
                      />
                      <span className="text-danger pt-1 d-flex">
                        {(isValidate && selectedBands.length === 0) && 'Please select atleast one frequency band.'}
                      </span>
                    </Col>
                  </div>
                )}
                <div className={`w-100 d-flex justify-content-between align-items-center`}>
                  {payloadSubtype === "RF" && (
                    <div className="w-100">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Data Rate (Mbps)</Form.Label>
                        <Form.Control
                          required={payloadSubtype === "RF"}
                          type="text"
                          placeholder="Enter data rate (Mbps)"
                          value={configComm?.['Data Rate']}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setConfigComm((prevState) => ({
                                ...prevState, // <-- copy other nested state
                                ['Data Rate']: event.target.value,
                              }));
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter data rate (Mbps).
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
                  {payloadSubtype === "RF" && (
                    <div className="w-100">
                      <Col className="mb-5">
                        <Form.Label className="color-text">Number of Beams</Form.Label>
                        <Form.Control
                          required={payloadSubtype === "RF"}
                          type="text"
                          placeholder="Enter number of beams"
                          value={configComm?.['Number of Beams']}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            event.preventDefault();
                            if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                              setConfigComm((prevState) => ({
                                ...prevState, // <-- copy other nested state
                                ['Number of Beams']: event.target.value,
                              }));
                            }
                          }}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter number of beams.
                        </Form.Control.Feedback>
                      </Col>
                    </div>
                  )}
                </div>
              </Form>
            )}

            <div className={`${payloadType === "Communication" ? "w-95" : "w-85"} ms-3 d-flex flex-column`}>
              <Form.Label className="color-text">Additional Configuration</Form.Label>
              {additionalIndex.map((_, i) => (
                <div className="d-flex w-100 gap-3 mb-3" key={i}>
                  <Form.Control
                    type="text"
                    placeholder="Enter cofiguration name"
                    disabled={(additionalConfigurations?.length > 0 && additionalConfigurations?.[i]) ? true : false}
                    value={
                      (additionalConfigurations?.length > 0 && additionalConfigurations?.[i]) ? Object.keys(additionalConfigurations[i])[0] : additionalConfigurationName
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(event) => {
                      event.preventDefault();
                      setAdditionalConfigurationName(event.target.value);
                    }}
                  ></Form.Control>
                  <Form.Control
                    type="text"
                    disabled={(additionalConfigurations?.length > 0 && additionalConfigurations[i]) ? true : additionalConfigurationName === "" ? true : false}
                    placeholder="Enter configuration value"
                    value={
                      (additionalConfigurations?.length > 0 && additionalConfigurations[i]) ? additionalConfigurations[i][Object.keys(additionalConfigurations[i])] : additionalConfigurationValue
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(event) => {
                      event.preventDefault();
                      if (event.target.value.length <= 15) {
                        setAdditionalConfigurationValue(event.target.value);
                      }
                    }}
                  ></Form.Control>

                  {!additionalConfigurations[i] && additionalConfigurationValue !== '' && <Button
                    variant="outline-primary"
                    type="button"
                    className="d-flex align-items-center btn btn-payload btn-outline-primary p-1 justify-content-center"
                    onClick={() => {
                      setAdditionalConfigurations((prev) => ([
                        ...prev,
                        {
                          [additionalConfigurationName]: additionalConfigurationValue,
                        }
                      ]));
                      setAdditionalConfigurationName("");
                      setAdditionalConfigurationValue("");
                    }}
                  >
                    <i className="fe fe-check fs-16"></i>
                  </Button>}
                  <Button
                    variant="outline-primary"
                    type="button"
                    className="d-flex align-items-center btn btn-payload btn-outline-primary p-1 justify-content-center"
                    onClick={() => {
                      if (additionalConfigurations[i]) {
                        let filterconfig = additionalConfigurations.filter((item) => item !== additionalConfigurations[i])
                        setAdditionalConfigurations(filterconfig);
                      }
                      if (additionalIndex.includes(i)) {
                        let filterIndex = additionalIndex.filter((item) => item !== i)
                        let remainIndex = filterIndex.map((_, index) => {
                          return index
                        })
                        setAdditionalIndex(remainIndex);
                      }
                    }}
                  >
                    <i className="fe fe-x fs-16"></i>
                  </Button>
                </div>
              ))}
            </div>
            <div className="d-flex gap-3 ms-3">
              <Button
                disabled={
                  (additionalConfigurations.length === additionalIndex.length || additionalIndex.length === 0)
                    ? false
                    : true
                }
                variant="outline-primary"
                type="button"
                className="d-flex align-items-center btn-payload btn btn-outline-primary pt-1 pb-1 pe-6 ps-6 fs-14"
                onClick={(e) => {
                  let index =
                    additionalIndex.length > 0
                      ? additionalIndex[additionalIndex.length - 1] + 1
                      : 0;
                  setAdditionalIndex([...additionalIndex, index]);

                }}
              >
                {additionalIndex.length === 0 ? "Add" : "Add More"}
              </Button>
            </div>

          </div>
          <div className="w-45 d-flex flex-column gap-3 h-100 overflow-y-auto ">
            <Form
              noValidate
              validated={isValidate}
              className="w-90 d-flex flex-column"
            >
              <div className="w-100">
                <Col className="mb-5">
                  <Form.Label className="color-text">Payload Name *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter payload name"
                    value={payloadName}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(event) => {
                      event.preventDefault();
                      let isNotValidName = containsSpecialChars(event.target.value)
                      if (isNotValidName) {
                        toast.error('Name cannot contain special characters', { toastId: 'o' })
                      } else {
                        if (event.target.value.length > 30) {
                          toast.error('Name must be less than 30 characters. ', { toastId: 'o' })
                        } else {
                          setPayloadName(event.target.value.trimStart());
                        }
                      }
                    }}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter payload name.
                  </Form.Control.Feedback>
                </Col>
              </div>

              <div className="w-100">
                <Col className="mb-5">
                  <div className="d-flex gap-2 align-items-center w-100">
                    <div className=" w-32">
                      <Form.Label className="color-text">Length (cm) *</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter length"
                        value={payloadSizeX}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          event.preventDefault();
                          if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                            setPayloadSizeX(event.target.value);
                          }
                        }}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter length.
                      </Form.Control.Feedback>
                    </div>
                    <div className=" w-36">
                      <Form.Label className="color-text">Breadth (cm) *</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter breadth "
                        value={payloadSizeY}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          event.preventDefault();
                          if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                            setPayloadSizeY(event.target.value);
                          }
                        }}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter breadth.
                      </Form.Control.Feedback>
                    </div>
                    <div className=" w-32">
                      <Form.Label className="color-text">Height (cm) *</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter height"
                        value={payloadSizeZ}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          event.preventDefault();
                          if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                            setPayloadSizeZ(event.target.value);
                          }
                        }}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter height.
                      </Form.Control.Feedback>
                    </div>

                  </div>
                </Col>
              </div>

              <div className="w-100">
                <Col className="mb-5">
                  <Form.Label className="color-text">Peak Power Consumption (W) *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter peak power consumption (W)"
                    value={payloadPower}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(event) => {
                      event.preventDefault();
                      if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                        setPayloadPower(event.target.value);
                      }
                    }}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {payloadPower === '' ? 'Please enter peak power consumption (W).' : 'Peak power consumption must be greater than 0 (W).'}
                  </Form.Control.Feedback>
                </Col>
              </div>

              <div className="w-100">
                <Col className="mb-5">
                  <Form.Label className="d-flex w-100 align-items-center justify-content-between">Mass *
                    <div className="d-flex gap-2 align-items-center me-2">
                      <span style={{ color: massType === 'kg' ? '#CCF54E' : 'gray' }}>kg</span>
                      <AntSwitch
                        checked={massType === 'gm'}
                        color="success"
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        onChange={() => {
                          setMassType(massType === 'kg' ? 'gm' : 'kg')
                        }}
                        inputProps={{ 'aria-label': 'ant design' }} />
                      <span style={{ color: massType === 'gm' ? '#CCF54E' : 'gray' }}>gm</span>
                    </div>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder={"Enter payload mass " + `(${massType})`}
                    value={payloadMass}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(event) => {
                      event.preventDefault();
                      if (!isNaN(event.target.value) && event.target.value.length <= 5) {
                        setPayloadMass(event.target.value);
                      }
                    }}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {payloadMass === '' ? `Please enter payload mass  (${massType}).` : `Payload mass (${massType}) must be greater than 0.`}
                  </Form.Control.Feedback>
                </Col>
              </div>

              <div className="w-100">
                <Col className="mb-5">
                  <Form.Label className="color-text">Interfaces *</Form.Label>
                  {isNewInterface && (
                    <div className="d-flex w-100 gap-3 mb-5">
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter interface"
                        value={newInterface !== "" ? newInterface : ""}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          event.preventDefault();
                          if (event.target.value.length <= 10) {
                            setNewInterface(event.target.value);
                          }
                        }}
                      ></Form.Control>
                      <Button
                        variant="outline-primary"
                        type="button"
                        className="d-flex align-items-center btn btn-payload btn-outline-primary pt-1 pb-1 pe-4 ps-4 justify-content-center"
                        onClick={() => {
                          if (newInterface !== "") {
                            setPayloadInterFaces([
                              ...payloadInterFaces,
                              newInterface,
                            ]);
                            setAllPayloadInterFaces((prevState) => [
                              ...prevState,
                              newInterface,
                            ]);
                            setAllNewlyAddPayloadInterFaces((prevState) => [
                              ...prevState,
                              newInterface,
                            ]);
                            setIsNewInterface(false);
                            setNewInterface("");
                          } else {
                            setIsNewInterface(false);
                          }
                        }}
                      >
                        {newInterface !== '' ? 'Add New' : 'Cancel'}
                      </Button>
                    </div>
                  )}
                  <div className="d-flex flex-wrap w-100 gap-3">
                    {allPayloadInterFaces.map((data: any, i) => (
                      <div className="position-relative" key={i}>
                        <Button
                          key={data}
                          variant={
                            payloadInterFaces.includes(data)
                              ? "primary"
                              : "outline-danger"
                          }
                          type="button"
                          className={`d-flex align-items-center btn-payload btn ${payloadInterFaces.includes(data)
                            ? "btn-primary"
                            : "btn-outline-primary"
                            } pt-1 pb-1 pe-4 ps-4 fs-14`}
                          onClick={() => {
                            if (payloadInterFaces.includes(data)) {
                              setPayloadInterFaces(
                                payloadInterFaces.filter(
                                  (item) => item !== data
                                )
                              );
                            } else {
                              if (isValidate) {
                                setIsValidate(false);
                              }
                              setPayloadInterFaces([
                                ...payloadInterFaces,
                                data,
                              ]);
                            }
                          }}
                        >
                          {data}
                        </Button>
                        {allNewlyAddPayloadInterFaces.includes(data) && (
                          <span
                            className="remove-interface"
                            onClick={() => {
                              setAllPayloadInterFaces(
                                allPayloadInterFaces.filter(
                                  (item) => item !== data
                                )
                              );
                              setAllNewlyAddPayloadInterFaces(
                                allNewlyAddPayloadInterFaces.filter(
                                  (item) => item !== data
                                )
                              );
                              setNewInterface("");
                            }}
                          >
                            <i className="fe fe-x fs-14"></i>
                          </span>
                        )}
                      </div>
                    ))}
                    {!isNewInterface && (
                      <Button
                        variant="outline-primary"
                        type="button"
                        className="d-flex align-items-center btn btn-outline-primary pt-1 pb-1 pe-4 ps-4 justify-content-center"
                        onClick={() => {
                          setIsNewInterface(true);
                        }}
                      >
                        Add New
                      </Button>
                    )}
                  </div>
                  {isValidate && payloadInterFaces.length === 0 && (
                    <span className="text-danger d-flex pt-2">
                      Please select interface.
                    </span>
                  )}
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="navigator-btn gap-3">
        <Button
          variant="outline-danger"
          type="button"
          className="d-flex align-items-center btn btn-outline-danger pt-1 pb-1 pe-4 ps-4 fs-14"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <i className="fe fe-x me-1 fs-16"></i>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="button"
          className="d-flex align-items-center btn btn-primary pt-1 pb-1 pe-6 ps-6 fs-14"
          onClick={() => {
            checkValidation();
          }}
        >
          Create
        </Button>
      </div>

      <Dialog

        onClick={(event) => {
          if (event) {
            getAllCatalogs()
            dispatch(setIsNewPayloadCatelog(false));
          }
          setOpenModal(false)
        }}
        openModel={openModal} comment='Are you sure, you want to cancel ?'
      />
    </div>
  );
};

export default NewPayloadCatelog;
