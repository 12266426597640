import { useState, useEffect } from "react";

interface PayloadTelemetryProps{
    scenarioData: any
    configStep: string
    replaceValidationPanelURL: (data) => void
}

const PayloadTelemetry: React.FC<PayloadTelemetryProps> = ({scenarioData, replaceValidationPanelURL}) => {
    
    const [selectedPayload, setSelectedPayload] = useState<any>(null);

    // useEffect(()=>{
    //     if(scenarioData?.['grafanaData']?.['Urls']?.['PAYLOAD']){
    //         setSelectedPayload(Object.keys(scenarioData?.['grafanaData']?.['Urls']?.['PAYLOAD']?.['PayloadApp'])?.[0]);
    //     }
    // },[scenarioData])

    useEffect(() => {
        if (scenarioData?.['grafanaData']?.['Urls']?.['PAYLOAD']) {
            const payloadApp = scenarioData?.['grafanaData']?.['Urls']?.['PAYLOAD']?.['PayloadApp'];
            
            if (payloadApp && typeof payloadApp === 'object') {
                const payloadKeys = Object.keys(payloadApp);
                
                if (payloadKeys?.length > 0) {
                    setSelectedPayload(payloadKeys[0]); 
                }
            }
        }
    }, [scenarioData]);

 

    
    const disableEscaping = () => {
        window.addEventListener("blur", function (e) {
            setTimeout(function () {
                window.focus();
            }, 0);
        });
    }

    return (
        <div className="w-100 h-100 gap-3 d-flex flex-column">
            <div className="w-100 d-flex gap-3 justify-content-start">
                {selectedPayload && Object.keys(scenarioData?.['grafanaData']?.['Urls']?.['PAYLOAD']?.['PayloadApp'])?.length > 0 && Object.keys(scenarioData?.['grafanaData']?.['Urls']?.['PAYLOAD']?.['PayloadApp']).map((payload)=>{
                    return(
                        <button className={`d-flex bg-transparent p-0 align-items-center gap-2 ${selectedPayload === payload ? 'opacity-100' : 'opacity-50'}`}
                            // onClick={() => setSelectedPayload(payload)}
                        >
                            {/* {selectedPayload === payload && <img src={require('../../../../assets/images/svgs/roundChecked.svg').default} alt=""/>}
                            {selectedPayload !== payload && <img src={require('../../../../assets/images/svgs/roundUnChecked.svg').default} alt=""/>} */}
                            <span title={payload} className={`cursor-pointer`}>{payload?.length > 30 ? payload?.slice(0, 30) + '...' : payload}</span>
                        </button>
                    )
                })}
            </div>

            <div className="w-100 d-flex flex-wrap justify-content-between">
                {selectedPayload && scenarioData?.['grafanaData']?.['Urls']?.['PAYLOAD']?.['PayloadApp']?.[selectedPayload]?.map((item) => (
                    <div key={item?.panel_name} className={`simulate-grafana-panel mb-4 ${scenarioData?.['grafanaData']?.['Urls']?.['PAYLOAD']?.['PayloadApp']?.[selectedPayload]?.length > 3  ? 'w-49' : 'w-100'}`}>
                        <iframe src={`${process.env.PUBLIC_URL}${replaceValidationPanelURL(item?.url)}`} loading="lazy" onLoad={disableEscaping} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PayloadTelemetry