import ModalDropdown from './ModalDropdown';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MissionService from '../../../../../../service/MissionService';
import { useParams } from 'react-router-dom';
import { truncateNumber } from '../../../../../CommonComponents/CommonFunctions';

dayjs.extend(utc)

interface ScenarioScheduleProps {
  task_plan: string;
  start_time: any;
  duration: any;
  epoch: any;
  scenario_duration?: any;
  imported_schedule_data: any;
  set_new_simulation_time: (details: any) => void;
  set_scenario_task_plan: (details: any) => void;
  set_simulation_start_time: (date: any) => void;
  set_simulation_duration: (date: any) => void;
  set_imported_schedule: (data: any) => void;
  navigate_scenario: (data: string) => void;
  set_scenario_schedule_data: (data: any[]) => void;
  setUploaddFileStatus : any;
  data_submited: string[];
  scenario_step?: string;
  uploadFileStatus : any;
  setOpenTaskPlanMenu: (data)=> void
}

const ScenarioSchedule: React.FC<ScenarioScheduleProps> = ({task_plan, duration, start_time, epoch, scenario_duration, set_simulation_duration, set_simulation_start_time, set_scenario_schedule_data,
    set_scenario_task_plan, navigate_scenario, data_submited, set_new_simulation_time, scenario_step, set_imported_schedule, imported_schedule_data, setUploaddFileStatus, uploadFileStatus, setOpenTaskPlanMenu}) => {
    
    const params = useParams();
    const {
        sat_id,
    } = params;

    const [TaskPlans, setTaskPlans] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any>(null);

    const months = {
        '01': 0,
        '02': 1,
        '03': 2,
        '04': 3,
        '05': 4,
        '06': 5,
        '07': 6,
        '08': 7,
        '09': 8,
        '10': 9,
        '11': 10,
        '12': 11
    }

    const get_active_dates = () => {
        let start_scenario = new Date(epoch).toISOString();
        let end_scenario = new Date(new Date(epoch).getTime() + ((scenario_duration - duration) * 60000)).toISOString()
        let active_years: number[] = [];
        let active_months: { [year: number]: number[] } = {}
        let active_dates: { [year: number]: number[] } = {}
        let currentDate = new Date(start_scenario).getTime();
        while (currentDate <= new Date(end_scenario).getTime()) {
            let year = new Date(currentDate).toISOString().slice(0, 4);
            let day: any = new Date(currentDate).toISOString().slice(8, 10);
            let month: any = new Date(currentDate).toISOString().slice(5, 7);
            day = day[0] === '0' ? parseInt(day[1]) : parseInt(day);
            if (!active_years.includes(parseInt(year))) {
                active_years.push(parseInt(year));
            }
            if (!active_dates[parseInt(months[month])]) {
                active_dates[parseInt(months[month])] = [];
            }
            if (!active_months[parseInt(year)]) {
                active_months[parseInt(year)] = [];
            }
            if (!active_dates[parseInt(months[month])].includes(day)) {
                active_dates[parseInt(months[month])].push(day);
            }
            if (!active_months[parseInt(year)].includes(months[month])) {
                active_months[parseInt(year)].push(months[month])
            }

            currentDate += (86400000 - (duration * 60000));
        }
        return { active_years, active_months, active_dates }
    }

    const update_simulation_time = (dateTime) => {
        set_new_simulation_time(dateTime)
        try {
            let scenario_start_time = new Date(epoch).getTime()
            let scenario_end_time = new Date(epoch).getTime() + (scenario_duration * 60000)
            let simulation_time = new Date(dateTime).getTime()
            let simulation_duration = duration === '' ? 10 : parseInt(duration) * 60000
            if (simulation_time >= scenario_start_time && simulation_time <= scenario_end_time) {
                if ((simulation_time + simulation_duration <= scenario_end_time)) {
                    set_simulation_start_time(dateTime)
                }
            }
        } catch (error: any) {
            toast.error(error.message, { toastId: "error" });
        }
    }

    const get_all_task_plans = () => {
        MissionService.getAllTaskPlan(sat_id)?.then((response) => {
            if (response?.data) {
                let alltaskPlans: any = [];
                response?.["data"]?.length > 0 &&
                    response?.["data"]?.forEach(taskPlan => {
                        alltaskPlans.push({
                            label: taskPlan?.["taskPlanName"],
                            value: taskPlan
                        });
                        if (task_plan !== '' && taskPlan?.["taskPlanId"] === task_plan) {
                            setSelectedOption({
                                label: taskPlan?.["taskPlanName"],
                                value: taskPlan
                            })
                        }
                    })
                setTaskPlans(alltaskPlans);
            }
        }).catch((err) => {
            console.log("Error in getting taskplans");
        });
    }

    const upload_json_data = () => {
        setUploaddFileStatus()
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.onchange = (event: any) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const uploadFile = e.target.result;
                    // Do something with the file content
                    const blob = new Blob([uploadFile], { type: "multipart/form-data" });
                    const file_data = new FormData();
                    file_data.append("file", blob, file.name);
                    // setUploadedFile({
                    //     file_data: file_data,
                    //     name: file.name
                    // });
                    set_imported_schedule({
                        file_data: file_data,
                        name: file.name,
                        schedule_upload: false,
                        schedule_imported: true,
                        show_upload_btn: true,
                        show_deploy_btn: false
                    })
                };
                reader.readAsText(file);
            } else {
                toast.error("Invalid file format")
            }
        };
        fileInput.click();
    }

    useEffect(()=>{
        get_all_task_plans();
    }, [])

    useEffect(() => {
        if (task_plan !== '' && TaskPlans?.length > 0) {
            TaskPlans?.map((taskPlan) => {
                if (taskPlan?.value?.["taskPlanId"] === task_plan) {
                    setSelectedOption({
                        label: taskPlan?.value?.["taskPlanName"],
                        value: taskPlan?.value
                    })
                }
            })
        }
    }, [task_plan])

    const change_duration = (event) => {
        event.preventDefault()
        const scenario_end_time = new Date(epoch).getTime() + (scenario_duration  * 60000)
        if(Number(event.target.value) <= (scenario_duration / 12) && Number(event.target.value) >= 0){
            const simulation_end_time =  new Date(start_time).getTime()  + (Number(event.target.value) * 60000)
            if(simulation_end_time <= scenario_end_time && !isNaN(event.target.value)){
                let simulationDoration = Math.abs(+event.target.value)
                set_simulation_duration(event.target.value !== '' ? simulationDoration : '')
            }else{
                let remaining_time = truncateNumber((((scenario_end_time / 1000) - (new Date(start_time).getTime() / 1000)) / 60), 0)
                toast.error(`Duration should be less than or equal to ${remaining_time}.`,{toastId:'o'})
            }
        }else{
            toast.error(`Duration should be between 10 and ${scenario_duration/12} minutes`,{toastId:'o'})
        }
    }


    return (
        <Fragment>
            <div className="modalBoxShadowDiv">
                <button
                    onClick={() => {
                        if (data_submited?.includes('Schedule')) {
                            navigate_scenario('Schedule')
                        }
                    }}
                    className={`modalCheckboxDiv bg-transparent ${!data_submited?.includes('Schedule') ? "cursor-auto" : ''}`}>
                    <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
                    <div className={`modalDropDownIconBtn ${!data_submited?.includes('Schedule') ? 'disabledBtn' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d={scenario_step === 'Schedule' ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                                stroke="#F0F0F0"
                                strokeWidth="1.25"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    <div className='flex-1 gap-5 d-flex align-items-center justify-content-between'>
                        <div className={!data_submited?.includes('Schedule') ? "modalCheckboxText modalWhiteSubHeader disabledBtn" : "modalCheckboxText modalWhiteSubHeader"}>Schedule</div>
                        {data_submited?.length >= 5 && <div className={`d-flex ${data_submited?.includes('Schedule') ? 'pe-8 position-absolute right-0' : 'pe-6'} align-items-center cursor-pointer`} onClick={upload_json_data}>
                            <img src={require("../../../../../../assets/images/svgs/import.svg").default} />
                            <span>Import</span>
                        </div>}
                        {(data_submited?.includes('Schedule') && data_submited?.length === 6) && <img className="greenTickIcon" src={require("../ImageSvgs/GreenTickIcon.svg").default} />}
                    </div>
                </button>
            </div>

            {!imported_schedule_data?.['schedule_imported'] && 
                <Fragment>
                    {scenario_step === 'Schedule' && (
                        <div className='d-flex flex-column gap-2 px-4 pb-3'>
                            <div className='modalCheckboxText modalWhiteSubHeader'>Task Plan</div>
                            <ModalDropdown 
                                setOpenTaskPlanMenu={(data)=>setOpenTaskPlanMenu(data)}
                                options={TaskPlans} 
                                selectedOption={selectedOption}
                                handleSelectedOption={(e)=>{
                                    set_scenario_task_plan(e.value?.['taskPlanId'])
                                    setSelectedOption(e)
                                }} 
                                placeholder='Task plan'
                            />
                        </div>
                    )}
                </Fragment>
            }
            
            {imported_schedule_data?.['schedule_imported'] && scenario_step === 'Schedule' && 
                <div className='d-flex flex-column gap-2 px-4 pb-3'>
                    <div className='modalCheckboxText modalWhiteSubHeader'>Imported Schedule</div>
                    <div className='w-100 py-2 bg-black border d-flex align-items-center justify-content-between rounded-2 text-truncate'>
                        <span className='w-90'>{imported_schedule_data?.['name']}</span>
                        <button className='p-2 bg-transparent d-flex align-items-center justify-content-center'
                            onClick={()=>{
                                set_imported_schedule({
                                    file_data: null,
                                    name: '',
                                    schedule_upload: false,
                                    schedule_imported: false,
                                    show_upload_btn: false,  
                                    show_deploy_btn: false,  
                                })
                                set_scenario_schedule_data([])
                            }}
                        >
                            <i className='fe fe-x fs-18'/>
                        </button>
                    </div>
                    <span style={{color: "red", font : "10px"}}> {uploadFileStatus} </span>
                </div>
            }

                       
            {scenario_step === 'Schedule' && (
                <div className='d-flex gap-1 px-4 pb-3 w-100 align-items-center'>
                    <div className='d-flex gap-2 flex-column w-75'>
                        <div className='modalCheckboxText modalWhiteSubHeader'> Simulation Start Time</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DateTimePicker className="w-100" ampm={false} format='YYYY-MM-DD HH:mm:ss'
                                
                                shouldDisableDate={(date) => {
                                    const { active_dates } = get_active_dates()
                                    const current_month = dayjs(date).month();
                                    const current_day = dayjs(date).date();
                                    return active_dates[current_month] && !active_dates[current_month].includes(current_day)
                                }}
                                shouldDisableTime={(date) => {
                                    const simulation_duration = (!duration || duration == '') ? 10 : duration
                                    const sixHoursLater = dayjs(epoch).add((scenario_duration - simulation_duration) / 60,  'hours');
                                    return dayjs(date).isAfter(sixHoursLater) || dayjs(date).isBefore(epoch);
                                }}
                                shouldDisableMonth={(date) => {
                                    const current_year = dayjs(date).year();
                                    const current_month = dayjs(date).month();

                                    const { active_months } = get_active_dates()
                                    return active_months[current_year] && !active_months[current_year]?.includes(current_month)
                                }}

                                shouldDisableYear={(date) => {
                                    const current_year = dayjs(date).year();
                                    const { active_years } = get_active_dates()
                                    return !active_years.includes(current_year);
                                }}
                                views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                value={dayjs.utc(start_time)} onChange={update_simulation_time}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='d-flex gap-2 flex-column w-25'>
                        <div className='w-100 modalCheckboxText modalWhiteSubHeader'>Duration</div>
                        <div className='w-100 scenario__time__input__container'>
                            <input className='w-55 scenario__time__input' type='text' value={duration} onChange={change_duration} />
                            <span className='w-45'>Mins</span>
                        </div>
                    </div>
                </div>
            )}
            
        </Fragment>
    )
}

export default ScenarioSchedule
