// @ts-nocheck
import React, { Fragment, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { RouterUrl } from './Routes';

import Loader from './Layouts/Loader/Loader';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import keycloak from "./keycloak/keycloak";
import jwt_decode from "jwt-decode";



import * as Cesium from 'cesium';
import UserService from './service/UserService';
// TODO: environment variable for the token.
Cesium.Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_TOKEN;
const TenantAdmin = lazy(() => import('./components/Missions/AdminPanel/TenantAdmin'));
const SuperAdmin = lazy(() => import('./components/Missions/AdminPanel/SuperAdmin'));
const App = lazy(() => import('./components/app'));
const container: HTMLElement | any = document.getElementById('root');
const root = createRoot(container);

//Initialization of the keycloak instance
let tokenData:any = {}
let userData:any = {}

    keycloak.init({
        onLoad: 'login-required',
        pkceMethod: "S256"
    }).then(async (authenticated) => {
        
        if (!authenticated) {
            window.location.reload();
        } else {
            //store authentication tokens in sessionStorage
            let token = keycloak?.token ? keycloak.token : '';

            // New token with resources, permissions and scopes
            if (token) {
                UserService.getToken(token)?.then(newToken => {
                    const { access_token, refresh_token }= newToken.data
                    sessionStorage.setItem('authentication', access_token);
                    sessionStorage.setItem('refreshToken', refresh_token);
                    if(sessionStorage.getItem('userDetails')){
                        userData = JSON?.parse(sessionStorage.getItem('userDetails'));
                    }
                    // New token decoded 
                    const decoded = jwt_decode(access_token);
                    
                    if(decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles']?.includes('super_admin')){
                        tokenData = {
                            ...decoded,
                            ...userData,
                            'user_roles': decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles'],
                            'url': userData?.['owner_org'] && userData?.['owner_org'] !== '' ? userData?.['url'] : `/super_admin_dashboard`
                        };
                        sessionStorage.setItem('userDetails', JSON?.stringify(tokenData));
                    }else{
                        let owner_org_list = (decoded?.ownerOrgList && decoded?.ownerOrgList !== '') ? JSON?.parse(decoded?.ownerOrgList) : []
                        let user_roles = []
                        if(decoded?.['owner_org'] && decoded?.['owner_org'] !== ''){
                            owner_org_list.forEach(org => {
                                if(decoded?.['owner_org'] === org?.['owner_org']){
                                    user_roles = [org?.['role']]
                                }
                            })
                        }else{
                            user_roles = decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles']
                        }
                        tokenData = {
                            ...decoded,
                            ...userData,
                            'user_roles': user_roles,
                            'url': userData?.['owner_org'] && userData?.['owner_org'] !== '' ? userData?.['url'] : `/user/${decoded?.['name']}/${decoded?.['sub']}`
                        };
                        sessionStorage.setItem('userDetails', JSON?.stringify(tokenData));
                    }
                    root.render(
                        <Fragment >
                            <BrowserRouter >
                                <React.Suspense fallback={<Loader/>}>
                                    <Routes>
                                        <Route element={<App/>}>
                                            {RouterUrl.map((data) => {
                                                return <Route key={data.id} path={`${process.env.PUBLIC_URL}` + data.url}
                                                    element={<data.component/>}/>
                                            })}
                                            {tokenData?.['user_roles']?.includes('super_admin') && 
                                                <Route path={`${process.env.PUBLIC_URL}/super_admin_dashboard`} element={<SuperAdmin/>}/>
                                            }
                                            <Route path={`${process.env.PUBLIC_URL}/settings`} element={<TenantAdmin/>}/>
                                            <Route path={`${process.env.PUBLIC_URL}/*`} element={<Navigate to={`${process.env.PUBLIC_URL}${tokenData?.['url']}`}/>}/> : 
                                        </Route>
                                    </Routes>
                                </React.Suspense>
                            </BrowserRouter>
                        </Fragment>
                    );
                }).catch(err => {
                    console.error(err);
                })
            }
        }

    //to regenerate token on expiry
     let tokenExp = keycloak.tokenParsed?.exp ? keycloak.tokenParsed.exp : 0;
     let timeSkew = keycloak.timeSkew ? keycloak.timeSkew : 0;
     
     setInterval(() => {
         keycloak.updateToken(50).then(async (refreshed) => {
             if (refreshed) {
                let token = keycloak?.token ? keycloak.token : '';
                if (token) {
                    UserService.getToken(token)?.then(newToken => {
                        const { access_token, refresh_token }= newToken.data
                        sessionStorage.setItem('authentication', access_token);
                        sessionStorage.setItem('refreshToken', refresh_token);
                        if(sessionStorage.getItem('userDetails')){
                            userData = JSON?.parse(sessionStorage.getItem('userDetails'));
                        }
                        // New token decoded 
                        const decoded = jwt_decode(access_token);
                        if(decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles']?.includes('super_admin')){
                            tokenData = {
                                ...decoded,
                                ...userData,
                                'user_roles': decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles'],
                                'url': userData?.['owner_org'] && userData?.['owner_org'] !== '' ? userData?.['url'] : `/super_admin_dashboard`
                            };
                            sessionStorage.setItem('userDetails', JSON?.stringify(tokenData));
                        }else{
                            let owner_org_list = (decoded?.ownerOrgList && decoded?.ownerOrgList !== '') ? JSON?.parse(decoded?.ownerOrgList) : []
                            let user_roles = []
                            if(decoded?.['owner_org'] && decoded?.['owner_org'] !== ''){
                                owner_org_list.forEach(org => {
                                    if(decoded?.['owner_org'] === org?.['owner_org']){
                                        user_roles = [org?.['role']]
                                    }
                                })
                            }else{
                                user_roles = decoded?.['resource_access']?.['ATMOS-RESOURCE-SERVER']?.['roles']
                            }
                            tokenData = {
                                ...decoded,
                                ...userData,
                                'user_roles': user_roles,
                                'url': userData?.['owner_org'] && userData?.['owner_org'] !== '' ? userData?.['url'] : `/user/${decoded?.['name']}/${decoded?.['sub']}`
                            };
                            sessionStorage.setItem('userDetails', JSON?.stringify(tokenData));
                        }
                    }).catch(err => {
                        console.error(err);
                    })
                }
             } else {
                 console.warn('Token not refreshed, valid for '
                     + Math.round(tokenExp + timeSkew - new Date().getTime() / 1000) + ' seconds');
             }
         }).catch(() => {
             console.error('Failed to refresh token');
         });

     }, 300000)
 }).catch(() => {
     console.error("Authenticated Failed");
 });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

