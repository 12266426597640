import React from "react";

interface ModalHeaderProps {
  setisModalVisible: () => void;
  close: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ setisModalVisible, close}) => {

    const header = {
      title: "Scenario Creation",
      img: require("../ImageSvgs/ScenarioCreationIcon.svg").default,
    };

    return (
      <div className="modalHeader">
        <div className="modalHeaderImg">
          <img
            src={header.img}
            className="active-img"
          />
        </div>
        <span>{header.title}</span>
        <button
          className="modalCloseBtn"
          onClick={() => {
            close()
          }}
        >
          <img src={require("../ImageSvgs/CloseButtonWhite.svg").default} />
        </button>
        <button
          className="modalCloseBtn modalMinimizeBtn"
          onClick={() => {
            setisModalVisible()
          }}
        >
          <img src={require("../ImageSvgs/MinimizeButtonWhite.svg").default} />
        </button>
      </div>
    );
  };
  
  export default ModalHeader;
  