import React, { useEffect, useState } from "react";
import * as Cesium from "cesium";
import { useParams } from "react-router-dom";
import MissionService from "../../../../../../service/MissionService";


const ScenarioOrbit = ({
  scenario_scheduled,
  set_scenario_scheduled,
  ground_stations,
  selected_ground_station,
  scenario_response_data,
  set_loading,
  show_manual_gs,
  set_show_manual_gs
}) => {

  const params = useParams();
  const {
    truetwin_id,
    scenario_id,
    scenario_step,
  } = params;

  const [viewer, setViewer] = useState(null)
  const [gsRendered, setGsRendered] = useState(false)
  
  const fetchOrbitData = (viewer) => {
    if(scenario_id && scenario_id !== undefined && truetwin_id && truetwin_id !== undefined){
      resetPosition(viewer, 30000000)
      viewer?.dataSources?.removeAll()
      const propagation = false
      MissionService.getOribtData(scenario_id, truetwin_id, propagation)?.then((res) => {
        if (res?.data) {
          viewer.dataSources.add(Cesium.CzmlDataSource.load(res.data))
          if(!scenario_response_data?.['groundStations']){
            add_ground_stations_to_viewer(viewer)
          }else{
            resetPosition(viewer, 35500000)
            setTimeout(() => {
              set_loading(false)
            },[3000])
          }
          set_scenario_scheduled()
        }
      }).catch((err) => {
        console.log(err)
        set_scenario_scheduled()
        if(!scenario_response_data?.['groundStations']){
          add_ground_stations_to_viewer(viewer)
        }else{
          set_loading(false)
        }
      })
    }
  };

  const add_ground_stations_to_viewer = (viewer) => {
    if(!gsRendered || scenario_step === "Physical_Characteristics" || scenario_step ===  "Ground_Station"){
      let stationsSource = new Cesium.CustomDataSource('all_gs_stations');
      if(stationsSource){
        setGsRendered(true)
        ground_stations.forEach(station => {
          stationsSource.entities.add({
            name: station.groundStationName,
            label: {
              text: station.groundStationName,
              font: '14px montserrat',
              fillColor: Cesium.Color.WHITE,
              outlineColor: Cesium.Color.BLACK,
              horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              pixelOffset: new Cesium.Cartesian2(20, 0),
              outlineWidth: 2,
              style: Cesium.LabelStyle.FILL_AND_OUTLINE
            },
            position: Cesium.Cartesian3.fromDegrees(
              Number(station?.properties?.longitude), 
              Number(station?.properties?.latitude)
            ),
            billboard: {
              image: '/assets/select_gs.svg',
              heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
            }
          });  
  
        });
        viewer.dataSources?.add(stationsSource);
        if(scenario_step === "Physical_Characteristics" || scenario_step ===  "Ground_Station"){
          resetPosition(viewer, 20000000)
        }
        if(show_manual_gs){
          set_show_manual_gs(false)
        }
      }
    }
    setTimeout(() => {
      set_loading(false)
      resetPosition(viewer, 35500000)
    },[(scenario_step ===  "Ground_Station" || scenario_step === "Physical_Characteristics" ) ? 5000 : 3000])
  }

  const resetPosition = (viewer, position) => {
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(0, 0, position)
    });
  }

  let imaginary = new Cesium.MapboxStyleImageryProvider({
    styleId: 'satellite-v9',
    accessToken:  process.env.REACT_APP_MAPBOX_TOKEN
});

  useEffect(() => {
    if(viewer === null){
      let cesium_viewer = (new Cesium.Viewer("scenariocesiumContainer", {
        requestRenderMode: true,
        shouldAnimate: true,
        animation: false, // Disable the default animation widget
        baseLayerPicker: false, // Disable the default base layer picker
        timeline: false, // Disable the timeline
        geocoder: false, // Disable the geocoder
        homeButton: false, // Disable the home button
        navigationHelpButton: false, // Disable the navigation help button
        sceneModePicker: false, // Disable the scene mode picker
        selectionIndicator: false, // Disable the selection indicator
        fullscreenButton: false,
        imageryProvider: imaginary,
      }));

      if (imaginary){
        cesium_viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
      }

      cesium_viewer.scene.morphTo2D(0)
      cesium_viewer.scene.globe.showGroundAtmosphere = true;
      cesium_viewer.clock.currentTime = cesium_viewer.clock.startTime;
      cesium_viewer.clock.shouldAnimate = true;
      cesium_viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
      cesium_viewer.scene.screenSpaceCameraController.enableRotate = true;
      cesium_viewer.scene.screenSpaceCameraController.enableZoom = true;
      cesium_viewer.scene.screenSpaceCameraController.enableTilt = true;
      cesium_viewer.scene.screenSpaceCameraController.enableLook = true;
      cesium_viewer.scene.highDynamicRange = false
      
      resetPosition(cesium_viewer, 30000000)
      setViewer(cesium_viewer)
    }
    
    return () => {
      if(viewer){
        try {
          viewer.dataSources.removeAll();
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, [viewer]);

  useEffect(() => {
    if(viewer && viewer !== null && scenario_scheduled){
      fetchOrbitData(viewer);
    }
  },[scenario_scheduled, viewer])
  
  useEffect(() => {
    if (viewer && viewer !== null && ground_stations?.length > 0 &&  show_manual_gs) {
      add_ground_stations_to_viewer(viewer)
    }
  },[ground_stations, viewer, show_manual_gs])

  return (
    <div id="scenariocesiumContainer" className="d-flex h-100 w-100" />
  );
};

export default ScenarioOrbit;


