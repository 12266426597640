import React, { Fragment } from 'react'
import ModalDropdown from './ModalDropdown';
import { Form } from 'react-bootstrap';

interface ScenarioGroundStationProps {
  all_ground_stations: any[];
  ground_stations: any[];
  set_scenario_ground_stations: (stations: any) => void;
  navigate_scenario: (data: string) => void;
  data_submited: string[];
  true_twin: Object;
  scenario_step?: string;
}

const ScenarioGroundStation: React.FC<ScenarioGroundStationProps> = ({all_ground_stations, ground_stations, set_scenario_ground_stations, navigate_scenario, true_twin, scenario_step, data_submited}) => {
  return (
    <Fragment>
      <div className="modalBoxShadowDiv">
        <button 
          onClick={() =>{
            if(data_submited?.includes('Ground_Station')){
              navigate_scenario('Ground_Station')
            }
          }}
          className={`modalCheckboxDiv bg-transparent ${!data_submited?.includes('Ground_Station') ? "disabledBtn" : ''}`}>
        <input type="checkbox" name="checkboxName" id="checkboxId" className="modalCheckbox" />
        <div className={"modalDropDownIconBtn"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d={scenario_step === 'Ground_Station' ? "M6.01108 8.97725L11.9879 15.0003L18.011 9.02344" : "M8.9775 17.9887L15.0005 12.0118L9.02368 5.98877"}
                stroke="#F0F0F0"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            </svg>
        </div>
        <div className={!data_submited?.includes('Ground_Station') ? "modalCheckboxText modalWhiteSubHeader disabledBtn" : "modalCheckboxText modalWhiteSubHeader"}>Ground Stations</div>
          {data_submited?.includes('Ground_Station') && <img className="greenTickIcon" src={require("../ImageSvgs/GreenTickIcon.svg").default} />}
        </button>
    </div>

    {scenario_step === 'Ground_Station' && (
        <div style={{ margin: "12px" }} className='d-flex flex-column gap-3'>
          {all_ground_stations?.map(gs => (
            <div className='d-flex align-items-center justify-content-between ps-5' key={gs?.['groundStationId']}>
              <div className='d-flex gap-3'>
                <Form.Check
                  type="checkbox"
                  className="h-5 cursor-pointer"
                  checked={ground_stations.includes(gs?.["groundStationId"])}
                  onKeyDown={(e)=>{
                    if(e.keyCode === 13){
                        e.preventDefault();
                    }
                  }}
                  onChange={() =>{
                    if(ground_stations.includes(gs?.["groundStationId"])){
                      set_scenario_ground_stations(ground_stations.filter(id => id !== gs?.["groundStationId"]))
                    }else{
                      set_scenario_ground_stations([...ground_stations, gs?.["groundStationId"]])
                    }
                  }}
                /> 
                <div title={gs?.['groundStationName']}>{gs?.['groundStationName']?.length > 15 ? gs?.['groundStationName']?.substring(0, 15) + '...' : gs?.['groundStationName']}</div>
              </div>
              {/* <button disabled className='btn bg-transparent border-0 p-0'>
                  <i className='fe fe-eye'/>
              </button> */}
            </div>
          ))}
        </div>
    )}
    </Fragment>
  )
}

export default ScenarioGroundStation
