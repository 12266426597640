// @ts-nocheck
import React, { useEffect, useState } from "react";
import PageHeader from "../../../../../Layouts/PageHeader/PageHeader";
import TemplateSlider from "./TemplateSlider";
import { Button, Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    MissionStore,
    setShowTemplateSlideToTrue,
    setShowTemplateDataTrue,
    setShowTemplateDataFalse,
    setIsNewTemplateTrue,
    setCloneTemplate,
    setIsNewTemplateFalse,
    setTemplate,
    setShowTemplateSlideToFalse,
} from "../../../../../store/MissionSlice";
import {
    usageStore,
} from "../../../../../store/UsageSlice";
import CatalogueService from "../../../../../service/CatalogueService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

////////////...................................//////////////////
import Mui_Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Mui_Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
////////////...................................//////////////////

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box } from "@mui/material";
import { calculateEdgeUsage, calculatePayloadUsage, calculateUsagePercentage, calculate_Total_AOP_Usage, navigateTo, truncateNumber } from "../../../../CommonComponents/CommonFunctions";
import { Steps } from "../../Stepper.constants";
import CloseButton from "../../../../CommonComponents/CloseButton";
import MissionService from "../../../../../service/MissionService";
import Dialog from "../../../../CommonComponents/Dialog";
import CreateBusModel from "../Bus/CreateBusModel";
import Loader from "../../../../../Layouts/Loader/Loader";
////////////...................................//////////////////


const SelectTemplate = () => {

    const params = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mission_name, sat_name, mission_id, sat_id, template_id } = params

    const [espaTempData, setEspaTempData] = useState({
        type: '',
        value: '',
    })
    const [templateList, setTemplateList] = useState([]);
    const [templateDataAvailable, setTemplateDataAvailable] = useState(false);
    const [matchTemplate, setmatchTemplate] = useState([]);
    const [remainingTemplate, setremainingTemplate] = useState([]);
    const [blankTemplateData, setBlankTemplateData] = useState({});

    const [showBtnGroup, setShowBtnGroup] = useState('');

    const [openDeleteModel, setOpenDeleteModal] = React.useState(false);

    const payloadSizePercent = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadPerCent.size
    );
    const payloadWtPercent = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadPerCent.weight
    );
    const payloadPowerPercent = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadPerCent.power
    );
    const edgeSizeUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgeSizeUsage
    );
    const edgeWeightUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgeWeightUsage
    );
    const edgePowerUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.edgePowerUsage
    );

    const edgeSizePercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.size);
    const edgeWtPercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.weight);
    const edgePowerPercent = useSelector((state: { usage: usageStore }) => state.usage.edgePerCent.power);


    const payloadSizeUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadSizeUsage
    );
    const payloadWeightUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadWeightUsage
    );
    const payloadPowerUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.payloadPowerUsage
    );
    const busSizeUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.busSizeUsage
    );
    const busWeightUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.busWeightUsage
    );
    const busPowerUsage = useSelector(
        (state: { usage: usageStore }) => state.usage.busPowerUsage
    );
    const busSizePercent = useSelector(
        (state: { usage: usageStore }) => state.usage.busPerCent.size
    );
    const busWtPercent = useSelector(
        (state: { usage: usageStore }) => state.usage.busPerCent.weight
    );
    const busPowerPercent = useSelector(
        (state: { usage: usageStore }) => state.usage.busPerCent.power
    );


    const userData = useSelector((state: { mission: MissionStore }) => state.mission.userData);


    const [satellieteDeta, setSatellieteData] = useState<any>({});
    const [selectedTemplateId, setSelectedTemplateId] = useState<any>('');

    const isTemplateSelected = useSelector(
        (state: { mission: MissionStore }) => state.mission.isTemplateSelected
    );
    const isTemplateData = useSelector(
        (state: { mission: MissionStore }) => state.mission.isTemplateData
    );

    const isNewTemplate = useSelector(
        (state: { mission: MissionStore }) => state.mission.isNewTemplate
    );

    //////////////////.............................////////////////

    const [errorMessg, setErrorMesg] = useState<any>("");
    const [Loading, setLoading] = useState(true);
    const [openCloneDialogue, setOpenCloneDialogue] = React.useState(false);
    const [templateName, setTemplateName] = React.useState<any>('');
    const [defaultTemplateName, setDefaultTemplateName] = React.useState<any>('');
    const [busType, setBusType] = React.useState('3U');
    const [hover, setHover] = useState(true)
    const [showPercent, setShowPercent] = useState(false)


    const getTemplates = () => {
        const response = CatalogueService.getAllTemplates();
        response?.then((data) => {
            if (data.data) {
                setTemplateList(data.data);
                setTemplateDataAvailable(true);
                setBlankTemplateData(data.data?.find((t) => t["name"] === "Blank")[0])
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getTemplates()
    }, [])

    useEffect(() => {
        if (sat_id && templateList?.length > 0) {
            MissionService.get_satellite_by_sat_id(sat_id)?.then((sat) => {
                if (sat?.data) {
                    let all_payloas = sat?.data?.['versions']?.[0]?.['assembly']?.['Payloads'] ? sat?.data?.['versions']?.[0]?.['assembly']?.['Payloads'] : []
                    let all_edges = sat?.data?.['versions']?.[0]?.['assembly']?.['Edge'] ? sat?.data?.['versions']?.[0]?.['assembly']?.['Edge'] : []
                    setSatellieteData(sat.data)
                    calculate_Total_AOP_Usage(all_payloas, all_edges, dispatch)
                    calculatePayloadUsage(all_payloas, dispatch)
                    calculateEdgeUsage(all_edges, dispatch)
                    if (sat.data?.["versions"]?.[0]?.["assembly"]?.["Template"] && sat.data?.["versions"]?.[0]?.["assembly"]?.["Template"] !== '') {
                        let templateId = sat.data?.["versions"]?.[0]?.["assembly"]?.["Template"]
                        let selectTemplate = templateList?.find((t) => t?.["uniqueId"] === templateId);
                        if ((sat.data?.['swap-info']?.['totalPayloadSize'] + Number(selectTemplate?.["template-attribute"]?.["bus-size"]) < Number(selectTemplate?.["template-attribute"]?.["sizeInUnits"]))) {
                            onAddTemplate(selectTemplate);
                        }
                    }
                    if (template_id && template_id !== undefined) {
                        setSelectedTemplateId(template_id);
                        let selectedTemplate = templateList?.find((t) => t?.["uniqueId"] === template_id);
                        if ((sat.data?.['swap-info']?.['totalPayloadSize'] + Number(selectedTemplate?.["template-attribute"]?.["bus-size"]) < Number(selectedTemplate?.["template-attribute"]?.["sizeInUnits"]))) {
                            onAddTemplate(selectedTemplate);
                        }
                    }
                }
            }).catch((err) => {
                console.log('Some thing went wrong while getting satellite');
            })
        }
    }, [sat_id, templateList])

    useEffect(() => {
        if (payloadSizeUsage > 0 && templateList?.length > 0) {
            getMatchFromTemplateList(templateList);
        }
    }, [payloadSizeUsage, payloadPowerUsage, templateList])

    const update_satellite = () => {
        setLoading(true)
        let groundLink = [
            {
                "type": "system",
                "name": "Teledata",
                "primaryRadioModule": {}
            },
            {
                "type": "system",
                "name": "TT&C",
                "primaryRadioModule": {}
            }
        ]
        let sat = {
            ...satellieteDeta,
            name: satellieteDeta?.['name'],
            draftMode: 'No',
            'groundLink': ((template_id && template_id !== undefined) && (template_id === selectedTemplateId)) ? satellieteDeta?.['groundLink'] : groundLink,
            "containing-mission": satellieteDeta?.['containing-mission'],
            versions: [
                {
                    assembly: {
                        'Payloads': satellieteDeta?.['versions']?.[0]?.['assembly']?.['Payloads'],
                        'Edge': satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge'],
                        'Template': selectedTemplateId,
                    },
                },
            ],
            "swap-info": {
                totalUsedSize: truncateNumber((payloadSizeUsage + edgeSizeUsage + busSizeUsage), 1),
                totalUsedMass: truncateNumber((payloadWeightUsage + edgeWeightUsage + busWeightUsage), 1),
                totalUsedPower: truncateNumber((
                    Number(payloadPowerUsage) +
                    Number(edgePowerUsage) +
                    Number(busPowerUsage)
                ), 1),
                totalUsedSizePercent: truncateNumber((payloadSizePercent + edgeSizePercent + busSizePercent), 1),
                totalUsedMassPercent: truncateNumber((payloadWtPercent + edgeWtPercent + busWtPercent), 1),
                totalUsedPowerPercent: truncateNumber((payloadPowerPercent + edgePowerPercent + busPowerPercent), 1),
                totalPayloadSize: truncateNumber(payloadSizeUsage, 1),
                totalPayloadMass: truncateNumber(payloadWeightUsage, 1),
                totalPayloadPower: truncateNumber(payloadPowerUsage, 1),
            },
        };
        MissionService.update_satellites(sat_id, sat)?.then((res) => {
            if (res.data) {
                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/review_satellite`, navigate)
            }
            setLoading(false)
        }).catch((err) => {
            toast.error("Something went wrong while adding payload ", { toastId: 'o' });
            setLoading(false)
        });
    }




    const handle_submit = () => {
        if (templateName !== '') {

            if (isNewTemplate) {
                const temp = templateList.filter(template => template?.['template-attribute']?.['bus-type'] === busType)
                const data = {
                    'busType': busType,
                    'templateName': templateName,
                    'template-attribute': temp[0]?.['template-attribute']
                }
                dispatch(setCloneTemplate({ ['Other']: data }))
                navigateTo(`${mission_name}/${mission_id}/view_satellite/${sat_name}/${sat_id}/configure`, navigate)
                setOpenCloneDialogue(false);
            }
            if (openCloneDialogue) {
                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/clone_bus_design/${templateName}/${selectedTemplateId}`, navigate)
                setOpenCloneDialogue(false);
            }
        } else {
            toast.error('Please enter bus design name', { toastId: 'o' })
        }
    };

    const handle_close = () => {
        setOpenCloneDialogue(false)
        setTemplateName('')
        setDefaultTemplateName('')
    }


    //////////////////.............................////////////////


    const getTemplateErrorMsg = () => {
        setmatchTemplate([]);
        setremainingTemplate([]);
        dispatch(setShowTemplateDataFalse());
        setErrorMesg(
            "There are no bus designed for your configuration. Please select a different configuration."
        );
        setLoading(false)
    }

    const getMatchFromTemplateList = (temp: any[]) => {
        const totalSize: number = edgeSizeUsage + payloadSizeUsage
        const totalWeight: number = edgeWeightUsage + payloadWeightUsage
        const totalPower: number = edgePowerUsage + payloadPowerUsage


        const sizeNotMatch = temp?.filter((t) => t?.["template-attribute"]?.["sizeInUnits"] === 0)
        const sizeMatch = temp?.filter((t) => {
            return (
                t["name"] !== "Blank" &&
                totalSize + Number(t?.["template-attribute"]?.["bus-size"]) < t?.["template-attribute"]?.["sizeInUnits"]
            );
        });

        const totalSizeMatchTemp = [...sizeMatch, ...sizeNotMatch]

        if (totalSizeMatchTemp.length === 0) {
            getTemplateErrorMsg()
            return null
        }
        const weightNotMatch = totalSizeMatchTemp.find(t => t?.["template-attribute"]?.["maxWeightInKg"] === 0)
        const weightMatch = totalSizeMatchTemp.filter((t) => {
            return totalWeight + Number(t?.["template-attribute"]?.["bus-mass"]) < t?.["template-attribute"]?.["maxWeightInKg"];
        });
        const totalWeightMatchTemp = [...weightMatch, weightNotMatch]

        if (totalWeightMatchTemp?.length === 0) {
            getTemplateErrorMsg()
            return null
        }

        const powerMatch = totalWeightMatchTemp.filter((t) => {
            return totalPower + Number(t?.["template-attribute"]?.["bus-power"]) < t?.["template-attribute"]?.["battery-capacity"];
        });
        if (powerMatch.length === 0) {
            getTemplateErrorMsg()
            return null
        }

        powerMatch.sort((a, b) => {
            return (
                a?.["template-attribute"]?.["sizeInUnits"] -
                b?.["template-attribute"]?.["sizeInUnits"]
            );
        });
        let sizeSelected = powerMatch?.[0]?.["template-attribute"]?.["sizeInUnits"];
        const matchFinal = powerMatch.filter((t) => {
            return t?.["template-attribute"]?.["sizeInUnits"] === sizeSelected;
        });
        if (matchFinal?.length === 0) {
            getTemplateErrorMsg()
            return null
        }

        setmatchTemplate([...matchFinal].reverse());
        setBusType(matchFinal?.[matchFinal.length - 1]?.['template-attribute']?.['bus-type'])
        dispatch(setShowTemplateDataTrue());
        const unMatch = powerMatch.filter((el) => {
            return matchFinal.indexOf(el) === -1;
        });

        const allEspaTemp = unMatch.filter((t) => t?.["template-attribute"]?.['type'] === "espa");
        const allNotEspaTemp = unMatch.filter((t) => t?.["template-attribute"]?.['type'] !== "espa");
        setremainingTemplate([...allNotEspaTemp, ...allEspaTemp]);
        setLoading(false)
        return matchFinal;
    };

    const onAddTemplate = (data) => {
        if (data["template-attribute"]?.["type"] === 'espa') {
            setEspaTempData({
                type: data["template-attribute"]?.["type"],
                value: data["template-attribute"]?.["sizeInXYZ"],
            })
        } else {
            setEspaTempData({
                type: '',
                value: '',
            })
        }
        const allParams = {
            temp: data,
            templateDataAvailable: templateDataAvailable,
            dispatch: dispatch,
            powerUsage: payloadPowerUsage,
            edgePowerUsage: edgePowerUsage,
            edgeSizeUsage: edgeSizeUsage,
            edgeWeightUsage: edgeWeightUsage,
            sizeUsage: payloadSizeUsage,
            weightUsage: payloadWeightUsage
        }
        calculateUsagePercentage(allParams);
        setSelectedTemplateId(data["uniqueId"]);
        dispatch(setTemplate(data))
        setShowPercent(true)

    };

    //......................................................./
    const deleteUserTemplate = (templateId) => {
        setLoading(true)
        const resp = CatalogueService.deleteTemplate(templateId)
        resp?.then((result) => {
            setTemplateDataAvailable(false)
            getTemplates()
        }).catch(err => {
            console.log('Template Delete Error !!', err.message)
            setLoading(false)
        })
    }
    //......................................................./


    const navigate_to_step = (step) => {
        if (step === 'Payloads') {
            navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_payload`, navigate)
        }
        if (step === 'Edge') {
            if (satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge'] && satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge']?.length > 0) {
                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`, navigate)
            } else {
                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_edge`, navigate)
            }
        }
        if (step === 'Review' && selectedTemplateId !== '') {
            update_satellite()
        } if (step === 'Review' && selectedTemplateId === '') {
            return toast.error("Please select bus design to proceed.", { toastId: 'o' })
        }
    }

    return (
        <div className='h-100 w-100' onClick={() => setShowBtnGroup("")}>
            {Loading && <Loader />}
            <PageHeader
                sat_name={sat_name?.length > 15 ? sat_name.substring(0, 15) + " ..." : sat_name}
                titles="Select Bus Design"
                description="Bus Design represents satellite subsystems like Bus, Radio, Power and components associated with them.
                These designs can be used for different missions. Here the platform recommends best suited templates for your mission based on your size power mass."
                active="New Mission"
                items={["Apps"]}
                isButton={false}
                showSwap={true}
                showPercent={showPercent}
                type={espaTempData?.['type']}
                value={espaTempData?.['value']}
            />
            {isTemplateSelected && <TemplateSlider />}

            {templateDataAvailable && (
                <div className="d-flex flex-column pop-scroll-xl py-5 ps-7 gap-4">
                    <div className='d-flex flex-column gap-1'>
                        {isTemplateData && <div className="d-flex align-items-center">
                            <h4 className="mb-3">
                                {isTemplateData
                                    ? "Recommended Bus Design"
                                    : "Create your own Design"
                                }
                            </h4>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Popover id="popover-basic">
                                        <Popover.Body>
                                            <span className='color-text text-center d-flex'>Recommended Bus Design Based on Your Configuration</span>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <sup>
                                    <i className="fe fe-info ms-1 fs-14 text-info"></i>
                                </sup>
                            </OverlayTrigger>
                        </div>}
                        <div className="d-flex flex-wrap gap-4">
                            {isTemplateData &&
                                matchTemplate?.filter((t) => t["name"] !== "Blank" && !(satellieteDeta?.['versions']?.[0]?.['assembly']?.['Payloads']?.length > 2 && t["name"] === "XDSAT M600"))
                                    .map((data, i) => {
                                        return (
                                            <div key={i}
                                                onClick={() => {
                                                    onAddTemplate(data);
                                                }}
                                            >
                                                <div
                                                    style={{ width: '440px', margin: '0px' }}
                                                    className={
                                                        data?.["uniqueId"] === selectedTemplateId
                                                            ? "common-card-box  active"
                                                            : "common-card-box "
                                                    }
                                                >
                                                    <div className="common-card-header d-flex justify-content-between align-items-start">
                                                        <div>
                                                            <h4 className="mb-2" title={data?.["name"]}>{data?.["name"]?.length > 15 ? data?.["name"]?.substring(0, 15) + "..." : data?.["name"]}</h4>
                                                        </div>
                                                        <div className="btn-list more-button" onClick={(e) => {
                                                            e.stopPropagation()
                                                            setShowBtnGroup("");
                                                        }}>
                                                            <Dropdown drop='end' show={showBtnGroup === data?.["uniqueId"]}>
                                                                <Dropdown.Toggle variant="transparent text-white fs-20"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (showBtnGroup === data?.["uniqueId"]) {
                                                                            setShowBtnGroup("");
                                                                        } else {
                                                                            setShowBtnGroup(data?.["uniqueId"]);
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="fe fe-more-vertical"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        eventKey="0"
                                                                        onClick={() => {
                                                                            dispatch(setTemplate(data))
                                                                            dispatch(setShowTemplateSlideToTrue());
                                                                        }}
                                                                    >
                                                                        Summary
                                                                    </Dropdown.Item>
                                                                    {userData?.['feature-allowed']?.['advancedEdit'] === 'Yes' && <Dropdown.Item
                                                                        eventKey="1"
                                                                        onClick={() => {
                                                                            setDefaultTemplateName(data["name"]);
                                                                            setTemplateName(data["name"] + '-Clone');
                                                                            setSelectedTemplateId(data["uniqueId"]);
                                                                            setOpenCloneDialogue(true);
                                                                        }}
                                                                    >
                                                                        Clone
                                                                    </Dropdown.Item>}
                                                                    {(userData?.['feature-allowed']?.['advancedEdit'] === 'Yes' && (data?.["owner-org"] && data?.["owner-org"] === userData?.['owner_org'])) && <Dropdown.Item
                                                                        eventKey="2"
                                                                        onClick={() => {
                                                                            setSelectedTemplateId(data["uniqueId"]);
                                                                            setOpenDeleteModal(true)
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Dropdown.Item>}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>

                                                    <div className="common-card-content">
                                                        <div className="d-flex flex-wrap">
                                                            <div className="stats-alpha">
                                                                <strong>
                                                                    {data?.["template-attribute"]?.["bus-type"]}
                                                                </strong>
                                                                <span>Bus Type</span>
                                                            </div>
                                                            <div className="stats-alpha">
                                                                <strong>
                                                                    {data?.["template-attribute"]?.["maxWeightInKg"] +
                                                                        " kg"}
                                                                </strong>
                                                                <span>Mass</span>
                                                            </div>
                                                            <div className="stats-alpha">
                                                                <strong>
                                                                    {data?.["template-attribute"]?.[
                                                                        "battery-capacity"
                                                                    ] + " W"}{" "}
                                                                </strong>
                                                                <span>Power</span>
                                                            </div>

                                                            <div className="stats-alpha">
                                                                <strong>
                                                                    {data?.["template-attribute"]?.["ADCS"]}
                                                                </strong>
                                                                <span>ADCS</span>
                                                            </div>
                                                            <div className="stats-alpha">
                                                                <strong>
                                                                    {data?.["template-attribute"]?.["NumSolarPanel"]}
                                                                </strong>
                                                                <span>Solar Panels</span>
                                                            </div>
                                                            <div className="stats-alpha">
                                                                <strong>
                                                                    {data?.["template-attribute"]?.["propulsion"]}
                                                                </strong>
                                                                <span>Propulsion</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            {/* //......................................IMPORTENT..........................................// */}
                            {/* <div className="common-card-box empty-box">
                                <div
                                    className="common-card-content"
                                    onClick={() => {
                                        dispatch(setIsNewTemplateTrue());
                                    }}
                                >
                                    <i className="fe fe-plus fs-40 mb-1"></i>
                                    <span className="fs-16">
                                        Click here to create your new Template
                                    </span>
                                </div>
                            </div> */}
                            {/* //.....................................IMPORTENT...........................................// */}
                        </div>
                    </div>

                    {remainingTemplate.length > 0 && (
                        <div className='d-flex flex-column gap-1'>
                            <h4 className="mb-3">Other Available Bus Design</h4>
                            <div className="d-flex flex-wrap gap-4">
                                {remainingTemplate
                                    ?.filter((t) => t["name"] !== "Blank" && !(satellieteDeta?.['versions']?.[0]?.['assembly']?.['Payloads']?.length > 2 && t["name"] === "XDSAT M600"))
                                    ?.map((data, i) => {
                                        return (
                                            <div
                                                style={{ width: '440px', margin: '0px' }}
                                                className={
                                                    data["uniqueId"] === selectedTemplateId
                                                        ? "common-card-box active"
                                                        : "common-card-box "
                                                }
                                                key={i}
                                                onClick={() => {
                                                    onAddTemplate(data);
                                                    if (data["template-attribute"]?.["type"] === 'espa') {
                                                        setEspaTempData({
                                                            type: data["template-attribute"]?.["type"],
                                                            value: data["template-attribute"]?.["sizeInXYZ"],
                                                        })
                                                    } else {
                                                        setEspaTempData({
                                                            type: '',
                                                            value: '',
                                                        })
                                                    }
                                                }}
                                            >
                                                <div className="common-card-header d-flex justify-content-between align-items-start" onClick={(e) => {
                                                    setShowBtnGroup("");
                                                }}>
                                                    <div>
                                                        <h4 className="mb-2" title={data?.["name"]}>{data?.["name"]}</h4>
                                                    </div>
                                                    <div className="btn-list more-button" >
                                                        <Dropdown drop='end' show={showBtnGroup === data?.["uniqueId"]}>
                                                            <Dropdown.Toggle variant="transparent text-white fs-20"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (showBtnGroup === data?.["uniqueId"]) {
                                                                        setShowBtnGroup("");
                                                                    } else {
                                                                        setShowBtnGroup(data?.["uniqueId"]);
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fe fe-more-vertical"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    eventKey="1"
                                                                    onClick={() => {
                                                                        dispatch(setTemplate(data))
                                                                        dispatch(setShowTemplateSlideToTrue());
                                                                    }}
                                                                >
                                                                    Summary
                                                                </Dropdown.Item>
                                                                {userData?.['feature-allowed']?.['advancedEdit'] === 'Yes' && <Dropdown.Item
                                                                    eventKey="2"
                                                                    onClick={() => {
                                                                        setDefaultTemplateName(data["name"]);
                                                                        setTemplateName(data["name"] + '-Clone');
                                                                        setSelectedTemplateId(data["uniqueId"]);
                                                                        setOpenCloneDialogue(true);
                                                                    }}
                                                                >
                                                                    Clone
                                                                </Dropdown.Item>}
                                                                {(userData?.['feature-allowed']?.['advancedEdit'] === 'Yes' && (data?.["owner-org"] && data?.["owner-org"] === userData?.['owner_org'])) && <Dropdown.Item
                                                                    eventKey="3"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setSelectedTemplateId(data["uniqueId"]);
                                                                        setOpenDeleteModal(true)
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Dropdown.Item>}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>

                                                <div className="common-card-content">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {data?.["template-attribute"]?.["bus-type"]}
                                                            </strong>
                                                            <span>Bus Type</span>
                                                        </div>
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {data?.["template-attribute"]?.[
                                                                    "maxWeightInKg"
                                                                ] + " kg"}
                                                            </strong>
                                                            <span>Mass</span>
                                                        </div>
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {data?.["template-attribute"]?.[
                                                                    "battery-capacity"
                                                                ] + " W"}{" "}
                                                            </strong>
                                                            <span>Power</span>
                                                        </div>
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {data?.["template-attribute"]?.["ADCS"]}
                                                            </strong>
                                                            <span>ADCS</span>
                                                        </div>
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {
                                                                    data?.["template-attribute"]?.[
                                                                    "NumSolarPanel"
                                                                    ]
                                                                }
                                                            </strong>
                                                            <span>Solar Panels</span>
                                                        </div>
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {data?.["template-attribute"]?.["propulsion"]}
                                                            </strong>
                                                            <span>Propulsion</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    )}

                    {errorMessg !== '' && <div className="notification-alert info-text mt-4 fs-16">
                        <i className="fe fe-help-circle fs-20 text-danger me-3"></i>
                        {errorMessg}
                    </div>}
                </div>
            )}
            {/* ////////////.....................////////// */}
            {openCloneDialogue && <CreateBusModel type={'Clone'} setTemplateName={setTemplateName} templateName={templateName} defaultTemplateName={defaultTemplateName} close={handle_close} handle_click={handle_submit} />}

            <Dialog
                onClick={(event) => {
                    if (event) {
                        deleteUserTemplate(selectedTemplateId)
                    }
                    setOpenDeleteModal(false)
                }}
                openModel={openDeleteModel} comment='Are you sure you want to delete this bus ?'
            />


            <div className="navigator-btn gap-3">
                <CloseButton />
                <div className="btn-group btn-grp-nav">
                    <Button
                        variant={hover ? "outline-primary" : "primary"}
                        type="button"
                        className={`d-flex align-items-center btn ${hover ? "btn-outline-primary" : "btn-primary"
                            } pt-1 pb-1 pe-4 ps-3 fs-14`}
                        onClick={() => {
                            if (satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge'] && satellieteDeta?.['versions']?.[0]?.['assembly']?.['Edge']?.length > 0) {
                                if (isTemplateSelected) {
                                    dispatch(setShowTemplateSlideToFalse())
                                }
                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/preview_edge`, navigate)
                            } else {
                                if (isTemplateSelected) {
                                    dispatch(setShowTemplateSlideToFalse())
                                }
                                navigateTo(`${mission_name}/${mission_id}/${sat_name}/${sat_id}/add_new_edge`, navigate)
                            }
                        }}
                        onMouseEnter={() => {
                            setHover(false);
                        }}
                        onMouseLeave={() => {
                            setHover(true);
                        }}
                    >
                        <i className="fe fe-chevron-left me-1 fs-16"></i> Back
                    </Button>
                    <Button
                        variant={hover ? "primary" : "outline-primary"}
                        type="submit"
                        className={`d-flex align-items-center btn ${hover ? "btn-primary" : "btn-outline-primary"
                            } pt-1 pb-1 pe-3 ps-4 fs-14`}
                        onClick={() => {
                            if (selectedTemplateId !== '') {
                                if (isTemplateSelected) {
                                    dispatch(setShowTemplateSlideToFalse())
                                }
                                update_satellite()
                            } else {
                                if (isTemplateSelected) {
                                    dispatch(setShowTemplateSlideToFalse())
                                }
                                toast.error("Please select template to proceed.", { toastId: 'o' })
                            }
                        }}
                    >
                        Save & Continue
                        <i className="fe fe-chevron-right ms-1 fs-16"></i>
                    </Button>
                </div>
            </div>


            <div className="stepper-container">
                <div className="breadcrumb">
                    {Steps.map((data) => {
                        return <a
                            onClick={() => {
                                if (isTemplateSelected) {
                                    dispatch(setShowTemplateSlideToFalse())
                                }
                                navigate_to_step(data.title)
                            }}
                            className={data.title === 'Bus Design' ? 'active' : ''}
                            key={data.title}>
                            <span className="breadcrumb__inner">
                                <span className="breadcrumb__title">
                                    {data.svg}
                                </span>
                                <span className="breadcrumb__desc">{data.title}</span>
                            </span>
                        </a>
                    })}
                </div>
            </div>
        </div>
    );
};
export default SelectTemplate;
