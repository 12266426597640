import React from 'react'
import ScenarioOrbit from './ScenarioOrbit';
import ScenarioTimeline from './ScenarioTimeline';

interface VisualizationWrapperProps {
    scenario_start_time: any;
    scenario_duration: any;
    simulation_start_time: any;
    scenario_step?: string;
    open_schedule_modal: () => void;
    scenario_scheduled: boolean;
    schedule_data: any[];
    set_scenario_scheduled: () => void;
    duration: any;
    set_simulation_time:(data: any) => void;
    set_simulation_duration:(data: any) => void;
    ground_stations?: any[];
    selected_ground_station?: any[];
    scenario_response_data?: object;
    set_loading:(data) => void;
    show_manual_gs: boolean;
    set_show_manual_gs: (data) => void;
    isViewButtonEnabled: boolean;
}


const VisualizationWrapper: React.FC<VisualizationWrapperProps> = ({ scenario_step, open_schedule_modal, ground_stations, selected_ground_station, scenario_duration, scenario_start_time,
  scenario_scheduled, schedule_data, set_scenario_scheduled, simulation_start_time, duration, scenario_response_data, set_simulation_time, set_simulation_duration, set_loading, show_manual_gs, set_show_manual_gs, isViewButtonEnabled }) => {

  return (
    <div className='d-flex w-100 h-100 flex-column'>
      <div className={`w-100 d-flex  ${(schedule_data?.length > 0 && scenario_step === 'Schedule') ? 'h-75' : 'h-100'}`}>
        <ScenarioOrbit
          scenario_scheduled={scenario_scheduled}
          set_scenario_scheduled={set_scenario_scheduled}
          ground_stations={ground_stations}
          selected_ground_station={selected_ground_station}
          set_loading={(data)=>set_loading(data)}
          scenario_response_data={scenario_response_data}
          show_manual_gs={show_manual_gs}
          set_show_manual_gs={(data)=>set_show_manual_gs(data)}
        />
      </div>
      <div className={`w-100 h-25 ${(schedule_data?.length > 0 && scenario_step === 'Schedule') ? 'd-flex position-absolute bottom-0' : 'd-none'}`}>
        <ScenarioTimeline
          scenario_duration={scenario_duration}
          scenario_start_time={scenario_start_time}
          simulation_start_time={simulation_start_time}
          open_schedule_modal={open_schedule_modal}
          schedule_data={schedule_data}
          duration={duration}
          set_simulation_time={set_simulation_time}
          set_simulation_duration={set_simulation_duration}
          isViewButtonEnabled={isViewButtonEnabled}
        />
      </div>
    </div>
  )
}


export default VisualizationWrapper
