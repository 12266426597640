import { FC, useEffect, useRef, useState } from 'react';
import './GsMapVisualization.css'
import GsVisualization from './GsVisualization';
import CreateGSModal from './CreateGSModal';
import GroundStationService from '../../../../../../service/GroundStationService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import MissionService from '../../../../../../service/MissionService';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';

interface GsMapVisualizationProps {
    close: () => void;
    ground_stations: any[];
    provider_detail?: any;
    all_providers: any[];
    get_provider_details: () => void;
    get_all_ground_stations: () => void;
    mode: string | any;
}

const GsMapVisualization: FC<GsMapVisualizationProps> = ({ close, ground_stations, provider_detail, all_providers, get_provider_details, mode, get_all_ground_stations }) => {

    const params = useParams()
    const { mission_id } = params

    const scrollRef = useRef(null)

    const [selectedGroundStations, setSelectedGroundStations] = useState<any[]>([])
    const [providerGroundStations, setProviderGroundStations] = useState<any>(null)
    const [visualizationGroundStationsList, setVisualizationGroundStationsList] = useState<any>([])
    const [filteringType, setFilteringType] = useState<any>(null)
    const [searchValue, setSearchValue] = useState<any>('')
    const [selectedFilteringValue, setSelectedFilteringValue] = useState<{
        providers: string[],
        bands: string[]
    }>({
        providers: [],
        bands: []
    })
    const [selectedFilteringMenu, setSelectedFilteringMenu] = useState<{
        providers: boolean,
        bands: boolean
    }>({
        providers: false,
        bands: false
    })
    const [filterdProviderGroundStations, setFilterdProviderGroundStations] = useState<any>(null)
    const [selectedProviderGroundStations, setSelectedProviderGroundStations] = useState<any>({})
    const [minimize, setMinimize] = useState<any>(false)
    const [openCreateGSModal, setOpenCreateGSModal] = useState<any>(false)
    const [selectedEntity, setSelectedEntity] = useState<any>(null);
    const [selectFromList, setSelectFromList] = useState<any>(false);
    const [toggleDropdown, setToggleDropdown] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false)

    const select_unselect_ground_station = (stationId, providerName) => {
        if (selectedGroundStations?.includes(stationId)) {
            let all_filter_ground_stations = selectedGroundStations?.filter(gsId => gsId !== stationId)
            let filter_provider_ground_stations = selectedProviderGroundStations?.[providerName]?.groundStations?.filter(gsId => gsId !== stationId)
            setSelectedProviderGroundStations({
                ...selectedProviderGroundStations,
                [providerName]: {
                    ...selectedProviderGroundStations[providerName],
                    groundStations: filter_provider_ground_stations
                }
            })
            setSelectedGroundStations(all_filter_ground_stations)
        } else {
            let all_selected_ground_stations = selectedGroundStations?.length > 0 ? [...selectedGroundStations, stationId] : [stationId]
            let selected_provider_ground_stations = selectedProviderGroundStations?.[providerName]?.groundStations ? [...selectedProviderGroundStations[providerName]?.groundStations, stationId] : [stationId]
            setSelectedProviderGroundStations({
                ...selectedProviderGroundStations,
                [providerName]: {
                    ...selectedProviderGroundStations[providerName],
                    groundStations: selected_provider_ground_stations
                }
            })

            setSelectedGroundStations(all_selected_ground_stations)
        }
    }

    const set_provider_ground_stations_list = () => {
        let ans: any = {};
        let without_provider_ground_stations: any = []
        let with_provider_ground_stations: any = []
        without_provider_ground_stations = ground_stations?.filter(gs => (gs?.['owner-org'] !== "System" && ((gs?.status === 'Configure' && (!gs?.['properties']?.['provider'] || gs?.['properties']?.['provider'] === '')) || gs?.status === 'Associate With Provider')))
        if (provider_detail) {
            with_provider_ground_stations = ground_stations?.filter(gs => provider_detail?.providerName === gs?.properties?.provider)
            with_provider_ground_stations.forEach(item => {
                if (!ans[item?.properties.provider]) {
                    ans[item?.properties.provider && item?.properties?.provider] = [];
                }
                ans[item?.properties.provider && item?.properties?.provider].push(item);
            });
        } else if (!provider_detail) {
            with_provider_ground_stations = ground_stations?.filter(gs => (gs?.properties.provider && gs?.properties?.provider !== ''))
            with_provider_ground_stations.forEach(item => {
                if (!ans[item?.properties.provider]) {
                    ans[item?.properties.provider && item?.properties?.provider] = [];
                }
                ans[item?.properties.provider && item?.properties?.provider].push(item);
            });
        }
        if (without_provider_ground_stations?.length > 0) {
            ans = {
                ...ans,
                'Standalone': without_provider_ground_stations
            }
        }

        setLoading(false)
        setProviderGroundStations(ans)
        setVisualizationGroundStationsList([...without_provider_ground_stations, ...with_provider_ground_stations])
        setFilterdProviderGroundStations(ans)
        set_selected_provider_ground_stations()
    }

    const set_selected_provider_ground_stations = () => {
        if (!provider_detail) {
            let all_selected_ground_stations: any[] = []
            let provider_ground_stations = {}
            all_providers?.map(provider => {
                provider_ground_stations = {
                    ...provider_ground_stations,
                    [provider?.providerName]: provider
                }
                if (!all_selected_ground_stations) {
                    all_selected_ground_stations = [];
                }
                if (provider?.groundStations) {
                    all_selected_ground_stations.push(...provider?.groundStations);
                }
            })
            all_selected_ground_stations.map(station => {
                if (!selectedGroundStations.includes(station)) {
                    setSelectedGroundStations((prev) => [...prev, station])
                }
            })
            Object.keys(provider_ground_stations).map(provider => {
                setSelectedProviderGroundStations(prevState => ({
                    ...prevState,
                    [provider]: {
                        ...prevState[provider],
                        ...provider_ground_stations[provider],
                        groundStations: (provider_ground_stations[provider]?.['groundStations'] && provider_ground_stations[provider]?.['groundStations']?.length > 0) ?
                            (prevState?.[provider]?.['groundStations'] && prevState?.[provider]?.['groundStations']?.length > 0) ?
                                Array.from(new Set([...prevState?.[provider]?.['groundStations'], ...provider_ground_stations[provider]?.['groundStations']])) :
                                provider_ground_stations[provider]?.['groundStations'] : []
                    }
                }))
            })
            setLoading(false)
        } else if (provider_detail) {
            setSelectedProviderGroundStations(prevState => ({
                ...prevState,
                [provider_detail?.providerName]: {
                    ...provider_detail,
                    ...prevState?.[provider_detail?.providerName],
                    groundStations: (provider_detail?.['groundStations'] && provider_detail?.['groundStations']?.length > 0) ?
                        (prevState?.[provider_detail?.providerName]?.['groundStations'] && prevState?.[provider_detail?.providerName]?.['groundStations']?.length > 0) ?
                            Array.from(new Set([...prevState?.[provider_detail?.providerName]?.['groundStations'], ...provider_detail?.['groundStations']])) :
                            provider_detail?.['groundStations'] : []
                }
            }))
            if (provider_detail?.groundStations) {
                provider_detail?.groundStations.map(station => {
                    if (!selectedGroundStations.includes(station)) {
                        setSelectedGroundStations((prev) => [...prev, station])
                    }
                })
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        if (ground_stations?.length > 0) {
            set_provider_ground_stations_list()
        }
    }, [ground_stations, provider_detail])


    const update_provider_details = (provider_detail, stations) => {
        const provider_mqttConfiguredBands: string[] = get_provider_mqttConfiguredBands(stations)
        const not_need_mqtt_configured = provider_mqttConfiguredBands?.length === provider_detail?.['mqttConfiguredBands']?.length
        let not_configured_provider_stations = ground_stations?.filter(station => stations?.includes(station?.['groundStationId']) && station?.['status'] === 'Configure')

        let updated_provider = {
            ...provider_detail,
            groundStations: stations,
            mqttConfiguredBands: provider_detail?.['mqttConfiguredBands'] ? provider_detail?.['mqttConfiguredBands'] : [],
            nextConfigStep: stations?.length === 0 ? 'Add Ground Station' : not_configured_provider_stations?.length > 0 ? 'Configure Ground Station' : (provider_detail?.['MQTTTopicList'] && provider_detail?.['MQTTTopicList']?.length > 0 && not_need_mqtt_configured) ? '' : 'Configure MQTT Topics',
            status: (stations?.length === 0 || not_configured_provider_stations?.length > 0) ? 'Not Configured' : (provider_detail?.['MQTTTopicList'] && provider_detail?.['MQTTTopicList']?.length > 0 && not_need_mqtt_configured) ? 'Configured' : 'Not Configured'
        }
        let filter_providers = all_providers?.filter(provider => provider?.['uniqueId'] !== provider_detail?.['uniqueId'])
        let providers_list = [...filter_providers, updated_provider]
        GroundStationService.updateGsProvider(provider_detail?.['uniqueId'], updated_provider).then((res: any) => {
            if (res.data) {
                get_provider_details()
                update_ground_stations(provider_detail?.['providerName'])
                update_mission_ground_stations(providers_list, 'Provider updated successfully.')
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to update provider.')
        })
    }

    const update_ground_stations = (provider_name) => {
        let updated_provider_ground_stations: any = []
        if (provider_detail) {
            Object.keys(selectedProviderGroundStations)?.map((provider: any) => {
                providerGroundStations[provider]?.map((station: any) => {
                    if (station?.['owner-org'] !== "System" && selectedGroundStations?.includes(station?.['groundStationId']) && selectedGroundStations?.length > 0) {
                        let data = {
                            ...station,
                            properties: {
                                ...station?.['properties'],
                                provider: provider_name
                            },
                            status: (!station?.['properties']?.['altitude'] || station?.['properties']?.['altitude'] === '') ? 'Configure' : 'Configured',
                        }
                        updated_provider_ground_stations = [...updated_provider_ground_stations, data]
                    } else if (station?.['owner-org'] !== "System" && !selectedGroundStations?.includes(station?.['groundStationId']) && selectedGroundStations?.length > 0) {
                        let data = {
                            ...station,
                            properties: {
                                ...station?.['properties'],
                                provider: '',
                            },
                            status: (!station?.['properties']?.['altitude'] || station?.['properties']?.['altitude'] === '') ? 'Configure' : 'Associate With Provider',
                        }
                        updated_provider_ground_stations = [...updated_provider_ground_stations, data]
                    }
                })
            })
        } else {
            Object.keys(selectedProviderGroundStations)?.map((provider: any) => {
                providerGroundStations[provider]?.map((station: any) => {
                    if (station?.['owner-org'] !== "System" && selectedGroundStations?.includes(station?.['groundStationId']) && selectedGroundStations?.length > 0) {
                        let data = {
                            ...station,
                            properties: {
                                ...station?.['properties'],
                                provider: provider === 'Standalone' ? provider_name : provider
                            },
                            status: (!station?.['properties']?.['altitude'] || station?.['properties']?.['altitude'] === '') ? 'Configure' : 'Configured',
                        }
                        updated_provider_ground_stations = [...updated_provider_ground_stations, data]
                    } else if (station?.['owner-org'] !== "System" && !selectedGroundStations?.includes(station?.['groundStationId']) && selectedGroundStations?.length > 0) {
                        let data = {
                            ...station,
                            properties: {
                                ...station?.['properties'],
                                provider: '',
                            },
                            status: (!station?.['properties']?.['altitude'] || station?.['properties']?.['altitude'] === '') ? 'Configure' : 'Associate With Provider',
                        }
                        updated_provider_ground_stations = [...updated_provider_ground_stations, data]
                    }
                })
            })
        }
        if (updated_provider_ground_stations?.length > 0) {
            GroundStationService.updateGroundStations(updated_provider_ground_stations).then((res) => {
                if (res.data) {
                    get_all_ground_stations()
                    setLoading(false)
                    close()
                }
            }).catch((err) => {
                console.error(err);
                toast.error('Failed to update ground station.')
            })
        } else {
            setLoading(false)
            close()
        }
    }

    const get_provider_mqttConfiguredBands = (provider_stations: any) => {
        const filter_ground_stations = ground_stations?.filter((station: any) => provider_stations?.includes(station?.['groundStationId']))
        let provider_mqttConfiguredBands: string[] = []
        filter_ground_stations?.map((station: any) => {
            if (station?.['properties']?.['freqbands']?.length > 0) {
                station?.['properties']?.['freqbands'].map((band: any) => {
                    if (!provider_mqttConfiguredBands?.includes(band)) {
                        provider_mqttConfiguredBands.push(band)
                    }
                })
            }

        })
        return provider_mqttConfiguredBands
    }

    const check_any_update = (already_created_providers, new_providers) => {
        let all_providers_stations: string[] = []
        let already_created_providers_stations: string[] = []
        all_providers?.map(provider => {
            if (provider?.['groundStations']) {
                all_providers_stations.push(...provider?.['groundStations'])
            }
        })
        already_created_providers?.map(provider => {
            if (provider?.['groundStations']) {
                already_created_providers_stations.push(...provider?.['groundStations'])
            }
        })
        return new_providers?.length > 0 ? 'Providers created successfully.' : already_created_providers_stations?.length !== all_providers_stations?.length ? 'Providers updated successfully.' : false
    }
    const create_provider = () => {
        setLoading(true)
        let untitled_providers = all_providers?.filter(item => item?.['providerName']?.includes('Untitled') || item?.['providerName'] === 'Untitled')
        const last_untitled_provider = untitled_providers?.length === 0 ? 'Untitled' : untitled_providers?.length === 1 ? 'Untitled 1' : 'Untitled ' + (Number(untitled_providers[untitled_providers?.['length'] - 1]?.['providerName'].split(' ')[1]) + 1)

        let provider_name = ''
        let new_providers: any = []
        let already_created_providers: any = []
        let all_selected_providers: any = []
        Object.keys(selectedProviderGroundStations)?.map((provider: any) => {
            provider_name = provider === 'Standalone' ? last_untitled_provider : provider;
            if (selectedProviderGroundStations[provider]?.['uniqueId']) {
                const provider_stations = selectedProviderGroundStations[provider]?.groundStations?.length > 0 ? selectedProviderGroundStations[provider]?.groundStations : []
                const provider_mqttConfiguredBands: string[] = get_provider_mqttConfiguredBands(provider_stations)
                const need_mqtt_configured = provider_mqttConfiguredBands?.length !== selectedProviderGroundStations[provider]?.['mqttConfiguredBands']?.length
                let not_configured_provider_stations = ground_stations?.filter(station => provider_stations?.includes(station?.['groundStationId']) && station?.['status'] === 'Configure')

                let updated_provider = {
                    ...selectedProviderGroundStations[provider],
                    mqttConfiguredBands: selectedProviderGroundStations[provider]?.['mqttConfiguredBands'] ? selectedProviderGroundStations[provider]?.['mqttConfiguredBands'] : [],
                    MQTTTopicList: (selectedProviderGroundStations[provider]?.['MQTTTopicList'] && selectedProviderGroundStations[provider]?.['MQTTTopicList']?.length === 0) ? selectedProviderGroundStations[provider]?.['MQTTTopicList'] : [],
                    nextConfigStep: (!selectedProviderGroundStations[provider]?.['MQTTEndpoint'] || selectedProviderGroundStations[provider]?.['MQTTEndpoint'] === '') ? 'Configure Provider' : (!selectedProviderGroundStations[provider]?.['groundStations'] || selectedProviderGroundStations[provider]?.['groundStations']?.length === 0) ? 'Add Ground Station' : not_configured_provider_stations?.length > 0 ? 'Configure Ground Station' : (!selectedProviderGroundStations[provider]?.['MQTTTopicList'] || selectedProviderGroundStations[provider]?.['MQTTTopicList']?.length === 0 || need_mqtt_configured) ? 'Configure MQTT Topics' : '',
                    status: ((!selectedProviderGroundStations[provider]?.['MQTTEndpoint'] || selectedProviderGroundStations[provider]?.['MQTTEndpoint'] === '') || (!selectedProviderGroundStations[provider]?.['groundStations'] || selectedProviderGroundStations[provider]?.['groundStations']?.length === 0) || (!selectedProviderGroundStations[provider]?.['MQTTTopicList'] || selectedProviderGroundStations[provider]?.['MQTTTopicList']?.length === 0 || need_mqtt_configured || not_configured_provider_stations?.length > 0)) ? 'Not Configured' : 'Configured',
                    groundStations: selectedProviderGroundStations[provider]?.groundStations
                }
                already_created_providers.push(updated_provider)
            } else {
                let updated_provider = {
                    providerName: provider_name,
                    missionId: mission_id,
                    mqttConfiguredBands: [],
                    MQTTTopicList: [],
                    customProvider: (provider === 'ATLAS' || provider === 'Leaf Space') ? false : true,
                    uniqueId: uuidv4(),
                    nextConfigStep: 'Configure Provider',
                    status: 'Not Configure',
                    groundStations: selectedProviderGroundStations[provider]?.groundStations
                }
                new_providers.push(updated_provider)
            }
        })
        new_providers = new_providers.filter(provider => provider?.groundStations?.length > 0)
        all_selected_providers = [...already_created_providers, ...new_providers]

        const is_any_update: any = check_any_update(already_created_providers, new_providers)

        GroundStationService.createandUpdateGsProvider(all_selected_providers).then((res: any) => {
            if (res.data) {
                get_provider_details()
                update_ground_stations(provider_name)
                update_mission_ground_stations(all_selected_providers, is_any_update)
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to create provider.')
            setLoading(false)
        })
    }

    const update_mission_ground_stations = (providers, is_any_update) => {
        let updated_mission_ground_stations: any = []
        providers?.map((provider: any) => {
            if (provider?.groundStations?.length > 0) {
                updated_mission_ground_stations = updated_mission_ground_stations?.length > 0 ? [...updated_mission_ground_stations, ...provider?.groundStations] : [...provider?.groundStations]
            }
        })
        MissionService.updateMissionStations(mission_id, updated_mission_ground_stations)?.then((res) => {
            if (res?.data && is_any_update) {
                toast.success(is_any_update)
            }
        }).catch((err) => {
            console.error(err);
            toast.error('Failed to update station.')
        })
    }

    const filtering_ground_stations_by_provider = (providers: string[] = []) => {
        let ans: any = {}
        let with_provider_ground_stations: any[] = []
        const bands = selectedFilteringValue?.['bands']
        if (bands?.['length'] > 0) {
            Object.keys(providerGroundStations).filter(provider => {
                if (providers?.length > 0 && providers.includes(provider)) {
                    with_provider_ground_stations.push(...providerGroundStations[provider])
                } else if (providers?.length === 0) {
                    with_provider_ground_stations.push(...providerGroundStations[provider])
                }
            })
            bands?.map((band: any) => {
                with_provider_ground_stations?.map(gs => {
                    if (gs?.properties?.freqbands?.includes(band)) {
                        if (gs?.properties.provider !== '') {
                            const providerStations = new Set(ans[gs?.properties.provider]);
                            providerStations.add(gs);
                            ans[gs?.properties.provider] = Array.from(providerStations);
                        } else if (gs?.properties.provider === '') {
                            const standaloneStations = new Set(ans['Standalone'] || []);
                            standaloneStations.add(gs);
                            ans['Standalone'] = Array.from(standaloneStations);
                        }
                    }
                })
            })
        } else {
            if (providers?.length > 0) {
                Object.keys(providerGroundStations).filter(provider => {
                    if (providers.includes(provider)) {
                        ans[provider] = providerGroundStations[provider]
                    }
                })
            } else {
                ans = providerGroundStations
            }
        }

        setSelectedFilteringValue((prev) => ({
            ...prev,
            providers: providers,
        }))
        setFilterdProviderGroundStations(ans)
        let toggle_drop_downs: string[] = []
        if (Object.keys(ans)?.length > 0) {
            Object.keys(ans)?.map((provider: any) => {
                toggle_drop_downs.push(provider)
            })
        }
        setToggleDropdown(toggle_drop_downs)
    }

    const filtering_ground_stations_by_bands = (bands: string[] = []) => {
        let ans: any = {}
        let provider_stations: any = []
        const providers = selectedFilteringValue?.['providers']
        Object.keys(providerGroundStations).filter(provider => {
            if (providers?.length > 0 && providers.includes(provider)) {
                provider_stations.push(...providerGroundStations[provider])
            } else if (providers?.length === 0) {
                provider_stations.push(...providerGroundStations[provider])
            }
        })

        if (bands?.length > 0) {
            bands?.map((band: any) => {
                provider_stations?.map(gs => {
                    if (gs?.properties?.freqbands?.includes(band)) {
                        if (gs?.properties.provider !== '') {
                            const providerStations = new Set(ans[gs?.properties.provider]);
                            providerStations.add(gs);
                            ans[gs?.properties.provider] = Array.from(providerStations);
                        } else if (gs?.properties.provider === '') {
                            const standaloneStations = new Set(ans['Standalone'] || []);
                            standaloneStations.add(gs);
                            ans['Standalone'] = Array.from(standaloneStations);
                        }
                    }
                })
            })
        } else {
            if (providers?.length > 0) {
                Object.keys(providerGroundStations).filter(provider => {
                    if (providers.includes(provider)) {
                        ans[provider] = providerGroundStations[provider]
                    }
                })
            } else {
                ans = providerGroundStations
            }
        }

        setSelectedFilteringValue((prev) => ({
            ...prev,
            bands: bands
        }))
        setFilterdProviderGroundStations(ans)
        let toggle_drop_downs: string[] = []
        if (Object.keys(ans)?.length > 0) {
            Object.keys(ans)?.map((provider: any) => {
                toggle_drop_downs.push(provider)
            })
        }
        setToggleDropdown(toggle_drop_downs)
    }

    const search_ground_stations = (gs_name) => {
        setSearchValue(gs_name)
        if (gs_name === '') {
            setFilterdProviderGroundStations(providerGroundStations)
        } else {
            let provider_stations: any = []
            Object.keys(providerGroundStations).map(provider => {
                provider_stations.push(...providerGroundStations[provider])
            })
            let gs_name_length = gs_name?.length
            let ans: any = {}
            let with_provider_ground_stations = provider_stations?.filter(gs => gs?.['groundStationName']?.toLowerCase().slice(0, gs_name_length) === gs_name?.toLowerCase())
            with_provider_ground_stations.forEach(item => {
                if (item?.properties.provider !== '') {
                    const providerStations = new Set(ans[item?.properties.provider]);
                    providerStations.add(item);
                    ans[item?.properties.provider] = Array.from(providerStations);
                } else if (item?.properties.provider === '') {
                    const standaloneStations = new Set(ans['Standalone'] || []);
                    standaloneStations.add(item);
                    ans['Standalone'] = Array.from(standaloneStations);
                }
            });
            setFilterdProviderGroundStations(ans)
            let toggle_drop_downs: string[] = []
            if (Object.keys(ans)?.length > 0) {
                Object.keys(ans)?.map((provider: any) => {
                    toggle_drop_downs.push(provider)
                })
            }
            setToggleDropdown(toggle_drop_downs)
        }
    }

    const select_provider_ground_stations = (provider_name) => {
        const stations = filterdProviderGroundStations?.[provider_name].map(station => station?.['groundStationId'])
        const filter_stations = selectedGroundStations.filter(station => {
            if (!stations.includes(station)) return station
        })
        setSelectedProviderGroundStations((prev) => ({
            ...prev,
            [provider_name]: {
                ...prev[provider_name],
                groundStations: stations
            }
        }))
        setSelectedGroundStations([...filter_stations, ...stations])
    }

    const unselect_provider_ground_stations = (provider_name) => {
        const stations = filterdProviderGroundStations?.[provider_name].map(station => station?.['groundStationId'])
        const filter_stations = selectedGroundStations.filter(sta => {
            if (!stations.includes(sta)) return sta
        })
        setSelectedProviderGroundStations((prev) => ({
            ...prev,
            [provider_name]: {
                ...prev[provider_name],
                groundStations: []
            }
        }))
        setSelectedGroundStations(filter_stations)
    }


    // useEffect(() => {
    //     const container = scrollRef.current;
    //     const container_item = scrollRef.current;
    //     if (container && container_item) {
    //         (container as HTMLElement).scrollTop = (container_item as HTMLElement)?.offsetTop - 100;
    //         (container as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    //     }
    // }, [toggleDropdown])

    const close_filter_type = () => {
        setFilteringType(null)
        setSearchValue('')
        if (selectedFilteringValue?.providers?.length === 0 && selectedFilteringValue?.bands?.length === 0) {
            setSelectedFilteringMenu({
                providers: false,
                bands: false
            })
        }
    }
    useEffect(() => {
        window.addEventListener('click', close_filter_type)
        return () => { window.removeEventListener('click', () => { setFilteringType(null) }) }
    }, [])

    const clear_filter_data = (e: any) => {
        e.stopPropagation()
        setSelectedFilteringValue({
            providers: [],
            bands: []
        })
        setFilterdProviderGroundStations(providerGroundStations)
    }


    return (
        <div className='popup-model z-2 d-flex align-items-end' >
            <div className='visualization_container'>
                <GsVisualization
                    ground_stations={visualizationGroundStationsList}
                    selected_stations={selectedGroundStations}
                    selectedEntity={selectedEntity}
                    selectFromList={selectFromList}
                    setSelectedEntity={(data) => {
                        setSelectedEntity(data)
                        setTimeout(() => {
                            setSelectFromList(false)
                        }, 100);
                    }}
                />

                <CreateGSModal
                    close={() => {
                        get_all_ground_stations()
                        setOpenCreateGSModal(false)
                    }}
                    open={openCreateGSModal}
                    all_providers={all_providers}
                    select_ground_station={(station, provider_name) => {
                        const station_id = station?.['groundStationId']
                        const provider_stations = selectedProviderGroundStations?.[provider_name]?.['groundStations'] ? [...selectedProviderGroundStations?.[provider_name]?.['groundStations'], station_id] : [station_id]

                        const provider_details = {
                            ...selectedProviderGroundStations?.[provider_name],
                            groundStations: provider_stations
                        }
                        setSelectedProviderGroundStations((prev) => ({
                            ...prev,
                            [provider_name]: provider_details
                        }))
                        setSelectedGroundStations((prev) => [...prev, station_id])
                        setToggleDropdown((prev) => [...prev, provider_name])
                    }}
                    provider_details={provider_detail}
                    loading={loading}
                    setLoading={(data) => { setLoading(data) }}
                />

                <div className={`gs_list_container ${minimize && 'minimize'}`}>
                    <div className='w-100 px-3 py-2 d-flex align-items-center justify-content-between'>
                        <div className='d-flex gap-2 align-items-center'>
                            <img src={require('../../../../../../assets/images/svgs/gs/ground_station.svg').default} width='20px' height='20px' alt='mission' />
                            <span className='fs-16 mt-1'>Ground Stations</span>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <button className='p-0 border-0 bg-transparent' onClick={() => { setMinimize(!minimize) }}>
                                {!minimize && <i className='fe fe-minus color-text fs-20'></i>}
                                {minimize && <img src={require('../../../../../../assets/images/svgs/maximize.svg').default} width='20px' height='20px' alt='mission' />}
                            </button>
                            <button className='p-0 border-0 bg-transparent' onClick={close}>
                                <i className='fe fe-x fs-20 color-text'></i>
                            </button>
                        </div>
                    </div>

                    <div className='gs_container_body'>
                        <div className='gs_container_body_header'>
                            <div className='d-flex align-items-center gap-1 ps-1'>
                                <span className='color_history ps-1'>Selected:</span>
                                <span className='color-text'>{selectedGroundStations?.length}</span>
                            </div>
                            <div className='d-flex align-items-center'>

                                <div className={`search_input_container ${filteringType === 'search' ? 'active' : ''}`} onClick={(e) => { e.stopPropagation() }}>
                                    {filteringType === 'search' && <input autoFocus placeholder='Search...'
                                        type='text'
                                        value={searchValue}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            search_ground_stations(e.target.value)
                                        }}
                                    />}

                                    <CustomToolTip title='Search' placement='top'>
                                        <button className='search_btn'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setSearchValue('')
                                                setOpenCreateGSModal(false)
                                                setFilteringType(filteringType === 'search' ? null : 'search')
                                                setFilterdProviderGroundStations(providerGroundStations)
                                            }}
                                        >
                                            {filteringType === 'search' &&
                                                <i className='fe fe-x fs-16 color_history' />
                                            }
                                            {(!filteringType || filteringType === 'filter') &&
                                                <i className='fe fe-search fs-16 color_history' />
                                            }
                                        </button>
                                    </CustomToolTip>
                                </div>
                                {(!filteringType || filteringType === 'filter') &&
                                    <div className='position-relative'>

                                        <CustomToolTip title='Filter' placement='top'>
                                            <button className={`search_btn ${(filteringType === 'filter' || (selectedFilteringValue['providers']?.length > 0 || selectedFilteringValue['bands']?.length > 0)) ? 'active' : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setOpenCreateGSModal(false)
                                                    setFilteringType(filteringType === 'filter' ? null : 'filter')
                                                    const providers = selectedFilteringValue['providers']
                                                    const bands = selectedFilteringValue['bands']
                                                    setSelectedFilteringMenu({
                                                        providers: providers?.length > 0 ? true : false,
                                                        bands: bands?.length > 0 ? true : false,
                                                    })
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M10.4998 15.0004H13.5002C13.6914 15.0006 13.8753 15.0738 14.0143 15.2051C14.1533 15.3363 14.237 15.5157 14.2482 15.7066C14.2594 15.8974 14.1973 16.0854 14.0746 16.232C13.9519 16.3786 13.7778 16.4728 13.588 16.4954L13.5002 16.5006H10.4998C10.3086 16.5004 10.1247 16.4272 9.98569 16.296C9.84667 16.1647 9.76301 15.9853 9.75181 15.7945C9.7406 15.6036 9.8027 15.4157 9.92541 15.2691C10.0481 15.1225 10.2222 15.0283 10.412 15.0057L10.4998 15.0004ZM8.99958 11.2499H15.0004C15.1916 11.2501 15.3755 11.3233 15.5145 11.4546C15.6535 11.5858 15.7372 11.7652 15.7484 11.956C15.7596 12.1469 15.6975 12.3348 15.5748 12.4814C15.4521 12.6281 15.278 12.7223 15.0882 12.7449L15.0004 12.7501H8.99958C8.8084 12.7499 8.62451 12.6767 8.48549 12.5454C8.34647 12.4142 8.26281 12.2348 8.2516 12.044C8.2404 11.8531 8.30249 11.6652 8.4252 11.5186C8.54791 11.372 8.72197 11.2777 8.91182 11.2552L8.99958 11.2499ZM6.74927 7.49939H17.2507C17.4419 7.4996 17.6258 7.57281 17.7648 7.70405C17.9038 7.8353 17.9875 8.01468 17.9987 8.20553C18.0099 8.39639 17.9478 8.58432 17.8251 8.73093C17.7024 8.87754 17.5283 8.97176 17.3385 8.99434L17.2507 8.9996H6.74927C6.55809 8.99938 6.3742 8.92618 6.23518 8.79493C6.09615 8.66369 6.01249 8.48431 6.00129 8.29345C5.99009 8.1026 6.05218 7.91466 6.17489 7.76805C6.2976 7.62144 6.47166 7.52722 6.66151 7.50464L6.74927 7.49939Z" fill="#F0F0F0" fill-opacity="0.6" />
                                                </svg>
                                            </button>
                                        </CustomToolTip>
                                        <div className={`filter_popup_modal ${filteringType === 'filter' ? 'active' : ''}`} onClick={(e) => e.stopPropagation()}>
                                            <div className='filter_popup_modal_container'>
                                                <div className={`dropdown_menu ${selectedFilteringMenu?.['providers'] ? 'active' : 'color_history'}`}>
                                                    <div className='d-flex align-items-center justify-content-start gap-2 w-100' onClick={(e) => {
                                                        e.stopPropagation()
                                                        setSelectedFilteringMenu({
                                                            providers: !selectedFilteringMenu['providers'],
                                                            bands: selectedFilteringMenu['bands'],
                                                        })
                                                    }}>
                                                        <div className='toggle_filter_menu' />
                                                        <span className={`dropdown_title `}> Providers</span>
                                                    </div>
                                                    <div className='dropdown_content px-2'>
                                                        {providerGroundStations && Object.keys(providerGroundStations)?.length > 0 && Object.keys(providerGroundStations).map((key) => (
                                                            <div className='d-flex gap-2 align-items-center' onClick={(e) => {
                                                                e.stopPropagation()
                                                                const providers: string[] = selectedFilteringValue?.providers?.length > 0 ? selectedFilteringValue?.providers : []
                                                                let value = providers?.includes(key) ? providers?.filter(item => item !== key) : [...providers, key];
                                                                filtering_ground_stations_by_provider(value)
                                                            }}>
                                                                {selectedFilteringValue?.providers.includes(key) && <img src={require('../../../../../../assets/images/svgs/gs/checked_box.svg').default} width='14px' height='14px' alt='mission' className={` d-flex align-items-center justify-content-center`} />}
                                                                {!selectedFilteringValue?.providers.includes(key) && <img src={require('../../../../../../assets/images/svgs/gs/uncheck_box.svg').default} width='14px' height='14px' alt='mission' className={` d-flex align-items-center justify-content-center`} />}
                                                                <span title={key} className={` fs-12`}>{key?.length > 12 ? key?.slice(0, 12) + '...' : key} </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className={`dropdown_menu ${selectedFilteringMenu?.['bands'] ? 'active' : 'color_history'}`}>
                                                    <div className='d-flex align-items-center justify-content-start gap-2 w-100' onClick={(e) => {
                                                        e.stopPropagation()
                                                        setSelectedFilteringMenu({
                                                            providers: selectedFilteringMenu['providers'],
                                                            bands: !selectedFilteringMenu['bands'],
                                                        })
                                                    }}>
                                                        <div className='toggle_filter_menu' />
                                                        <span className={`dropdown_title`}> RF Bands</span>
                                                    </div>
                                                    <div className='dropdown_content px-2'>
                                                        {['S-band', 'X-band', 'UHF'].map((band) => (
                                                            <div className='d-flex align-items-center gap-2' onClick={(e) => {
                                                                e.stopPropagation()
                                                                const bands: string[] = selectedFilteringValue?.['bands']?.length > 0 ? selectedFilteringValue?.['bands'] : []
                                                                let value = bands?.includes(band) ? bands?.filter(item => item !== band) : [...bands, band];
                                                                filtering_ground_stations_by_bands(value)
                                                            }}>
                                                                {selectedFilteringValue?.['bands']?.includes(band) && <img src={require('../../../../../../assets/images/svgs/gs/checked_box.svg').default} width='14px' height='14px' alt='mission' className={`d-flex align-items-center justify-content-center`} />}
                                                                {!selectedFilteringValue?.['bands']?.includes(band) && <img src={require('../../../../../../assets/images/svgs/gs/uncheck_box.svg').default} width='14px' height='14px' alt='mission' className={`d-flex align-items-center justify-content-center`} />}
                                                                <span className={` fs-12`}>{band}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            {(selectedFilteringValue['providers']?.length > 0 || selectedFilteringValue['bands']?.length > 0) &&
                                                <button className='reset__filter__btn' onClick={clear_filter_data}>
                                                    Reset Filters
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }
                                {(!filteringType || filteringType === 'filter') && mode === 'Map' &&
                                    <CustomToolTip title='Create Ground Station' placement='top'>
                                        <button className={`add_ground_station_btn ${openCreateGSModal && 'active'}`}
                                            onClick={(e) => {
                                                setFilteringType(null)
                                                clear_filter_data(e)
                                                setOpenCreateGSModal(!openCreateGSModal)
                                            }}
                                        >
                                            <i className='fe fe-plus fs-20 icon' />
                                        </button>
                                    </CustomToolTip>
                                }
                            </div>
                        </div>
                        <div className='gs_container_body_container' ref={scrollRef}>
                            {filterdProviderGroundStations && Object.keys(filterdProviderGroundStations)?.length > 0 && Object.keys(filterdProviderGroundStations).map((key) => (
                                <div className={`dropdown_menu ${toggleDropdown.includes(key) ? 'active' : ''}`} key={key}>
                                    <div className='d-flex w-100 align-items-center ps-2 justify-content-between'>

                                        <div className='d-flex align-items-center flex-1 gap-2 justify-content-between'>
                                            {(!selectedProviderGroundStations?.[key] || selectedProviderGroundStations?.[key]?.['groundStations']?.length === 0) &&
                                                <img src={require('../../../../../../assets/images/svgs/gs/uncheck_box.svg').default} width='16px' height='16px' alt='mission' className={` d-flex align-items-center justify-content-center`}
                                                    onClick={() => { select_provider_ground_stations(key) }}
                                                />
                                            }
                                            {(selectedProviderGroundStations?.[key] && selectedProviderGroundStations?.[key]?.['groundStations']?.length > 0 && selectedProviderGroundStations?.[key]?.['groundStations']?.length !== providerGroundStations[key]?.length) &&
                                                <img src={require('../../../../../../assets/images/svgs/gs/partial_selection_check_box.svg').default} width='16px' height='16px' alt='mission' className={` d-flex align-items-center justify-content-center`}
                                                    onClick={() => { select_provider_ground_stations(key) }}
                                                />
                                            }
                                            {(selectedProviderGroundStations?.[key] && selectedProviderGroundStations?.[key]?.['groundStations']?.length === providerGroundStations[key]?.length) &&
                                                <img src={require('../../../../../../assets/images/svgs/gs/checked_box.svg').default} width='16px' height='16px' alt='mission' className={` d-flex align-items-center justify-content-center`}
                                                    onClick={() => { unselect_provider_ground_stations(key) }}
                                                />
                                            }
                                        </div>
                                        <div className='d-flex align-items-center gap-2 w-85' ref={toggleDropdown[toggleDropdown?.length - 1] === key ? scrollRef : null}
                                            onClick={() => {
                                                if (toggleDropdown.includes(key)) {
                                                    setToggleDropdown(toggleDropdown?.filter((item) => item !== key))
                                                } else {
                                                    setToggleDropdown([...toggleDropdown, key])
                                                }
                                            }}>
                                            <div className='toggle' />
                                            <span title={key} className={`${toggleDropdown.includes(key) ? 'active' : 'color_history'} fs-16`}>{`${key}`?.length > 15 ? key?.slice(0, 15) + '...' : `${key}`}</span>
                                        </div>
                                        <span className='color_history flex-1 '>{filterdProviderGroundStations[key]?.length}</span>
                                    </div>
                                    <div className={`dropdown_content  ps-2`} >
                                        {filterdProviderGroundStations[key]?.map((station: any, index: number) => (
                                            <div className={`dropdown_content_item ${selectedEntity === station?.['groundStationId'] ? 'active' : ''}`} key={index} onClick={(e) => {
                                                e.stopPropagation()
                                                close_filter_type()
                                                setSelectFromList(true)
                                                setSelectedEntity(selectedEntity === station?.['groundStationId'] ? null : station?.['groundStationId'])
                                            }}>
                                                <div className='d-flex gap-2 align-items-center w-70'>
                                                    <button className={`p-0 border-0 bg-transparent`}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            close_filter_type()
                                                            select_unselect_ground_station(station?.['groundStationId'], key)
                                                        }}
                                                    >
                                                        {selectedGroundStations?.includes(station?.['groundStationId']) && <img src={require('../../../../../../assets/images/svgs/gs/checked_box.svg').default} width='16px' height='16px' alt='mission' className={` d-flex align-items-center justify-content-center`} />}
                                                        {!selectedGroundStations?.includes(station?.['groundStationId']) && <img src={require('../../../../../../assets/images/svgs/gs/uncheck_box.svg').default} width='16px' height='16px' alt='mission' className={` d-flex align-items-center justify-content-center`} />}
                                                    </button>
                                                    <span className='fs-12 w-85 text-truncate' title={station?.['groundStationName']}>
                                                        {station?.['groundStationName']}
                                                    </span>
                                                </div>
                                                <div className='d-flex gap-1 flex-1 justify-content-end'>
                                                    {station?.['properties']?.['freqbands'] && station?.['properties']?.['freqbands']?.map(band => (
                                                        <div className='dropdown_item_batch fs-10' key={band} title={band}>
                                                            {band.split('-')[0]}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className='px-3 py-2 gs_container_footer'>
                        <button className='btn btn-outline-primary' onClick={close}>{mode === 'Map' ? 'Back' : 'Close'}</button>
                        {mode === 'Map' && <button className='btn btn-primary' onClick={() => {
                            if (provider_detail) {
                                update_provider_details(provider_detail, selectedGroundStations)
                            } else {
                                create_provider()
                            }
                        }}>{provider_detail ? 'Update' : 'Save'}</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GsMapVisualization
