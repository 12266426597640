// @ts-nocheck
import { Fragment, useEffect, useState } from 'react';
import GrafanaPanel from '../GrafanaPanel';
import CesiumView from './CesiumView';
import GroundContact from './GroundContact';
import { useParams } from 'react-router-dom';
import ConsolePanel from '../QuickDeployDashboard/ConsolePanel';
import MissionService from '../../../../../service/MissionService';

function SimulationDashboard({ selectedTabBtn, TrueTwin, simulationStatus, isQuickDeploy,isScenarioAutomated,taskListQueue, isShowPanel, setLoading}) {
    const [absTime, setAbsTime] = useState(null);

    const params = useParams();
    const { truetwin_id, scenario_id } = params

    useEffect(() => {
        if(simulationStatus?.['simulation_time_abs'] && absTime === null){
            setAbsTime(simulationStatus?.['simulation_time_abs'])
        }   
    },[simulationStatus, absTime])


    return (
        <div className="w-100 h-100 overflow-hidden" >
            {selectedTabBtn === 'Console' && <ConsolePanel TrueTwin={TrueTwin} />}
            {selectedTabBtn === 'Overview' && <CesiumView 
                simulationStatus={simulationStatus} 
                selectedTabBtn={selectedTabBtn} 
                isQuickDeploy = {isQuickDeploy} 
                isScenarioAutomated={isScenarioAutomated} 
                taskListQueue={taskListQueue} 
                isShowPanel={isShowPanel} 
                setLoading={setLoading}       
            />}
            {selectedTabBtn === 'Ground Contacts' &&
                <Fragment>
                    <div style={{ height: "45vh" }}>
                        <CesiumView 
                            simulationStatus={simulationStatus} 
                            isQuickDeploy = {isQuickDeploy} 
                            isScenarioAutomated={isScenarioAutomated} 
                            isShowPanel={isShowPanel}
                            setLoading={setLoading} 
                        />
                    </div>
                    <div style={{ height: "40vh" , overflow : "scroll" }}><GroundContact scenarioId={scenario_id} absTime = {absTime} setAbsTime= {setAbsTime}/></div>
                </Fragment>
            }
            {selectedTabBtn === 'Telemetry' && <GrafanaPanel TrueTwin={TrueTwin} taskListQueue={taskListQueue}/>}
        </div>
    );
}

export default SimulationDashboard;
